var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"eventDetails") : depths[1]),{"name":"with","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":9,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  					<span title=\""
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":43}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":68}}}))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"eventDetails") : depths[1]),{"name":"with","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":47,"column":13}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<b>SATNO</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"SATNO") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":44}}}))
    + "<br>\n					<b>Name</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":22,"column":19},"end":{"line":22,"column":42}}}))
    + "<br>\n					<b>Reentry Date</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"REENTRY_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":23,"column":27},"end":{"line":23,"column":79}}}))
    + "<br>\n					<b>Uncertainty</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"UNCERTAINTY") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":93}}}))
    + " day(s)<br>\n					<b>Mass</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"MASS") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":25,"column":19},"end":{"line":25,"column":79}}}))
    + " kg<br>\n					<b>Class</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"CLASS") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":44}}}))
    + "<br>\n					<b>Inclination</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"INCLINATION") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":27,"column":26},"end":{"line":27,"column":93}}}))
    + " deg<br>\n					<b>Perigee</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"PERIGEE") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":28,"column":22},"end":{"line":28,"column":85}}}))
    + " km<br>\n					<b>Apogee</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"APOGEE") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":29,"column":21},"end":{"line":29,"column":83}}}))
    + " km<br>\n					<b>Tool</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"TOOL") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":30,"column":19},"end":{"line":30,"column":42}}}))
    + "<br>\n					<b>Country</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"COUNTRY") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":31,"column":22},"end":{"line":31,"column":48}}}))
    + "\n					<div class=\"btn-group\" role=\"group\" style=\"width:100%; display:flex;justify-content:center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"isFollowed") : depths[2]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":41,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"showRequestRestrictedButton") : depths[2]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":46,"column":13}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "							<button type=\"button\" class=\"btn btn-primary followButton\" title=\"Unfollow event\" data-toggle=\"modal\" id=\"unfollow\">\n								<span class=\"fa fa-star\" aria-hidden=\"true\"></span>\n							</button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "							<button type=\"button\" class=\"btn btn-default followButton\" title=\"Follow event\" data-toggle=\"modal\" id=\"follow\">\n								<span class=\"fa fa-star-o\" aria-hidden=\"true\"></span>\n							</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "							<button type=\"button\" class=\"btn btn-default reqAddInfoButton\" title=\"Request Restricted Files\" data-toggle=\"modal\" data-target=\"#reqAddInfo\">\n								<span class=\"fa fa-lock\" aria-hidden=\"true\"></span>\n							</button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "	<center>\n		<button type=\"button\" class=\"btn btn-info uploadFileButton\" title=\"Upload File\" data-toggle=\"modal\" data-target=\"#uploadFile\" style=\"width:100%; color:#31708f; background-color:#d9edf7; border-color:#bce8f1\">\n			<span class=\"glyphicon glyphicon-upload\" aria-hidden=\"true\"></span> Upload File\n		</button>\n	</center>\n	<br>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<li>\n							<input class=\"detailFilesCheck\" type=\"checkbox\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":75,"column":61},"end":{"line":75,"column":70}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":75,"column":79},"end":{"line":75,"column":88}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isChecked") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":90},"end":{"line":75,"column":121}}})) != null ? stack1 : "")
    + ">\n								"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":76,"column":8},"end":{"line":76,"column":17}}}) : helper)))
    + "\n							</input>\n						</li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "checked";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"nameSearchValue") || (depth0 != null ? lookupProperty(depth0,"nameSearchValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nameSearchValue","hash":{},"data":data,"loc":{"start":{"line":83,"column":116},"end":{"line":83,"column":135}}}) : helper)))
    + " ";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"list-group-item list-group-item-arrow fileItem clickable\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":117,"column":77},"end":{"line":117,"column":87}}}) : helper)))
    + "\">\n				<span title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":118,"column":17},"end":{"line":118,"column":25}}}) : helper)))
    + "\">\n					<div class=\"col-sm-2\">\n						<span class=\"fa fa-"
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":120,"column":25},"end":{"line":120,"column":33}}}) : helper)))
    + " fa-fw\"></span>\n					</div>\n					<div class=\"row\">\n						<div class=\"col-sm-8\">\n							<h4 class=\"list-group-item-heading\" style=\"text-overflow: ellipsis; overflow: hidden; white-space: nowrap\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":124,"column":114},"end":{"line":124,"column":122}}}) : helper)))
    + "</h4>\n							<p class=\"list-group-item-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":125,"column":39},"end":{"line":125,"column":47}}}) : helper)))
    + "</p>\n						</div>\n					</div>\n				</div>\n			</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-info\">\n	<div class=\"panel-heading clickable\" id=\"detailEventInfoPanelHeading\">\n		<div class=\"panel-title clearfix\">\n      <div class=\"pull-left truncate_text\" style=\"width: 90%\">\n  			<span class=\"glyphicon glyphicon-info-sign\"/>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"reentryColumns") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":5},"end":{"line":10,"column":14}}})) != null ? stack1 : "")
    + "      </div>\n			<div class=\"btn-group pull-right\" role=\"group\">\n				<div class=\"detailEventInfoPanel\"/>\n			</div>\n		</div>\n	</div>\n	<div id=\"panelBodyDetailEventInfo\" class=\"panel-collapse collapse\" style=\"-webkit-transition: none; transition: none\">\n		<div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"reentryColumns") : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":48,"column":12}}})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":0},"end":{"line":61,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"detailFilesMenu\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":63,"column":43},"end":{"line":63,"column":56}}}) : helper)))
    + "\">\n	<div class=\"input-group\">\n		<div class=\"input-group-btn\">\n			<div class=\"dropdown\">\n				<button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n					<span class=\"glyphicon glyphicon-filter\"/>\n					<span class=\"caret\"></span>\n				</button>\n				<ul class=\"dropdown-menu detailFilesCheckList\" id=\"type\">\n					<h4>Type</h4>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fileTypes") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":5},"end":{"line":79,"column":14}}})) != null ? stack1 : "")
    + "				</ul>\n			</div>\n		</div>\n		<input type=\"text\" class=\"form-control text\" id=\"name\" placeholder=\"Filter By Name\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nameSearchValue") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":86},"end":{"line":83,"column":143}}})) != null ? stack1 : "")
    + ">\n		<div class=\"input-group-btn\">\n			<div class=\"dropdown\">\n				<button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n					<span class=\"glyphicon glyphicon-sort\"/>\n					<span class=\"caret\"></span>\n				</button>\n				<ul class=\"dropdown-menu dropdown-menu-right\">\n					<li>\n						<div class=\"sortItem clickable\" id=\"name-asc\">\n							Name (Ascending)\n						</div>\n					</li>\n					<li>\n						<div class=\"sortItem clickable\" id=\"name-desc\">\n							Name (Descending)\n						</div>\n					</li>\n					<li>\n						<div class=\"sortItem clickable\" id=\"type-asc\">\n							Type (Ascending)\n						</div>\n					</li>\n					<li>\n						<div class=\"sortItem clickable\" id=\"type-desc\">\n							Type (Descending)\n						</div>\n					</li>\n				</ul>\n			</div>\n		</div>\n	</div>\n	<div class=\"scroll-y-auto list-group\" style=\"height:85%\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"detailFiles") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":2},"end":{"line":130,"column":11}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true,"useDepths":true});