/*
	Global Reentry Objects View which populates the required sub-reentry views
	e.g list of reentry objects, filtering, ask admin.
*/
import Backbone from 'backbone'

import reentryFollowCollection from '../models/ReentryFollowCollection'
import reentryRestrictedCollection from '../models/ReentryRestrictedCollection'
import { ReentryFilterView } from './ReentryFilterView'
import { ReentryCollectionView } from './ReentryCollectionView'
import { AskAdminView } from './AskAdminView'

export const ReentryView = Backbone.View.extend({
  template: require('../../templates/ReentryView.hbs'),

  askAdminWindowOpened: false,

  initialize(options) {
    console.log('Initialized Reentry View')

    this.router = options.router

    this.isAdmin = options.accessLevel === 'admin' ? true : false

    this.askAdminView = new AskAdminView({
      cosparId: null,
      userId: null,
      isAdminUser: options.accessLevel === 'admin' ? true : false,
      view: 'reentry',
    })

    this.listenTo(reentryFollowCollection, 'sync add', function (e) {
      this.reentryCollectionView = new ReentryCollectionView({
        accessLevel: options.accessLevel,
        router: this.router,
        divHeight: this.$el.parent().height(),
      })
      this.renderCollection()
    })

    this.filterView = new ReentryFilterView()
  },

  events: {
    'click .askAdminPanelHeading-reentry': 'reentryAskAdminPanelHeading',
  },

  renderFilter() {
    this.listenTo(reentryRestrictedCollection, 'sync', function (e) {
      this.el
        .querySelector('.reentryCollectionFilter')
        .appendChild(this.filterView.render().el)
    })
    return this
  },

  renderAskAdmin() {
    if (this.el.querySelector('.askAdminWindow-reentry')) {
      this.$el.find('.askAdminWindow-reentry').remove()
    }

    this.el
      .querySelector('.reentryAskAdminView')
      .appendChild(this.askAdminView.render(this.askAdminWindowOpened).el)
  },

  renderCollection() {
    if (this.el.querySelector('.reentrycollection')) {
      this.$el.find('.reentryCollection').remove()
    }
    this.el
      .querySelector('.reentryCollectionView')
      .appendChild(this.reentryCollectionView.render().el)
    return this
  },

  render() {
    this.filterDivHeight = this.$el.parent().height() - 90
    const markup = this.template({
      filterDivHeight: this.filterDivHeight + 'px',
    })
    this.$el.html(markup)
    this.renderFilter()
    this.renderAskAdmin()
    this.trigger('didRender')
    return this
  },

  reentryAskAdminPanelHeading(e) {
    if (this.el.querySelector('.expandReentryAskAdmin')) {
      // if ask admin window closed, open it.
      // change the icon to opened in header
      // reduce filter div height
      this.$el.find('.expandReentryAskAdmin').remove()
      if (this.el.querySelector('.newMessages-reentry') && !this.isAdmin) {
        this.askAdminView.updateNewMessageStatus()
        this.el.querySelector('.newMessages-reentry').remove()
      }
      this.$el.find('#panelBodyAskAdmin-reentry').collapse('show')
      this.filterDivHeight = this.filterDivHeight - 335
      this.el.querySelector('.reentryFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-top collapseReentryAskAdmin',
        '.askAdminPanelExpand-reentry',
      )
      if (this.el.querySelector('.askAdminChatBox-reentry')) {
        this.el.querySelector('.askAdminChatBox-reentry').scrollTop =
          this.el.querySelector('.askAdminChatBox-reentry').scrollHeight
      }
      this.askAdminWindowOpened = true
    } else {
      // if ask admin window opened, close it.
      // change the icon to closed in header
      // increase filter div height
      this.$el.find('.collapseReentryAskAdmin').remove()
      this.$el.find('#panelBodyAskAdmin-reentry').collapse('hide')
      this.filterDivHeight = this.filterDivHeight + 335
      this.el.querySelector('.reentryFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-right expandReentryAskAdmin',
        '.askAdminPanelExpand-reentry',
      )
      this.askAdminWindowOpened = false
    }
  },

  addPanelButton(spanClass, appendClass) {
    var span = document.createElement('span')
    span.className += spanClass

    this.el.querySelector(appendClass).appendChild(span)
  },
})
