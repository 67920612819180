import _ from 'lodash'
import $ from 'jquery'
import B from 'backbone'
B.$ = $

import '../jqplugins'
import '../helpers/handlebarsHelpers'
import messageBus from '../models/MessageBus'
import {
  containers as mapContainers,
  routes as mapRoutes,
} from '../config/mapper'
import { DetailView } from '../views/DetailView'
import GrowlView from '../views/GrowlView'
import { HomeView } from '../views/HomeView'
import XhrErrorView from '../views/XhrErrorView'
import { NavigationUserView } from '../views/NavigationUserView'
import { ReentryView } from '../views/ReentryView'
import { AdminView } from '../views/AdminView'

let activeView = null
let container = {}
let userRights = 'public'
let isDetailViewInitialized = false
let detailView = null

let router
let setContainer

const RouterManager = B.Router.extend({
  initialize() {
    this.listenTo(messageBus, 'xhrError', this.xhrError)
  },

  routes: mapRoutes,

  reentry(actions) {
    goToView('reentry')
  },

  detail(id) {
    const detailViewObject = {
      el: container.detail,
      router,
      userRights,
      cosparId: id,
    }
    if (isDetailViewInitialized) {
      detailView.undelegateEvents()
    }
    detailView = new DetailView(detailViewObject)
    isDetailViewInitialized = true
    goToView('detail')
  },

  home(page, id) {
    goToView('home', { page, id })
  },

  blog(id) {
    goToView('home', { page: 'blog' })
  },

  admin(id) {
    goToView('admin')
  },

  defaultRoute(actions) {
    goToView('home')
  },
})

router = new RouterManager()

$('#nav-home').on('click', (event) => {
  event.preventDefault()
  router.navigate('home', { trigger: true })
})

$('#nav-reentries').on('click', (event) => {
  event.preventDefault()
  router.navigate('reentry', { trigger: true })
})

$('#nav-admin').on('click', (event) => {
  event.preventDefault()
  router.navigate('admin', { trigger: true })
})

$('#nav-title').on('click', (event) => {
  event.preventDefault()
  router.navigate('home', { trigger: true })
})

let homeView

// navigate to view
function goToView(id, options) {
  if (activeView !== id) {
    if (id === 'detail') {
      $('#detail').show()
    } else {
      $('#container-detail').empty()
      $('#detail').hide()
    }

    console.log("changing to view '%s'", id)
    if (id !== 'xhrerror') {
      const nav = document.querySelector('#mainNavbarId')
      nav.querySelector('.active')?.classList.remove('active')
      nav.querySelector(`#${id}`).classList.add('active')
    }
    activeView = id
    const keys = Object.keys(container)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      container[key].style.display = 'none'
    }
    console.log(`Setting container ${id} to display: block`)
    container[id].style.display = 'block'

    if (id === 'admin' && document.querySelector('.askAdminChatBox-admin')) {
      document.querySelector('.askAdminChatBox-admin').scrollTop =
        document.querySelector('.askAdminChatBox-admin').scrollHeight
    }
  }

  if (id === 'home') {
    const page = options && options.page
    const id = options && options.id
    if (page) {
      homeView.openTab(page)

      if (page === 'blog') {
        if (id) {
          homeView.homeBlogCollectionView.renderItem(id)
        } else {
          // /home/blog should render main blog
          homeView.homeBlogCollectionView.renderMainBlog()
        }
      }
    } else {
      // this is the default view of /home
      homeView.homeBlogCollectionView.renderMainBlog()
    }
  }
}

var ViewManager = function ViewManager() {}

ViewManager.prototype.setUserRights = function setUserRights(userCheck) {
  userRights = userCheck
  setContainer()
}

setContainer = function setContainer() {
  container = {}

  Object.keys(mapContainers).forEach(
    (key) => (container[key] = document.querySelector(mapContainers[key])),
  )
}

ViewManager.prototype.renderViews = function renderViews() {
  // growl notification view
  const growlView = new GrowlView()
  document.body.appendChild(growlView.render().el)

  // XHR error View
  const xhrErrorView = new XhrErrorView({
    el: container.xhrerror,
  })
  xhrErrorView.render()

  const waitForRender = []
  const renderLater = []

  // Home View
  homeView = new HomeView({
    el: container.home,
    router,
  })

  waitForRender.push(homeView)

  if (userRights === 'protected' || userRights === 'admin') {
    // NavigationUserView
    const navigationUserView = new NavigationUserView({
      el: document.querySelector('ul.nav.user'),
    })
    waitForRender.push(navigationUserView)
    renderLater.push(navigationUserView)

    // Reentry View
    const reentryView = new ReentryView({
      el: container.reentry,
      router,
      accessLevel: userRights,
    })
    waitForRender.push(reentryView)
    renderLater.push(reentryView)

    if (userRights === 'admin') {
      // Admin View
      const adminView = new AdminView({
        el: container.admin,
        router,
      })
      waitForRender.push(adminView)
      renderLater.push(adminView)
    }
  }

  const allRenders = waitForRender.map(
    (view) => new Promise((resolve) => view.on('didRender', _.once(resolve))),
  )

  // Now that we have set up a promise to wait on the renders, start rendering.
  renderLater.forEach((view) => view.render())
  homeView.loadData()

  Promise.all(allRenders)
    .then(() => B.history.start({ pushState: true }))
    .catch((err) => console.error(err))
}

export default new ViewManager()
