/*
	Model/collection to store orbit collection of each reentry object.
*/
import Backbone from 'backbone'

import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

var ReentryOrbitModel = Backbone.Model.extend({
  idAttribute: reentryColumns.COSPAR_ID,
})

var ReentryOrbitCollection = Backbone.Collection.extend({
  model: ReentryOrbitModel,
  initialize() {},
})

var reentryOrbitCollection = new ReentryOrbitCollection()

// make it a singleton
export default reentryOrbitCollection
