/*
	Displays the filter menu to filter reentry objects.
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import reentryFilterModel from '../models/ReentryFilterModel'
import reentryModel from '../models/ReentryModel'
import ChecklistInputView from './ChecklistInputView'
import SelectorInputView from './SelectorInputView'
import TextInputView from './TextInputView'
import RangeInputView from './RangeInputView'
import TimeInputView from './TimeInputView'
import { reentryFieldNames } from '../../../common/config/constants'
import whoAmI from '../models/WhoAmI'

export const ReentryFilterView = Backbone.View.extend({
  template: require('../../templates/ReentryFilterView.hbs'),

  reentryDisplayCollection: null,

  autoFocusField: null,

  isAdmin: false,

  initialize(options) {
    console.log('Initialized Reentry Filter View')

    // The design of the filtering is that the controls re-render when the
    // filters change. In order to avoid this, we keep a dataCollectionKey which
    // is tied to the unfiltered data. We will only re-render the controls
    // when this changes. At the moment this is just when the data first loads.
    //
    // See the checkDataCollection and render methods to see how they use these
    // variables.
    this.prevDataCollection = null
    this.dataCollectionKey = 0
    this.renderedDataCollectionKey = null

    this.reentryDataColleciton = reentryModel.reentryDataCollection
    this.listenTo(this.reentryDataColleciton, 'reset', this.checkDataCollection)

    this.reentryDisplayCollection = reentryModel.getDisplayCollection()
    this.listenTo(this.reentryDisplayCollection, 'reset', this.render)
  },

  events: {
    'click .remove-text-value': 'removeTextValue',
    'click .remove-time-value': 'removeTimeValue',
    'click input.check': 'applyCheckListFilter',
    'change input.range': 'applyRangeFilter',
    'input input.text': 'applyTextFilter',
    'changeDate .filter_datetime': 'applyTimeFilter',
    'click .filterFollow': 'applyFollowFilter',
    'click .filterRestricted': 'applyRestrictedFilter',
  },

  checkDataCollection(dataCollection) {
    dataCollection = dataCollection.toJSON()
    console.log('checking dataCollection', dataCollection)
    if (!_.isEqual(this.prevDataCollection, dataCollection)) {
      console.log('incrementing key to', this.dataCollectionKey)
      this.dataCollectionKey++
      this.prevDataCollection = dataCollection
    }
  },

  // render filter type specific views
  filterOptions(view, labelName, idName, options) {
    let tempView
    switch (view) {
      case 'text':
        tempView = new TextInputView({
          input: {
            label: labelName,
            id: idName,
            value: reentryFilterModel.has(idName)
              ? reentryFilterModel.get(idName).text
              : '',
          },
        })
        break

      case 'checkbox':
        tempView = new ChecklistInputView({
          input: {
            label: labelName,
            id: idName,
          },
          collection: reentryModel.getCheckList(options),
        })
        break

      case 'selector':
        tempView = new SelectorInputView({
          input: {
            label: labelName,
            id: idName,
            selectors: options,
          },
        })
        break

      case 'range':
        tempView = new RangeInputView({
          input: {
            label:
              reentryModel.getDisplayCollection().toJSON().length > 0
                ? labelName +
                  ' (' +
                  reentryModel.getMinRange(options) +
                  ' : ' +
                  reentryModel.getMaxRange(options) +
                  ')'
                : labelName,
            id: idName,
            valueMin: reentryModel.getMinValue(options),
            valueMax: reentryModel.getMaxValue(options),
            rangeMin: reentryModel.getMinRange(options),
            rangeMax: reentryModel.getMaxRange(options),
            rangeFilterShow:
              reentryModel.getDisplayCollection().toJSON().length > 0,
          },
        })
        break

      case 'time':
        tempView = new TimeInputView({
          input: {
            label: labelName,
            id: idName,
            valueFrom: reentryFilterModel.has(idName + '-From')
              ? moment
                  .unix(
                    reentryFilterModel.get(idName + '-From').dateFrom / 1000,
                  )
                  .utc()
                  .format('DD-MMM-YYYY')
              : '',
            valueTo: reentryFilterModel.has(idName + '-To')
              ? moment
                  .unix(reentryFilterModel.get(idName + '-To').dateTo / 1000)
                  .utc()
                  .format('DD-MMM-YYYY')
              : '',
          },
        })
        break
    }

    return tempView
  },

  render() {
    if (this.renderedDataCollectionKey === this.dataCollectionKey) {
      return
    }
    this.renderedDataCollectionKey = this.dataCollectionKey

    const filterMarkup = this.template({
      isFollowedFilterApplied: reentryFilterModel.has('followedFilter'),
      isRestrictedFilterApplied: reentryFilterModel.has('restrictedFilter'),
      showRestrictedFilter: whoAmI.get('isPrivileged'),
    })

    const container = document.createDocumentFragment()
    const div = document.createElement('div')
    div.innerHTML = filterMarkup
    container.appendChild(div.childNodes[0])

    const filter = document.createDocumentFragment()
    const { filterOptions } = this

    // Append the following filters
    const children = [
      filterOptions('text', 'COSPAR ID', reentryFieldNames.COSPAR_ID, ''),
      filterOptions('text', 'SATNO', reentryFieldNames.SATNO, ''),
      filterOptions('text', 'Name', reentryFieldNames.NAME, ''),
      filterOptions('time', 'Reentry Date', reentryFieldNames.REENTRY_DATE, ''),

      // these fields need to be updated
      filterOptions(
        'range',
        'Mass<sub>kg</sub>',
        reentryFieldNames.MASS,
        reentryFieldNames.MASS,
      ),
      filterOptions(
        'checkbox',
        'Class',
        reentryFieldNames.CLASS,
        reentryFieldNames.CLASS,
      ),
      filterOptions(
        'range',
        'Inclination<sub>degree</sub>',
        reentryFieldNames.INCLINATION,
        reentryFieldNames.INCLINATION,
      ),
      filterOptions(
        'range',
        'Perigee<sub>km</sub>',
        reentryFieldNames.PERIGEE,
        reentryFieldNames.PERIGEE,
      ),
      filterOptions(
        'range',
        'Apogee<sub>km</sub>',
        reentryFieldNames.APOGEE,
        reentryFieldNames.APOGEE,
      ),

      filterOptions('text', 'Tool', reentryFieldNames.TOOL, ''),
      filterOptions('text', 'Country', reentryFieldNames.COUNTRY, ''),
    ]

    children.forEach((child) => filter.appendChild(child.render().el))
    container.querySelector('.filter-properties').appendChild(filter)

    this.$el.html(container)

    // Autofocus if last filter was text search
    if (this.autoFocusField !== null) {
      this.el.querySelector('#' + this.autoFocusField).focus()
    }
    return this
  },

  applyCheckListFilter(e) {
    console.log('CheckList clicked', e)
    this.autoFocusField = null
    var inputGroup = $(e.target).parents('div.input-group')
    var input = inputGroup.children('input.value')
    var inputValues = []
    inputGroup.find('input.check').each(function (index) {
      var checked = $(this).prop('checked')
      if (!checked) {
        inputValues.push($(this).prop('value'))
      }
    })
    reentryFilterModel.set($(e.target).parents('div.input-group').prop('id'), {
      checkbox: inputValues,
    })
  },

  applyRangeFilter(e) {
    console.log('Range clicked', e)
    this.autoFocusField = null
    reentryFilterModel.set(e.currentTarget.id, { range: e.currentTarget.value })
  },

  applyTextFilter(e) {
    this.autoFocusField = e.currentTarget.id
    console.log('Text searched', e)
    reentryFilterModel.set(e.currentTarget.id, { text: e.currentTarget.value })
  },

  removeTextValue(e) {
    console.log('Remove Text Value', e)
    const { targetId } = e.currentTarget.dataset

    this.$el.find(`#${targetId}`).val('')
    reentryFilterModel.set(targetId, { text: '' })
  },

  applyTimeFilter(e) {
    console.log('Time clicked', e)
    this.autoFocusField = null
    var id = e.currentTarget.id
    var field = id.split('-')[1]
    var fromTo = id.split('-')[0]
    switch (fromTo) {
      case 'from':
        this.el.querySelector('#text-' + id).value = moment
          .utc(e.date)
          .format('DD-MMM-YYYY')
        reentryFilterModel.set(field + '-From', {
          dateFrom: moment.utc(e.date).format('x'),
        })
        break
      case 'to':
        this.el.querySelector('#text-' + id).value = moment
          .utc(e.date)
          .format('DD-MMM-YYYY')
        reentryFilterModel.set(field + '-To', {
          dateTo: moment.utc(e.date).format('x'),
        })
        break
    }
  },

  applyFollowFilter(e) {
    console.log('Follow Filter clicked', e)
    if (reentryFilterModel.has('followedFilter')) {
      reentryFilterModel.unset('followedFilter')
      this.$el.find(`#filterFollow`).removeClass('active')
    } else {
      reentryFilterModel.set('followedFilter', true)
      this.$el.find(`#filterFollow`).addClass('active')
    }
  },

  applyRestrictedFilter(e) {
    console.log('Restricted Filter clicked', e)
    if (reentryFilterModel.has('restrictedFilter')) {
      reentryFilterModel.unset('restrictedFilter')
      this.$el.find(`#filterRestricted`).removeClass('active')
    } else {
      reentryFilterModel.set('restrictedFilter', true)
      this.$el.find(`#filterRestricted`).addClass('active')
    }
  },

  removeTimeValue(e) {
    console.log('Remove Time clicked', e)
    this.autoFocusField = null
    var id = e.currentTarget.id
    var field = id.split('-')[1]
    var fromTo = id.split('-')[0]
    switch (fromTo) {
      case 'removeFrom':
        this.el.querySelector('#text-from-' + field).value = ''
        reentryFilterModel.unset(field + '-From')
        break
      case 'removeTo':
        this.el.querySelector('#text-to-' + field).value = ''
        reentryFilterModel.unset(field + '-To')
        break
    }
  },
})
