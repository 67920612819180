/*
	To display the upload file modal
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import messageBus from '../models/MessageBus'

export const DetailFilesUploadItemView = Backbone.View.extend({
  template: require('../../templates/DetailFilesUploadItem.hbs'),

  uploadFileModel: null,

  initialize(options) {
    console.log('Initialized Details Files Upload Item View')

    this.detailModel = options.detailModel

    this.cosparId = options.cosparId
  },

  events: {
    'click button.uploadFileSubmit': 'uploadFileSubmit',
  },

  render() {
    var currentDateTime = moment.utc().format('DD-MMM-YYYY HH:mm:ss')
    var markup = this.template({ currentDateTime })
    this.$el.html(markup)

    this.$el.find('input.uploadFileDate').datetimepicker({
      autoclose: 1,
      format: 'dd-M-yyyy hh:ii:ss',
    })
    return this
  },

  uploadFileSubmit(e) {
    e.preventDefault()
    console.log(e)
    var files = this.el.querySelector('#uploadFileId').files
    var type = this.el.querySelector('#uploadFileType').value
    var date = moment(
      this.el.querySelector('#uploadFileDate').value,
      'DD-MMM-YYYY HH:mm:ss',
    )
      .utc()
      .format('YYYYMMDDHHmmss')
    if (files.length > 0) {
      var file = files[0]
      var collection = this.detailModel.getFilesCollection()
      this.uploadFileModel = new collection.model()

      this.uploadFileModel.set({
        file,
        type,
        date,
        fileName: file.name,
        cosparId: this.cosparId,
      })

      this.uploadFileModel.idAttribute = null

      this.uploadFileModel
        .save()
        .success(function (model) {
          collection.add(model)
          messageBus.trigger('growl', {
            type: 'success',
            content:
              'File uploaded!\n Name - ' +
              name +
              '\n Type - ' +
              type +
              '\n Date - ' +
              date,
          })
        })
        .error(function (model, error) {
          messageBus.trigger('growl', {
            type: 'danger',
            content: error.responseText,
          })
        })
    }
  },
})
