import $ from 'jquery'
import Backbone from 'backbone'

import { SettingsModalView } from './SettingsModal'
import whoAmIModel from '../models/WhoAmI'

export const NavigationUserView = Backbone.View.extend({
  whoAmIModel,

  template: require('../../templates/NavigationUserView.hbs'),

  initialize() {
    this.listenTo(this.whoAmIModel, 'sync', this.render)
  },

  events: {
    'click #logout': 'handleLogout',
  },

  render() {
    var markup = this.template({
      user: this.whoAmIModel.toJSON(),
    })
    this.$el.html(markup)

    var settingsModalView = new SettingsModalView()
    var settingsViewMarkup = settingsModalView.render()
    $('body').append(settingsViewMarkup.el)

    console.log('rendered NavigationUserView')
    this.trigger('didRender')

    return this
  },

  handleLogout(event) {
    event.preventDefault()
    fetch('/auth/logout', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRFToken': CONSTANTS.csrfToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data.redirect
      })
  },
})
