var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a class=\"btn btn-info btn-sm download\" href="
    + alias4(((helper = (helper = lookupProperty(helpers,"item") || (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item","hash":{},"data":data,"loc":{"start":{"line":7,"column":50},"end":{"line":7,"column":58}}}) : helper)))
    + " download=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"itemName") || (depth0 != null ? lookupProperty(depth0,"itemName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemName","hash":{},"data":data,"loc":{"start":{"line":7,"column":69},"end":{"line":7,"column":81}}}) : helper)))
    + "\" id=\"download-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":7,"column":96},"end":{"line":7,"column":105}}}) : helper)))
    + "\">\n							<span class=\"glyphicon glyphicon-download-alt\"/>\n					</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button class=\"btn btn-info btn-sm fullScreen\" type=\"button\" id=\"fullscreen-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":12,"column":81},"end":{"line":12,"column":90}}}) : helper)))
    + "\">\n						<span class=\"glyphicon glyphicon-fullscreen\"/>\n					</button>\n					<button class=\"btn btn-danger btn-sm itemRemove\" type=\"button\" id=\"itemRemove-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":15,"column":83},"end":{"line":15,"column":92}}}) : helper)))
    + "\">\n						<span class=\"glyphicon glyphicon-remove\"/>\n					</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "					<div class=\"col-md-1\">\n						<div>\n							<span class=\"glyphicon glyphicon-fast-backward disable-color\"/>\n						</div>\n					</div>\n					<div class=\"col-md-1\">\n						<div>\n							<span class=\"glyphicon glyphicon-triangle-left disable-color\"/>\n						</div>\n					</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"col-md-1\">\n						<div class=\"clickable itemChange\" id=\"firstImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":43,"column":53},"end":{"line":43,"column":62}}}) : helper)))
    + "\">\n							<span class=\"glyphicon glyphicon-fast-backward\"/>\n						</div>\n					</div>\n					<div class=\"col-md-1\">\n						<div class=\"clickable itemChange\" id=\"prevImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":48,"column":52},"end":{"line":48,"column":61}}}) : helper)))
    + "\">\n							<span class=\"glyphicon glyphicon-triangle-left\"/>\n						</div>\n					</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<li class=\"clickable changeDate\" id=\""
    + alias2(alias1(depth0, depth0))
    + "|"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "					<div class=\"col-md-1\">\n						<div>\n							<span class=\"glyphicon glyphicon-triangle-right disable-color\"/>\n						</div>\n					</div>\n					<div class=\"col-md-1\">\n						<div>\n							<span class=\"glyphicon glyphicon-fast-forward disable-color\"/>\n						</div>\n					</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"col-md-1\">\n						<div class=\"clickable itemChange\" id=\"nextImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":81,"column":52},"end":{"line":81,"column":61}}}) : helper)))
    + "\">\n							<span class=\"glyphicon glyphicon-triangle-right\"/>\n						</div>\n					</div>\n					<div class=\"col-md-1\">\n						<div class=\"clickable itemChange\" id=\"lastImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":86,"column":52},"end":{"line":86,"column":61}}}) : helper)))
    + "\">\n							<span class=\"glyphicon glyphicon-fast-forward\"/>\n						</div>\n					</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-primary\">\n	<div class=\"panel-heading\">\n		<div class=\"panel-title\" style=\"display:flex;justify-content:space-between\">\n			"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelHeading") || (depth0 != null ? lookupProperty(depth0,"panelHeading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelHeading","hash":{},"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":4,"column":19}}}) : helper)))
    + "\n			<div class=\"btn-group\" role=\"group\" style=\"display:inherit\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"downloadIconDisplay") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleView") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n	<div class=\"panel-body\" style=\"height:"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelBodyHeight") || (depth0 != null ? lookupProperty(depth0,"panelBodyHeight") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelBodyHeight","hash":{},"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":58}}}) : helper)))
    + "\">\n		<div class=\"detailItem\" id=\"detailItem-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":23,"column":41},"end":{"line":23,"column":50}}}) : helper)))
    + "\"></div>\n	</div>\n	<div class=\"panel-footer\">\n		<center>\n			<div class=\"row\" style=\"color:#000000\">\n				<div class=\"col-md-1\">\n				</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirstItem") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "")
    + "				<div class=\"col-md-6\">\n					<center>\n						<div class=\"btn-group dropup\">\n							<button type=\"button\" class=\"btn btn-info dropdown-toggle "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"datesDropDownDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":65},"end":{"line":56,"column":111}}})) != null ? stack1 : "")
    + "\" data-toggle=\"dropdown\">\n								"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayDate") || (depth0 != null ? lookupProperty(depth0,"displayDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayDate","hash":{},"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":57,"column":23}}}) : helper)))
    + "\n								<span class=\"caret\"></span>\n							</button>\n							<ul class=\"dropdown-menu scrollable-menu\" role=\"menu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"includeDates") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":63,"column":17}}})) != null ? stack1 : "")
    + "							</ul>\n						</div>\n					</center>\n				</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLastItem") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":68,"column":4},"end":{"line":90,"column":11}}})) != null ? stack1 : "")
    + "				<div class=\"col-md-1\">\n				</div>\n			</div>\n		</center>\n	</div>\n\n</div>\n";
},"useData":true,"useDepths":true});