/*
	Model/collection to fetch reentry objects follow status from server.
*/
import Backbone from 'backbone'

import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

const ReentryFollowModel = Backbone.Model.extend({
  urlRoot: '/api/protected/reentryFollow',
  idAttribute: reentryColumns.COSPAR_ID,
})

const ReentryFollowCollection = Backbone.Collection.extend({
  model: ReentryFollowModel,
  url: '/api/protected/reentryFollow',

  initialize() {
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

const reentryFollowCollection = new ReentryFollowCollection()

// make it a singleton
export default reentryFollowCollection
