/*
	Admin View
*/

import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import { UserEventItemView } from './UserEventItemView'
import adminUserFilterModel from '../models/AdminUserFilterModel'
import adminEventFilterModel from '../models/AdminEventFilterModel'
import adminModel from '../models/AdminModel'
import SettingsCollection from '../models/SettingsCollection'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

export const AdminView = Backbone.View.extend({
  template: require('../../templates/AdminView.hbs'),

  divheight: 0,

  // active email id index
  setEmailId: 0,

  // active cospar id index
  setCosparId: 0,

  // render only if user and event lists are both set to true
  userListSet: false,

  eventListSet: false,

  userCollection: null,

  // Stores text put in filter of email and cospar id.
  emailSearchValue: '',

  eventSearchValue: '',

  // Auto focusses to text filter if search going on
  autoFocusField: null,

  initialize(options) {
    console.log('Initialized Admin View')

    this.router = options.router

    this.divHeight = this.$el.height()

    this.userCollection = adminModel.getDisplayUserCollection()
    this.eventCollection = adminModel.getDisplayEventCollection()

    this.listenTo(this.userCollection, 'reset', function (e) {
      this.userList = this.userCollection.toJSON()
      this.displayUserList = this.userList
      this.userListSet = true
      this.userDomainCheckBox = adminModel.getUserDomainCheckBoxList()
      this.render()
    })

    this.listenTo(this.eventCollection, 'reset', function (e) {
      var eventCollection = this.eventCollection.toJSON()
      this.eventDetailList = _.uniqBy(
        this.eventCollection.toJSON(),
        function (item) {
          return item[reentryColumns.COSPAR_ID]
        },
      )
      var genericObject = {}
      genericObject[reentryColumns.COSPAR_ID] = 'Generic'
      this.eventDetailList.push(genericObject)
      this.displayEventDetailList = this.eventDetailList
      this.eventListSet = true
      this.render()
    })
  },

  events: {
    'click .usersEmailListItem': 'usersEmailListItem',
    'click .eventsListItem': 'eventsListItem',
    'input input.text': 'applyTextFilter',
    'click input[type=checkbox]': 'applyCheckListFilter',
  },

  // Render user settings, ask admin and event info
  renderUserEventItem() {
    if (this.el.querySelector('.selectItem')) {
      this.$el.find('.selectItem').remove()
      this.el
        .querySelector('.selectionItem')
        .appendChild(this.userEventItemView.render(this.divHeight, false).el)
    }

    const cosparId = _.map(
      this.displayEventDetailList,
      reentryColumns.COSPAR_ID,
    )[this.setCosparId]
    const userId = _.map(this.displayUserList, 'email')[this.setEmailId]

    const settingsCollection = new SettingsCollection({
      cosparId,
      userId,
    })

    this.listenTo(settingsCollection, 'sync', function () {
      this.userEventItemView = new UserEventItemView({
        router: this.router,
        cosparId: _.map(this.displayEventDetailList, reentryColumns.COSPAR_ID)[
          this.setCosparId
        ],
        eventInfo: this.displayEventDetailList[this.setCosparId],
        userInfo: this.displayUserList[this.setEmailId],
        userSettings: settingsCollection,
      })
      if (this.el.querySelector('.selectItem')) {
        this.$el.find('.selectItem').remove()
      }
      this.el
        .querySelector('.selectionItem')
        .appendChild(this.userEventItemView.render(this.divHeight, true).el)
    })
  },

  render() {
    let dataPopulated = false
    if (this.userListSet && this.eventListSet) {
      dataPopulated = true
    }
    const markup = this.template({
      eventList: _.map(this.displayEventDetailList, reentryColumns.COSPAR_ID),
      userList: _.map(this.displayUserList, 'email'),
      divHeight: this.divHeight - 40 + 'px',
      eventSearchValue: adminEventFilterModel.has(reentryColumns.COSPAR_ID)
        ? adminEventFilterModel.get(reentryColumns.COSPAR_ID).text
        : '',
      emailSearchValue: adminUserFilterModel.has('email')
        ? adminUserFilterModel.get('email').text
        : '',
      userDomainCheckBox: this.userDomainCheckBox,
      reentryColumns,
      dataPopulated,
    })
    this.$el.html(markup)
    if (dataPopulated === true) {
      this.$el.find('#usersEmailListItem-' + this.setEmailId).addClass('active')
      this.$el.find('#eventsListItem-' + this.setCosparId).addClass('active')

      // Autofocus if last filter was text search
      if (this.autoFocusField !== null) {
        this.el.querySelector('#' + this.autoFocusField).focus()
        this.el.querySelector('#' + this.autoFocusField).value =
          this.el.querySelector('#' + this.autoFocusField).value
      }

      this.renderUserEventItem()
    }
    this.trigger('didRender')
    return this
  },

  usersEmailListItem(e) {
    this.$el
      .find('#usersEmailListItem-' + this.setEmailId)
      .removeClass('active')

    this.setEmailId = e.currentTarget.id.split('-')[1]
    this.$el.find('#usersEmailListItem-' + this.setEmailId).addClass('active')
    this.renderUserEventItem()
  },

  eventsListItem(e) {
    this.$el.find('#eventsListItem-' + this.setCosparId).removeClass('active')

    this.setCosparId = e.currentTarget.id.split('-')[1]
    this.$el.find('#eventsListItem-' + this.setCosparId).addClass('active')
    this.renderUserEventItem()
  },

  applyTextFilter: _.debounce(function (e) {
    console.log('Text searched', e)
    this.autoFocusField = e.currentTarget.id
    if (e.currentTarget.id.split('-')[0] === 'user') {
      adminUserFilterModel.set(e.currentTarget.id.split('-')[1], {
        text: e.currentTarget.value,
      })
    } else {
      adminEventFilterModel.set(e.currentTarget.id.split('-')[1], {
        text: e.currentTarget.value,
      })
    }
  }, 500),

  applyCheckListFilter(e) {
    console.log('CheckList clicked', e)
    this.autoFocusField = null

    var inputGroup = $(e.target).parents('div.input-group')
    var inputValues = []
    inputGroup.find('.' + e.currentTarget.className).each(function (index) {
      var checked = $(this).prop('checked')
      if (!checked) {
        inputValues.push($(this).prop('value'))
      }
    })

    var formId = $(e.currentTarget)
      .parents('.' + e.currentTarget.className + 'Form')
      .prop('id')

    if (formId.split('-')[0] === 'user') {
      adminUserFilterModel.set(formId.split('-')[1], { checkbox: inputValues })
    } else {
      adminEventFilterModel.set(formId.split('-')[1], { checkbox: inputValues })
    }
  },
})
