/*
	Model/collection to fetch reentry objects restricted status from server.
*/
import Backbone from 'backbone'

import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

var ReentryRestrictedModel = Backbone.Model.extend({
  urlRoot: '/api/protected/reentryRestricted',
  idAttribute: reentryColumns.COSPAR_ID,
})

var ReentryRestrictedCollection = Backbone.Collection.extend({
  model: ReentryRestrictedModel,
  url: '/api/protected/reentryRestricted',

  initialize() {
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

var reentryRestrictedCollection = new ReentryRestrictedCollection()

// make it a singleton
export default reentryRestrictedCollection
