/*
	Class to display home view.
*/
import Backbone from 'backbone'

import _ from 'lodash'
import 'typeahead.js'
import Bloodhound from 'typeahead.js/dist/bloodhound.js'
import 'bootstrap-tagsinput'
import HomeCollection from '../models/HomeCollection'
import { HomeBlogCollectionView } from './HomeBlogCollectionView'

export const HomeView = Backbone.View.extend({
  template: require('../../templates/HomeView.hbs'),

  homeCollection: null,

  initialize(options) {
    this.homeCollection = new HomeCollection()
    this.router = options.router

    this.divHeight = this.$el.height()
    this.divWidth = this.$el.width()

    this.listenTo(this.homeCollection, 'sync', () => {
      this.homeBlogCollectionView = new HomeBlogCollectionView({
        blogs: this.homeCollection.toJSON()[0].blogs,
        router: this.router,
      })
      this.blogsToRender = this.homeCollection.toJSON()[0].blogs
      this.render()
    })
  },

  events: {
    'click .jumbotron': 'renderCarouselItem',
    'click .link': 'openLink',
    'click .tab': 'clickTab',
    'itemAdded #tagBlog': 'filterBlogItems',
    'itemRemoved #tagBlog': 'filterBlogItems',
    'click #reentry': 'clickReentryTab',
    'click #blog': 'clickBlogTab',
    'click #article-blog-home': 'clickBlogTab',
  },

  // Defer loading to control when initial render is carried out
  loadData() {
    this.homeCollection.fetch({ reset: true })
  },

  clickBlogTab(e) {
    // This is here in case there is a blog article open and the user
    // clicks the Blog tab rather than going back.
    e.preventDefault()
    this.homeBlogCollectionView.renderMainBlog()
    this.router.navigate('/home/blog')
  },

  clickReentryTab(e) {
    console.log(e)
    e.preventDefault()
  },

  render() {
    var markup = this.template({
      divHeight: this.divHeight + 'px',
      carouselCollection: this.homeCollection.toJSON()[0].carousel,
      plotsCollection: this.homeCollection.toJSON()[0].plots,
      reentriesCollection: this.homeCollection.toJSON()[0].reentries,
      historyHtmlContent: this.homeCollection.toJSON()[0].history.htmlContent,
      modellingHtmlContent:
        this.homeCollection.toJSON()[0].modelling.htmlContent,
      observationsHtmlContent:
        this.homeCollection.toJSON()[0].observations.htmlContent,
      recoveredDebrisHtmlContent:
        this.homeCollection.toJSON()[0].recoveredDebris.htmlContent,
      workshopHtmlContent: this.homeCollection.toJSON()[0].workshop.htmlContent,
      linksCollection: this.homeCollection.toJSON()[0].links,
    })
    this.$el.html(markup)
    this.renderBlogItems()

    this.$el.find('.dropdown-toggle').dropdown()

    var tags2D = _.map(this.homeCollection.toJSON()[0].blogs, 'tags')
    var tags1D = []
    for (var i = 0; i < tags2D.length; i++) {
      tags1D = tags1D.concat(tags2D[i])
    }

    // constructs the suggestion engine
    var tags = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: _.uniq(tags1D),
    })

    this.$el.find('#tagBlog').tagsinput({
      tagClass: 'tag label label-default',
      typeaheadjs: {
        name: 'tags',
        source: tags.ttAdapter(),
      },
    })

    this.trigger('didRender')

    return this
  },

  clickTab(e) {
    this.openTab(e.currentTarget.id, true)
  },

  /**
   * Render home with tab selected, and update the URL.
   * @param id - Selected tab id.
   * @param push - If true, URL is pushed to history. Should be false for the
   * 					     initial render.
   */
  openTab(id, push) {
    const tabs = this.el.querySelectorAll('.tab_display')
    tabs.forEach((tab) => (tab.style.display = 'none'))
    const elId = `#home_${id}`
    console.log('display block for', elId)
    const tabEl = this.$el.find(`#${id}`)
    this.$el.find('#blog').show()
    this.$el.find('#article-tab').hide()
    tabEl.siblings().removeClass('active')
    tabEl.addClass('active')
    this.el.querySelector(elId).style.display = 'block'
    this.router.navigate(`home/${id}`, { replace: !push })
  },

  openLink(e) {
    window.open(
      this.homeCollection.toJSON()[0].links[e.currentTarget.id.split('-')[1]]
        .link,
      '_blank',
    )
  },

  renderBlogItems() {
    if (this.el.querySelector('.blogs')) {
      this.$el.find('.blogs').remove()
    }

    this.el
      .querySelector('#home_blog')
      .appendChild(this.homeBlogCollectionView.render(this.blogsToRender).el)
  },

  renderCarouselItem(e) {
    // Get type of tab to be opened
    var type =
      this.homeCollection.toJSON()[0].carousel[e.currentTarget.id.split('-')[1]]
        .type

    // open tab
    this.openTab(type)
    $(`ul.nav a[href="home_${type}"]`).tab('show')

    // If blog tab, render the sub-item as well
    if (type === 'blog') {
      var typeId =
        this.homeCollection.toJSON()[0].carousel[
          e.currentTarget.id.split('-')[1]
        ].typeId
      this.homeBlogCollectionView.renderItem({
        currentTarget: {
          id: typeId,
        },
      })
    }
  },

  filterBlogItems(e) {
    var selectedTags = this.$el.find('#tagBlog').tagsinput('items')

    this.blogsToRender = _.filter(
      this.homeCollection.toJSON()[0].blogs,
      function (o) {
        for (var i = 0; i < selectedTags.length; i++) {
          if (_.includes(o.tags, selectedTags[i].toLowerCase()) === false) {
            return false
          }
        }
        return true
      },
    )

    this.renderBlogItems()

    // open blog tab
    this.openTab('blog')
    $('ul.nav a[href="home_blog"]').tab('show')
  },
})
