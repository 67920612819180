import Backbone from 'backbone'
import $ from 'jquery'
import messageBus from '../models/MessageBus'
import config from '../../../common/config/client'
import localUser from '../models/WhoAmI'
import userSettings from '../models/UserSettings'

export const SettingsModalView = Backbone.View.extend({
  template: require('../../templates/SettingsModal.hbs'),
  localUser,
  userSettings,

  initialize(options) {
    this.listenTo(this.userSettings, 'sync reset', this.render)
  },

  events: {
    'click button.saveSettings': 'saveSettings',
  },

  render() {
    const markup = this.template({
      user: this.localUser.toJSON(),
      settings: this.userSettings.toJSON(),
      accountSettingsURL: config.accountSettingsURL,
    })

    this.$el.html(markup)

    return this
  },

  saveSettings(e) {
    let newFreqVal = 'Never'
    if (this.el.querySelector('#settingsFrequencyEmailDaily').checked) {
      newFreqVal = 'Daily'
    } else if (this.el.querySelector('#settingsFrequencyEmailWeekly').checked) {
      newFreqVal = 'Weekly'
    } else {
      newFreqVal = 'Never'
    }
    if (Number(newFreqVal) !== this.userSettings.toJSON().frequencyEmail) {
      this.userSettings.set({
        frequencyEmail: newFreqVal,
      })
      this.userSettings.idAttribute = null
      this.userSettings
        .save()
        .error(function (model, error) {
          messageBus.trigger('growl', {
            type: 'danger',
            content: 'Error -' + error.responseText,
          })
        })
        .success(function (model, error) {
          messageBus.trigger('growl', {
            type: 'success',
            content: 'Settings changed!',
          })
        })
    }
    // force backdrop (grey screen) to disappear
    $('#settings-modal').modal('hide')
    $('body').removeClass('modal-open')
    $('.modal-backdrop').remove()
  },
})
