import Backbone from 'backbone'
import $ from 'jquery'

import messageBus from '../models/MessageBus'

export default Backbone.View.extend({
  notificationTemplate: require('../../templates/GrowlNotificationView.hbs'),

  messageBus,

  types: ['success', 'info', 'warning', 'danger'],

  delays: {
    success: 4000,
    info: 4000,
    warning: 0,
    danger: 0,
  },

  initialize() {
    this.listenTo(this.messageBus, 'growl', this.renderNotification)
  },

  render() {
    this.el.style.width = '270px'
    this.el.style.right = '20px'
    this.el.style.top = '100px'
    this.el.style.position = 'absolute'
    return this
  },

  renderNotification(msg) {
    var div = document.createElement('div')
    div.innerHTML = this.notificationTemplate(msg)
    div = div.childNodes[0]
    this.el.appendChild(div)
    $(div).fadeIn()

    var delay = this.delays[msg.type]
    if (delay > 0) {
      $(div)
        .delay(delay)
        .fadeOut(function () {
          $(div).alert('close')
        })
    }
  },
})
