var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<input class=\"range\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":33}}}) : helper)))
    + "\" type=\"text\"\n			data-slider-id=\"sliderBarColor\"\n			data-slider-min="
    + alias4(((helper = (helper = lookupProperty(helpers,"rangeMin") || (depth0 != null ? lookupProperty(depth0,"rangeMin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rangeMin","hash":{},"data":data,"loc":{"start":{"line":6,"column":19},"end":{"line":6,"column":31}}}) : helper)))
    + "\n			data-slider-max="
    + alias4(((helper = (helper = lookupProperty(helpers,"rangeMax") || (depth0 != null ? lookupProperty(depth0,"rangeMax") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rangeMax","hash":{},"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":31}}}) : helper)))
    + "\n			data-slider-step="
    + alias4(((helper = (helper = lookupProperty(helpers,"slider_step") || (depth0 != null ? lookupProperty(depth0,"slider_step") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slider_step","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":35}}}) : helper)))
    + "\n			data-slider-value=\"["
    + alias4(((helper = (helper = lookupProperty(helpers,"valueMin") || (depth0 != null ? lookupProperty(depth0,"valueMin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"valueMin","hash":{},"data":data,"loc":{"start":{"line":9,"column":23},"end":{"line":9,"column":35}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"valueMax") || (depth0 != null ? lookupProperty(depth0,"valueMax") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"valueMax","hash":{},"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":49}}}) : helper)))
    + "]\"\n			data-slider-range=\"true\"\n			data-slider-tooltip=\"show\" />\n		<br>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "		<span class=\"fa fa-spinner fa-pulse fa-fw margin-bottom\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"filter-input range\">\n	<label for=\"field-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":19},"end":{"line":2,"column":25}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":38}}}) : helper))) != null ? stack1 : "")
    + "</label><br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"rangeFilterShow") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":15,"column":8}}})) != null ? stack1 : "")
    + "	<br><br>\n</div>\n";
},"useData":true});