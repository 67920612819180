/*
	Model/collection to send and receive comments between user and admin
*/
import Backbone from 'backbone'

const AskAdminMessagesModel = Backbone.Model.extend({
  urlRoot: '/api/protected/askAdmin',
  idAttribute: 'messageId',
})

const AskAdminMessagesCollection = Backbone.Collection.extend({
  model: AskAdminMessagesModel,
  url() {
    let url = '/api/protected/askAdmin'
    const query = new URLSearchParams()
    if (this.cosparId) {
      query.set('cosparId', this.cosparId)
    }
    if (this.userId) {
      query.set('userId', this.userId)
    }
    url += `?${query.toString()}`
    return url
  },

  initialize(options) {
    this.cosparId = options.cosparId
    this.userId = options.userId
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

export default AskAdminMessagesCollection
