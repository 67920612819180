/*
	View displays the item selected from the files menu
*/

import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import svgConverter from '../helpers/svg2dataUri'
import { DetailPlotView } from './DetailPlotView'
import { DetailHistoryView } from './DetailHistoryView'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

export const DetailFilesItemView = Backbone.View.extend({
  template: require('../../templates/DetailFilesItemView.hbs'),

  subTemplate: require('../../templates/DetailFilesEachItemView.hbs'),

  divDisplayedItems: {},

  isSingleView: true,

  elementAtIndexZero: null,

  initialize(options) {
    console.log('Initialized Details Files Item View')

    this.detailFilesItemModel = options.detailFilesItemModel

    this.detailFilesItemModel.set('view', 'single')

    this.divHeight = options.divHeight

    this.cosparId = options.cosparId

    this.eventReentryDateHistory = options.eventReentryDateHistory

    this.detailEventCollection = options.detailEventCollection

    // if new files to be displayed or chnage in view (single to multiple or vice-versa)
    this.listenTo(
      this.detailFilesItemModel,
      'change:fileIds change:view',
      function () {
        this.isSingleView =
          this.detailFilesItemModel.get('view') === 'single' ? true : false
        if (this.isSingleView) {
          if (this.detailFilesItemModel.has('fileIds')) {
            this.render(true)
          } else {
            this.render(false)
          }
        } else {
          if (this.detailFilesItemModel.has('fileIds')) {
            for (
              var i = 0;
              i < this.detailFilesItemModel.get('fileIds').length;
              i++
            ) {
              this.populateDisplayItems(
                this.detailFilesItemModel.get('fileIds')[i],
                i,
              )
            }
            this.render(true)
          } else {
            this.render(false)
          }
        }
      },
    )
  },

  events: {
    'click .changeDate': 'changeDate',
    'click .itemChange': 'itemChange',
    'click .itemRemove': 'itemRemove',
    'click .viewType': 'switchView',
    'click .fullScreen': 'fullScreen',
  },

  // make sub div to display one item
  makeSubDiv(id) {
    var fileIds = this.detailFilesItemModel.get('fileIds')
    var isFirstItem = fileIds[id].indexItemDisplayed === 0 ? true : false
    var isLastItem =
      fileIds[id].indexItemDisplayed === fileIds[id].itemList.length - 1
        ? true
        : false

    var item
    var itemName

    if (fileIds[id].itemDisplayed.extension === 'plot') {
      let itemHeight =
        this.isSingleView || Object.keys(fileIds).length <= 2
          ? this.divHeight - 180
          : this.divHeight / 2 - 180
      var itemWidth = this.isSingleView
        ? this.$el.width() - 30
        : this.$el.width() / 2 - 60

      this.detailPlotView = new DetailPlotView({
        collection: _.sortBy(
          this.detailEventCollection.toJSON(),
          reentryColumns.CREATION_DATE,
        ),
        height: itemHeight,
        width: itemWidth,
      })

      switch (fileIds[id].itemDisplayed.name) {
        case 'Reentry_Date_History':
          this.historyPlot = this.detailPlotView.renderReentryDateHistory()
          break
        case 'Apogee_Perigee_History':
          this.historyPlot = this.detailPlotView.renderApogeePerigeeHistory()
          break
      }

      item = svgConverter.svgAsPng(this.historyPlot.el.querySelector('svg'))
      itemName = fileIds[id].itemDisplayed.name + '.png'
    } else if (fileIds[id].itemDisplayed.extension === 'table') {
      let itemHeight =
        this.isSingleView || Object.keys(fileIds).length <= 2
          ? this.divHeight - 180
          : this.divHeight / 2 - 180

      this.detailHistoryView = new DetailHistoryView({
        collection: _.orderBy(
          this.detailEventCollection.toJSON(),
          reentryColumns.CREATION_DATE,
          'desc',
        ),
        height: itemHeight,
      })

      this.detailHistory = this.detailHistoryView.render()
    } else {
      item =
        '../../../files/' + this.cosparId + '/' + fileIds[id].itemDisplayed.file
      itemName =
        fileIds[id].itemDisplayed.name +
        '.' +
        fileIds[id].itemDisplayed.extension
    }

    var displayItem = this.subTemplate({
      item,
      itemName,
      downloadIconDisplay:
        fileIds[id].itemDisplayed.extension === 'table' ? false : true,
      itemIndexDisplayed: fileIds[id].indexItemDisplayed + 1,
      totalItems: fileIds[id].itemList.length,
      isFirstItem,
      isLastItem,
      index: id,
      panelHeading:
        fileIds[id].itemDisplayed.name +
        ' (' +
        fileIds[id].itemDisplayed.type +
        ')',
      isSingleView: this.isSingleView,
      panelBodyHeight:
        this.isSingleView || Object.keys(fileIds).length <= 2
          ? this.divHeight - 150 + 'px'
          : this.divHeight / 2 - 150 + 'px',
      includeDates: fileIds[id].includeDates,
      displayDate: moment
        .utc(fileIds[id].itemDisplayed.date, 'YYYYMMDDHHmmss')
        .format('DD-MMM-YYYY HH:mm:ss'),
      datesDropDownDisabled:
        fileIds[id].includeDates.length <= 1 ? true : false,
    })

    var container = document.createDocumentFragment()
    var div = document.createElement('div')
    div.innerHTML = displayItem
    container.appendChild(div.childNodes[0])

    this.divDisplayedItems[id] = container
  },

  // make content to be displayed in the item
  makeDivDetailItem(id) {
    var fileIds = this.detailFilesItemModel.get('fileIds')
    var detailItem = document.createDocumentFragment()

    let itemHeight =
      this.isSingleView || Object.keys(fileIds).length <= 2
        ? this.divHeight - 180
        : this.divHeight / 2 - 180

    switch (fileIds[id].itemDisplayed.extension) {
      case 'plot':
        detailItem.appendChild(this.historyPlot.el)
        break
      case 'table':
        detailItem.appendChild(this.detailHistory.el)
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
        var img = document.createElement('img')
        img.src =
          '../../../files/' +
          this.cosparId +
          '/' +
          fileIds[id].itemDisplayed.file
        img.style.maxHeight = itemHeight + 'px'
        detailItem.appendChild(img)
        break
      case 'xml':
        const el = document.createElement('pre')
        const filename = fileIds[id].itemDisplayed.file
        fetch(`../../../files/${this.cosparId}/${filename}`)
          .then((response) => response.text())
          .then((text) => {
            el.innerText = text
          })
          .catch(() => {
            el.innerText = 'Error fetching file contents.'
          })
        detailItem.appendChild(el)
        el.style.height = `${itemHeight}px`
        break
      default:
        var embed = document.createElement('embed')
        embed.src =
          '../../../files/' +
          this.cosparId +
          '/' +
          fileIds[id].itemDisplayed.file
        embed.style.width = '100%'
        embed.style.height = itemHeight + 'px'
        detailItem.appendChild(embed)
        break
    }

    return detailItem
  },

  // render item
  renderItem(id, self) {
    self.makeSubDiv(id)
    self.el.querySelector('#item-' + id).appendChild(self.divDisplayedItems[id])
    self.el
      .querySelector('#detailItem-' + id)
      .appendChild(self.makeDivDetailItem(id))
  },

  // render full item view (can contain multiple items in multiple view)
  render(filesLoaded) {
    if (filesLoaded) {
      // if files collection is already fetched

      var fileIds = this.detailFilesItemModel.get('fileIds')

      // For single view
      var displayedItemsIndices = ['0']
      var size = '12'

      // For multiple view
      if (!this.isSingleView) {
        displayedItemsIndices = Object.getOwnPropertyNames(fileIds)
        size = '6'
      }

      // For fullscreen single view
      if (this.detailFilesItemModel.has('fullScreen')) {
        displayedItemsIndices = [this.detailFilesItemModel.get('fullScreen')]
      }

      let markup = this.template({
        filesLoaded,
        size,
        displayItems: displayedItemsIndices,
        isSingleView: this.isSingleView,
        divHeight: this.divHeight + 'px',
      })

      this.$el.html(markup)

      if (this.detailFilesItemModel.has('fullScreen')) {
        this.renderItem(this.detailFilesItemModel.get('fullScreen'), this)
      } else if (this.isSingleView) {
        this.renderItem(0, this)
      } else {
        var self = this
        _.keys(fileIds).forEach(function (key) {
          self.renderItem(key, self)
        })
      }
    } else {
      // if files collection not fetched yet, display blank view
      let markup = this.template({ filesLoaded })
      this.$el.html(markup)
      if (this.isSingleView) {
        this.$el.find('#single').addClass('active')
        this.$el.find('#multiple').removeClass('active')
      } else {
        this.$el.find('#multiple').addClass('active')
        this.$el.find('#single').removeClass('active')
      }
    }

    return this
  },

  changeDate(e) {
    console.log('Event Fired', e)
    var elementId = e.currentTarget.id.split('|')
    var eDate = elementId[0]
    var id = elementId[1]
    var fileIds = this.detailFilesItemModel.get('fileIds')
    console.log('Event Date', eDate)
    if (
      eDate !==
      moment
        .utc(fileIds[id].itemDisplayed.date, 'YYYYMMDDHHmmss')
        .format('DD-MMM-YYYY HH:mm:ss')
    ) {
      fileIds[id].itemDisplayed = _.head(
        _.filter(fileIds[id].itemList, {
          date: moment
            .utc(eDate, 'DD-MMM-YYYY HH:mm:ss')
            .format('YYYYMMDDHHmmss'),
        }),
      )
      fileIds[id].indexItemDisplayed = fileIds[id].itemList.indexOf(
        fileIds[id].itemDisplayed,
      )
      this.detailFilesItemModel.set('fileIds', fileIds)
      this.detailFilesItemModel.trigger('change:fileIds')
    }
  },

  itemChange(e) {
    console.log('Item Event Fired', e)
    var id = e.currentTarget.id.split('-')
    var fileIds = this.detailFilesItemModel.get('fileIds')
    switch (id[0]) {
      case 'firstImg':
        fileIds[id[1]].indexItemDisplayed = 0
        break
      case 'prevImg':
        fileIds[id[1]].indexItemDisplayed =
          fileIds[id[1]].indexItemDisplayed - 1
        break
      case 'nextImg':
        fileIds[id[1]].indexItemDisplayed =
          fileIds[id[1]].indexItemDisplayed + 1
        break
      case 'lastImg':
        fileIds[id[1]].indexItemDisplayed = fileIds[id[1]].itemList.length - 1
        break
    }

    fileIds[id[1]].itemDisplayed =
      fileIds[id[1]].itemList[fileIds[id[1]].indexItemDisplayed]
    this.detailFilesItemModel.set('fileIds', fileIds)
    this.detailFilesItemModel.trigger('change:fileIds')
  },

  itemRemove(e) {
    console.log('Item Remove', e)
    var id = e.currentTarget.id.split('-')
    var fileIds = this.detailFilesItemModel.get('fileIds')
    delete fileIds[id[1]]
    this.detailFilesItemModel.set('fileIds', fileIds)
    this.detailFilesItemModel.trigger('change:fileIds')
  },

  switchView(e) {
    console.log('View Id', e)
    this.detailFilesItemModel.unset('fullScreen')
    this.detailFilesItemModel.set('view', e.currentTarget.id)
  },

  fullScreen(e) {
    console.log('View Id', e)
    var id = e.currentTarget.id.split('-')
    this.detailFilesItemModel.set('fullScreen', id[1])
    this.detailFilesItemModel.set('view', 'single')
  },
})
