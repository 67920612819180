var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDataAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":184,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"col-lg-6\">\n				<div class=\"row\">\n					<div class=\"panel panel-info\">\n						<div class=\"panel-heading selectUserInfo\">\n							<div class=\"panel-title\">\n								<span class=\"glyphicon glyphicon-user\" />\n								User Settings\n							</div>\n						</div>\n            <div class=\"scroll-y-auto list-group\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeightUserPanelBody") || (depth0 != null ? lookupProperty(depth0,"divHeightUserPanelBody") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeightUserPanelBody","hash":{},"data":data,"loc":{"start":{"line":13,"column":64},"end":{"line":13,"column":90}}}) : helper)))
    + ";\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"userSettings") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":54,"column":23}}})) != null ? stack1 : "")
    + "							</div>\n						<div class=\"panel-footer\">\n							<div class=\"pull-right\" role=\"group\">\n								<button class=\"btn btn-primary saveUserSettings\" style=\"padding-top: 8px; padding-bottom: 8px\" title=\"Save\">\n									<span class=\"glyphicon glyphicon-floppy-disk\"/>\n									Save\n								</button>\n							</div>\n              <div class=\"clearfix\"></div>\n						</div>\n					</div>\n					<div class=\"selectionMessageBox\" />\n				</div>\n			</div>\n			<div class=\"col-lg-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEventInfoDisplay") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":4},"end":{"line":182,"column":11}}})) != null ? stack1 : "")
    + "			</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"isUserSettingsGeneric") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":53,"column":23}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Frequency of Email on Followed Events\n                      </small>\n                      <div class=\"input-group\">\n                        <input type=\"radio\" name=\"frequencyEmail\" value=\"Daily\" id=\"frequencyEmailDaily\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),(depths[1] != null ? lookupProperty(depths[1],"daily") : depths[1]),{"name":"ifCond","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":105},"end":{"line":22,"column":160}}})) != null ? stack1 : "")
    + "><strong> Daily </strong></input>\n                        <input type=\"radio\" name=\"frequencyEmail\" value=\"Weekly\" id=\"frequencyEmailWeekly\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),(depths[1] != null ? lookupProperty(depths[1],"weekly") : depths[1]),{"name":"ifCond","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":107},"end":{"line":23,"column":163}}})) != null ? stack1 : "")
    + "><strong> Weekly </strong></input>\n                        <input type=\"radio\" name=\"frequencyEmail\" value=\"Never\" id=\"frequencyEmailNever\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),(depths[1] != null ? lookupProperty(depths[1],"never") : depths[1]),{"name":"ifCond","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":105},"end":{"line":24,"column":160}}})) != null ? stack1 : "")
    + "><strong> Never </strong></input>\n                      </div>\n                    </div>\n                  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isPrivileged") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":18},"end":{"line":41,"column":25}}})) != null ? stack1 : "")
    + "                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Event Followed\n                      </small>\n                      <div class=\"input-group\">\n                        <input type=\"radio\" name=\"eventFollowed\" value=\"True\" id=\"eventFollowedTrue\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFollowed") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":101},"end":{"line":48,"column":135}}})) != null ? stack1 : "")
    + "><strong> True </strong></input>\n                        <input type=\"radio\" name=\"eventFollowed\" value=\"False\" id=\"eventFollowedFalse\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFollowed") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":103},"end":{"line":49,"column":145}}})) != null ? stack1 : "")
    + "><strong> False </strong></input>\n                      </div>\n                    </div>\n                  </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"list-group-item\">\n                      <div class=\"row\" style=\"padding-left:20px\">\n                        <small class=\"text-muted\">\n                          Access Type\n                        </small>\n                        <div class=\"input-group\">\n                          <input type=\"radio\" name=\"accessType\" value=\"Restricted\" id=\"accessTypeRestricted\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRestricted") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":109},"end":{"line":36,"column":145}}})) != null ? stack1 : "")
    + "><strong> Restricted </strong></input>\n                          <input type=\"radio\" name=\"accessType\" value=\"Common\" id=\"accessTypeCommon\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRestricted") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":101},"end":{"line":37,"column":145}}})) != null ? stack1 : "")
    + "><strong> Common </strong></input>\n                        </div>\n                      </div>\n                    </div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"panel panel-info\">\n						<div class=\"panel-heading selectEventInfoHeading\">\n							<div class=\"panel-title\">\n								<span class=\"glyphicon glyphicon-asterisk\" />\n								Event Information\n							</div>\n						</div>\n            <div class=\"scroll-y-auto list-group\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeightEventPanelBody") || (depth0 != null ? lookupProperty(depth0,"divHeightEventPanelBody") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeightEventPanelBody","hash":{},"data":data,"loc":{"start":{"line":78,"column":64},"end":{"line":78,"column":91}}}) : helper)))
    + ";\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"reentryColumns") : depth0),{"name":"with","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":14},"end":{"line":170,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n						<div class=\"panel-footer\">\n							<div class=\"pull-right\" role=\"group\">\n								<button class=\"btn btn-primary eventDetails\" style=\"padding-top: 8px; padding-bottom: 8px\" title=\"View Event Details\">\n									<span class=\"glyphicon glyphicon-new-window\"/>\n									Event Details\n								</button>\n							</div>\n              <div class=\"clearfix\"></div>\n						</div>\n					</div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"eventInfo") : depths[1]),{"name":"with","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":16},"end":{"line":169,"column":25}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        COSPAR ID\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":86,"column":33},"end":{"line":86,"column":61}}}))
    + "</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        SATNO\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"SATNO") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":94,"column":33},"end":{"line":94,"column":57}}}))
    + "</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Name\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":102,"column":33},"end":{"line":102,"column":56}}}))
    + "</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Uncertainty\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"UNCERTAINTY") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":110,"column":33},"end":{"line":110,"column":63}}}))
    + " day(s)</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Mass\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"MASS") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":118,"column":33},"end":{"line":118,"column":56}}}))
    + " kg</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Class\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"CLASS") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":126,"column":33},"end":{"line":126,"column":57}}}))
    + "</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Inclination\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"INCLINATION") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":134,"column":33},"end":{"line":134,"column":63}}}))
    + " deg</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Perigee\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"PERIGEE") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":142,"column":33},"end":{"line":142,"column":59}}}))
    + " km</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Apogee\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"APOGEE") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":150,"column":33},"end":{"line":150,"column":58}}}))
    + " km</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Tool\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"TOOL") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":158,"column":33},"end":{"line":158,"column":56}}}))
    + "</strong></p>\n                    </div>\n                  </div>\n                  <div class=\"list-group-item\">\n                    <div class=\"row\" style=\"padding-left:20px\">\n                      <small class=\"text-muted\">\n                        Country\n                      </small>\n                      <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"COUNTRY") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":166,"column":33},"end":{"line":166,"column":59}}}))
    + "</strong></p>\n                    </div>\n                  </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "		<center><span class=\"fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom\" style=\"margin:100px auto auto\"></span></center>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"selectItem\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDataReady") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":187,"column":8}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});