/*
	Admin View - View to display user settings, ask admin and event info for a given event and user
*/

import Backbone from 'backbone'

import { AskAdminView } from './AskAdminView'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

export const UserEventItemView = Backbone.View.extend({
  template: require('../../templates/UserEventItemView.hbs'),

  divHeight: 0,

  askAdminCollection: null,

  initialize(options) {
    console.log('Initialized User Event View')

    this.router = options.router

    this.cosparId = options.cosparId
    this.eventInfo = options.eventInfo
    this.userInfo = options.userInfo
    this.userSettings = options.userSettings
  },

  events: {
    'click button.eventDetails': 'eventDetails',
    'click button.saveUserSettings': 'saveUserSettings',
  },

  renderAskAdmin() {
    if (this.el.querySelector('.askAdminWindow-admin')) {
      this.$el.find('.askAdminWindow-admin').remove()
    }
    this.el
      .querySelector('.selectionMessageBox')
      .appendChild(this.askAdminView.render(true).el)
    return this
  },

  render(divHeight, isDataReady) {
    this.divHeight = divHeight
    const isDataAvailable = !(
      typeof this.eventInfo === 'undefined' ||
      typeof this.userInfo === 'undefined'
    )
    const isEventInfoDisplay = this.eventInfo.cosparId !== 'Generic'

    const markup = this.template({
      divHeightEventPanelBody: this.divHeight - 110 + 'px',
      divHeightUserPanelBody: this.divHeight - 510 + 'px',
      eventInfo: this.eventInfo,
      userInfo: this.userInfo,
      isDataAvailable,
      isEventInfoDisplay,
      reentryColumns,
      userSettings: this.userSettings.toJSON()[0],
      isUserSettingsGeneric: this.cosparId === 'Generic',
      daily: 'Daily',
      weekly: 'Weekly',
      never: 'Never',
      isPrivileged: this.userSettings.toJSON()[0].isPrivileged,
      isDataReady,
    })
    this.$el.html(markup)

    const cosparId = this.cosparId === 'Generic' ? null : this.cosparId

    if (isDataReady && isDataAvailable) {
      this.askAdminView = new AskAdminView({
        cosparId,
        emailUser: this.userInfo.email,
        isAdminUser: true,
        view: 'admin',
      })
      this.renderAskAdmin()
    }

    return this
  },

  eventDetails(e) {
    this.router.navigate('events/' + this.cosparId, { trigger: true })
  },

  saveUserSettings(e) {
    const isUserSettingsGeneric = this.cosparId === 'Generic'

    const saveObj = {}

    if (isUserSettingsGeneric) {
      // Generic settings changed
      let newFrequencyEmail = 'Never'
      if (this.el.querySelector('#frequencyEmailDaily').checked) {
        newFrequencyEmail = 'Daily'
      } else if (this.el.querySelector('#frequencyEmailWeekly').checked) {
        newFrequencyEmail = 'Weekly'
      } else {
        newFrequencyEmail = 'Never'
      }

      saveObj.frequencyEmail = newFrequencyEmail
    } else {
      const accessTypeRestricted = this.el.querySelector(
        '#accessTypeRestricted',
      )
      if (accessTypeRestricted) {
        // Event specific changes
        saveObj.isRestricted = !!accessTypeRestricted.checked
      }

      saveObj.isFollowed = !!this.el.querySelector('#eventFollowedTrue').checked
    }

    const collection = this.userSettings
    this.userSettingsModel = new collection.model()

    this.userSettingsModel.set({
      saveObj,
      cosparId: this.cosparId,
      userId: this.userInfo.email,
    })
    this.userSettingsModel.idAttribute = null
    this.userSettingsModel.save()
  },
})
