/*
	Plot reentry date history view plot
*/

import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import d3 from 'd3'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

export const DetailPlotView = Backbone.View.extend({
  template: require('../../templates/DetailPlotView.hbs'),

  divDisplayedItems: {},

  isSingleView: true,

  elementAtIndexZero: null,

  initialize(options) {
    console.log('Initialized Details Plot View')
    this.height = options.height
    this.width = options.width
    this.collection = options.collection
  },

  renderReentryDateHistory() {
    var markup = this.template({})
    this.$el.html(markup)

    this.dataset = []

    const collection = _.sortBy(this.collection, [reentryColumns.ORBIT_EPOCH])

    var data = (function () {
      var rv = []
      var oneDayInMs = 86400000
      for (var i = 0; i < collection.length; ++i) {
        rv.push({
          x: moment.utc(collection[i][reentryColumns.ORBIT_EPOCH]).toDate(),
          y: moment.utc(collection[i][reentryColumns.REENTRY_DATE]).toDate(),
          uncertainty_low: moment
            .utc(
              collection[i][reentryColumns.REENTRY_DATE] -
                collection[i][reentryColumns.UNCERTAINTY] * oneDayInMs,
            )
            .toDate(),
          uncertainty_high: moment
            .utc(
              collection[i][reentryColumns.REENTRY_DATE] +
                collection[i][reentryColumns.UNCERTAINTY] * oneDayInMs,
            )
            .toDate(),
          tool: collection[i][reentryColumns.TOOL],
        })
      }
      return rv
    })()

    this.dataset.push(data)

    var tickFormat = d3.time.format('%d %b')

    var margin = { top: 20, right: 50, bottom: 50, left: 70 },
      width = this.width - margin.left - margin.right,
      height = this.height - margin.top - margin.bottom

    var x = d3.time.scale.utc().range([0, width])

    var y = d3.time.scale.utc().range([height, 0])

    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient('bottom')
      // TODO Specify number of ticks
      //.ticks(((this.dataset[0].length + 2) < width/100) ? (this.dataset[0].length + 2) : (width/100))
      //.ticks(d3.time.day.utc, this.dataset[0].length < 5 ? 5 : 10)
      .tickFormat(tickFormat)

    var yAxisLeft = d3.svg
      .axis()
      .scale(y)
      .orient('left')
      .ticks(
        this.dataset[0].length < height / 30
          ? this.dataset[0].length
          : height / 30,
      )
      .tickFormat(tickFormat)

    var yAxisRight = d3.svg
      .axis()
      .scale(y)
      .orient('right')
      .ticks(
        this.dataset[0].length < height / 30
          ? this.dataset[0].length
          : height / 30,
      )
      .tickFormat(tickFormat)

    var line = d3.svg
      .line()
      .x(function (d) {
        return x(d.x)
      })
      .y(function (d) {
        return y(d.y)
      })

    var area = d3.svg
      .area()
      .x(function (d) {
        return x(d.x)
      })
      .y0(function (d) {
        return y(d.uncertainty_high)
      })
      .y1(function (d) {
        return y(d.uncertainty_low)
      })

    this.svg = d3
      .select(this.el.querySelector('.chart-area'))
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    var yMin = moment
      .utc(
        Number(
          d3.min(this.dataset[0], function (d) {
            return d.uncertainty_low
          }),
        ) - 86400000,
      )
      .toDate()
    var yMax = moment
      .utc(
        Number(
          d3.max(this.dataset[0], function (d) {
            return d.uncertainty_high
          }),
        ) + 86400000,
      )
      .toDate()
    var xMin = moment
      .utc(
        Number(
          d3.min(this.dataset[0], function (d) {
            return d.x
          }),
        ) - 86400000,
      )
      .toDate()
    var xMax = moment
      .utc(
        Number(
          d3.max(this.dataset[0], function (d) {
            return d.x
          }),
        ) + 86400000,
      )
      .toDate()
    x.domain([xMin, xMax])
    y.domain([yMin, yMax])

    this.svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .append('text')
      .attr('y', margin.bottom - 20)
      .attr('x', width / 2)
      .attr('dy', '.71em')
      .style('text-anchor', 'middle')
      .text('Orbit Epoch')

    this.svg
      .append('g')
      .attr('class', 'y axis')
      .call(yAxisLeft)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - height / 2)
      .attr('dy', '.71em')
      .style('text-anchor', 'middle')
      .text('Predicted Reentry Epoch')

    this.svg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(' + width + ',0)')
      .call(yAxisRight)

    this.svg
      .append('path')
      .datum(this.dataset[0])
      .attr('class', 'area')
      .attr('d', area)

    var pathContainers = this.svg.selectAll('g.line').data(this.dataset)

    pathContainers
      .enter()
      .append('g')
      .attr('class', 'line')
      .attr('style', 'stroke:steelblue')

    pathContainers
      .selectAll('path')
      .data(function (d) {
        return [d]
      }) // continues the data from the pathContainer
      .enter()
      .append('path')
      .attr(
        'd',
        d3.svg
          .line()
          .x(function (d) {
            return x(d.x)
          })
          .y(function (d) {
            return y(d.y)
          }),
      )

    // add uncertainity line
    pathContainers
      .selectAll('uncertainityLine')
      .data(function (d) {
        return d
      })
      .enter()
      .append('line')
      .attr('class', 'uncertainityLine')
      .attr('x1', function (d) {
        return x(d.x)
      })
      .attr('y1', function (d) {
        return y(d.uncertainty_low)
      })
      .attr('x2', function (d) {
        return x(d.x)
      })
      .attr('y2', function (d) {
        return y(d.uncertainty_high)
      })
      .style('fill', '#EC916A')
      .style('stroke', '#EC916A')
      .style('stroke-width', 2)

    // add symbols
    var tools = _.uniq(_.map(this.dataset[0], 'tool'))
    var symbols = [
      'circle',
      'diamond',
      'square',
      'triangle-up',
      'triangle-down',
      'cross',
    ]
    pathContainers
      .selectAll('symbol')
      .data(function (d) {
        return d
      })
      .enter()
      .append('path')
      .attr('class', 'symbol')
      .attr('fill', 'steelblue')
      .attr('stroke', 'steelblue')
      .attr(
        'd',
        d3.svg
          .symbol()
          .size(25)
          .type(function (d) {
            return symbols[tools.indexOf(d.tool) % symbols.length]
          }),
      )
      .attr('transform', function (d) {
        return 'translate(' + x(d.x) + ',' + y(d.y) + ')'
      })
      .append('svg:title')
      .text(function (d) {
        return (
          'Predicted Reentry Epoch: ' +
          d.y +
          '\nUncertainty Low: ' +
          d.uncertainty_low +
          '\nUncertainty High: ' +
          d.uncertainty_high +
          '\nTool: ' +
          d.tool
        )
      })

    return this
  },

  renderApogeePerigeeHistory() {
    var markup = this.template({})
    this.$el.html(markup)

    this.dataset = []

    var collection = this.collection

    var dataApogee = (function () {
      var dataTmp = []
      for (var i = 0; i < collection.length; ++i) {
        dataTmp.push({
          x: moment.utc(collection[i][reentryColumns.ORBIT_EPOCH]).toDate(),
          height: +collection[i][reentryColumns.APOGEE],
          type: 'Apogee',
        })
      }
      return dataTmp
    })()

    var dataPerigee = (function () {
      var dataTmp = []
      for (var i = 0; i < collection.length; ++i) {
        dataTmp.push({
          x: moment.utc(collection[i][reentryColumns.ORBIT_EPOCH]).toDate(),
          height: +collection[i][reentryColumns.PERIGEE],
          type: 'Perigee',
        })
      }
      return dataTmp
    })()

    this.dataset.push({
      type: 'Apogee',
      values: dataApogee,
    })

    this.dataset.push({
      type: 'Perigee',
      values: dataPerigee,
    })

    var tickFormat = d3.time.format('%d %b')

    var margin = { top: 20, right: 20, bottom: 50, left: 70 },
      width = this.width - margin.left - margin.right,
      height = this.height - margin.top - margin.bottom

    var x = d3.time.scale.utc().range([0, width])

    var y = d3.scale.linear().range([height, 0])

    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient('bottom')
      .innerTickSize(-height)
      .tickPadding(10)
      //.ticks((this.dataset[0].values.length < width/100) ? this.dataset[0].values.length : (width/100))
      .tickFormat(tickFormat)

    var yAxis = d3.svg
      .axis()
      .scale(y)
      .orient('left')
      .innerTickSize(-width)
      .tickPadding(10)
    //.ticks((this.dataset[0].values.length < height/30) ? this.dataset[0].values.length : (height/30));

    var line = d3.svg
      .line()
      .x(function (d) {
        return x(d.x)
      })
      .y(function (d) {
        return y(d.height)
      })

    var colors = {
      Apogee: '#4682B4',
      Perigee: '#EC916A',
    }

    var symbols = {
      Apogee: 'triangle-up',
      Perigee: 'triangle-down',
    }

    var symbol = d3.svg.symbol().size(25).type('circle')

    this.svg = d3
      .select(this.el.querySelector('.chart-area'))
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    x.domain([
      moment
        .utc(
          d3
            .min(this.dataset, function (c) {
              return d3.min(c.values, function (v) {
                return v.x
              })
            })
            .getTime() - 86400000,
        )
        .toDate(),
      moment
        .utc(
          d3
            .max(this.dataset, function (c) {
              return d3.max(c.values, function (v) {
                return v.x
              })
            })
            .getTime() + 86400000,
        )
        .toDate(),
    ])

    y.domain([
      0,
      d3.max(this.dataset, function (c) {
        return d3.max(c.values, function (v) {
          return v.height
        })
      }) + 100,
    ])

    this.svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .append('text')
      .attr('y', margin.bottom - 20)
      .attr('x', width / 2)
      .attr('dy', '.71em')
      .style('text-anchor', 'middle')
      .text('Orbit Epoch')

    this.svg
      .append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - height / 2)
      .attr('dy', '.71em')
      .style('text-anchor', 'middle')
      .text('Height (km)')

    var dataLine = this.svg
      .selectAll('.heightLine')
      .data(this.dataset)
      .enter()
      .append('g')
      .attr('class', 'heightLine')

    dataLine
      .append('path')
      .attr('class', 'line')
      .attr('d', function (d) {
        return line(d.values)
      })
      .style('stroke', function (d) {
        return colors[d.type]
      })

    var symbolValues = (function (self) {
      var values = _.map(self.dataset, 'values')
      return values[0].concat(values[1])
    })(this)

    var dataSymbol = this.svg
      .selectAll('.dataSymbol')
      .data(symbolValues)
      .enter()
      .append('g')
      .attr('class', 'dataSymbol')

    dataSymbol
      .append('path')
      .attr('class', 'symbol')
      .attr('transform', function (d) {
        return 'translate(' + x(d.x) + ',' + y(d.height) + ')'
      })
      .attr(
        'd',
        d3.svg
          .symbol()
          .size(25)
          .type(function (d) {
            return symbols[d.type]
          }),
      )
      .style('stroke', function (d) {
        return colors[d.type]
      })
      .style('fill', function (d) {
        return colors[d.type]
      })
      .append('svg:title')
      .text(function (d) {
        return d.type + ': ' + d.height + ' km'
      })

    dataLine
      .append('text')
      .datum(function (d) {
        return {
          type: d.type,
          y: d.values[d.values.length - 1].height,
          x: d.values[d.values.length - 1].x,
        }
      })
      .attr('class', 'chartText')
      .attr('transform', function (d) {
        return 'translate(' + x(d.x) + ',' + y(d.y) + ')'
      })
      .attr('y', -3)
      .attr('x', -45)
      .style('fill', function (d) {
        return colors[d.type]
      })
      .text(function (d) {
        return d.type
      })

    return this
  },
})
