import Backbone from 'backbone'

var WhoAmI = Backbone.Model.extend({
  url: '/api/protected/users/whoami',

  initialize() {
    this.fetch({
      reset: true,
    })
  },
})

var whoAmI = new WhoAmI()

// make it a singleton
export default whoAmI
