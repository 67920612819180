var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "			<nav class=\"navbar navbar-default\" role=\"navigation\" style=\"border:none\">\n				<div class=\"collapse navbar-collapse navLinksCollapse\">\n						<ul class=\"nav nav-pills navbar-nav navbar-right\">\n							<li class=\"reentry_tab active\" id=\"table\" title=\"Table\"><a data-toggle=\"tab\" href=\"#reentry_table\"><span class=\"fa fa-table\"></span></a></li>\n							<li class=\"reentry_tab\" id=\"gantt\" title=\"Gantt Chart\"><a data-toggle=\"tab\" href=\"#reentry_gantt\"><span class=\"fa fa-align-center\"></span></a></li>\n							<li class=\"reentry_tab\" id=\"live\" title=\"Live\"><a data-toggle=\"tab\" href=\"#reentry_live\"><span class=\"fa fa-globe\"></span></a></li>\n						</ul>\n				</div>\n			</nav>\n\n			<div class=\"tab-content\">\n				<div id=\"reentry_table\" class=\"tab-pane fade in active reentry_tab_display\">\n					<div class=\"reentryCollectionTable\" />\n				</div>\n				<div id=\"reentry_gantt\" class=\"tab-pane fade in reentry_tab_display\">\n					<div class=\"col-center reentryCollectionGantt\">\n					</div>\n				</div>\n				<div id=\"reentry_live\" class=\"tab-pane fade in reentry_tab_display\">\n					<div class=\"col-center reentryCollectionLive\">\n					</div>\n				</div>\n			</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "			<center><span class=\"fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom\" style=\"margin:100px auto auto\"></span></center>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"reentryCollection\">\n	<div class=\"row\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dataPopulated") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":30,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});