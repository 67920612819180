/*
	Calls new message user list (for admins) and then message view
	or directly message view for non-admins
*/
import Backbone from 'backbone'
import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'

import { AskAdminMessagesView } from './AskAdminMessagesView'
import { AskAdminUserListView } from './AskAdminUserListView'

import AskAdminUserCollection from '../models/AskAdminUserCollection'
import AskAdminMessagesCollection from '../models/AskAdminMessagesCollection'

export const AskAdminView = Backbone.View.extend({
  template: require('../../templates/AskAdmin.hbs'),

  initialize(options) {
    console.log('Initialized Ask Admin View')

    this.cosparId = options.cosparId

    this.userId = options.userId

    this.isAdminUser = options.isAdminUser

    this.view = options.view

    this.userListDisplay = this.isAdminUser && this.view !== 'admin'
  },

  events: {
    'click .usersEmailListItem': 'usersEmailListItem',
    'click button.back': 'loadUserList',
  },

  render(askAdminWindowOpened) {
    var markup

    markup = this.template({
      isAdminPage: this.view === 'admin' ? true : false,
      view: this.view,
    })
    this.$el.html(markup)

    if (this.userListDisplay) {
      // for admins - display new message user list if not admin page
      this.askAdminUserCollection = new AskAdminUserCollection({
        cosparId: this.cosparId,
      })
      this.askAdminUserListView = new AskAdminUserListView({
        cosparId: this.cosparId,
        view: this.view,
      })

      //if there are users with new message render them and add an icon to header to notify new messages
      this.listenTo(this.askAdminUserCollection, 'reset', function (e) {
        this.renderUserList()
        if (
          this.askAdminUserCollection
            .toJSON()
            .some((thread) => thread.hasUnread)
        ) {
          const icon = document.createElement('i')
          icon.className += 'fa fa-circle icon-unread newMessages-' + this.view
          this.el
            .querySelector('.askAdminPanelTitle-' + this.view)
            .appendChild(icon)
        }
      })
    } else {
      // for non-admins or admin page - load messages
      this.askAdminMessagesCollection = new AskAdminMessagesCollection({
        cosparId: this.cosparId,
        userId: this.userId,
      })
      this.askAdminMessagesView = new AskAdminMessagesView({
        view: this.view,
        cosparId: this.cosparId,
        userId: this.userId,
        isAdminUser: this.isAdminUser,
      })

      //if new message is there, add an icon to header of panel to notify it.
      this.listenTo(this.askAdminMessagesCollection, 'sync add', function () {
        this.renderMessages(false)
        var newMessages = _.filter(this.askAdminMessagesCollection.toJSON(), {
          isAdminMessage: true,
          isNewMessage: true,
        }).length
        if (newMessages > 0 && this.view !== 'admin') {
          const icon = document.createElement('i')
          icon.className += 'fa fa-circle icon-unread newMessages-' + this.view
          this.el
            .querySelector('.askAdminPanelTitle-' + this.view)
            .appendChild(icon)
        }
      })
    }

    var collapsePanel = ''
    var expandPanel = ''

    if (this.view === 'reentry') {
      collapsePanel = 'collapseReentryAskAdmin'
      expandPanel = 'expandReentryAskAdmin'
    } else if (this.view === 'detail') {
      collapsePanel = 'collapseDetailAskAdmin'
      expandPanel = 'expandDetailAskAdmin'
    }

    if (this.view !== 'admin') {
      if (askAdminWindowOpened) {
        this.addPanelButton(
          'glyphicon glyphicon-triangle-top ' + collapsePanel,
          '.askAdminPanelExpand-' + this.view,
        )
        this.$el.find('#panelBodyAskAdmin-' + this.view).collapse('show')
      } else {
        this.addPanelButton(
          'glyphicon glyphicon-triangle-right ' + expandPanel,
          '.askAdminPanelExpand-' + this.view,
        )
      }
    }

    return this
  },

  //Render user list for admins
  renderUserList() {
    this.el
      .querySelector('.askAdminDisplay-' + this.view)
      .appendChild(
        this.askAdminUserListView.render(this.askAdminUserCollection).el,
      )
  },

  //Render message view and scroll to bottom
  renderMessages(isBackButtonDisplay) {
    this.el
      .querySelector('.askAdminDisplay-' + this.view)
      .appendChild(
        this.askAdminMessagesView.render(
          this.askAdminMessagesCollection,
          isBackButtonDisplay,
        ).el,
      )
    this.el.querySelector('.askAdminChatBox-' + this.view).scrollTop =
      this.el.querySelector('.askAdminChatBox-' + this.view).scrollHeight
  },

  // Admins - If a user email is clicked
  usersEmailListItem(e) {
    console.log(e)
    this.userId =
      this.askAdminUserCollection.toJSON()[e.currentTarget.id.split('-')[2]][
        'userId'
      ]

    // get list of messages for the user
    this.askAdminMessagesCollection = new AskAdminMessagesCollection({
      cosparId: this.cosparId,
      userId: this.userId,
    })

    // Initialize message view
    this.askAdminMessagesView = new AskAdminMessagesView({
      view: this.view,
      cosparId: this.cosparId,
      userId: this.userId,
      isAdminUser: this.isAdminUser,
    })

    // Render message view once message list is fetched
    this.listenTo(this.askAdminMessagesCollection, 'sync add', function () {
      this.el.querySelector('.askAdminBody-' + this.view).remove()
      this.renderMessages(true)
      this.el.querySelector('.askAdminChatBox-' + this.view).scrollTop =
        this.el.querySelector('.askAdminChatBox-' + this.view).scrollHeight
    })

    // Set new message status to false
    this.updateNewMessageStatus()

    // Mark the thread as read in the model
    this.askAdminUserCollection.get(this.userId).set({ hasUnread: false })

    // Remove the icon of new message if no more users with new message
    if (
      !this.askAdminUserCollection.toJSON().some((thread) => thread.hasUnread)
    ) {
      if (this.el.querySelector('.newMessages-' + this.view)) {
        this.el.querySelector('.newMessages-' + this.view).remove()
      }
    }
  },

  // Load new message user list view.
  loadUserList(e) {
    this.el.querySelector('.askAdminBody-' + this.view).remove()
    this.renderUserList()
  },

  // Update status of new message to false for the user
  updateNewMessageStatus() {
    var collection = this.askAdminMessagesCollection
    this.askAdminMessagesModel = new collection.model()

    this.askAdminMessagesModel.set({
      cosparId: this.cosparId,
      userId: this.userId,
      isMessagePost: false,
    })

    this.askAdminMessagesModel.idAttribute = null

    this.askAdminMessagesModel.save()
  },

  addPanelButton(spanClass, appendClass) {
    var span = document.createElement('span')
    span.className += spanClass

    this.el.querySelector(appendClass).appendChild(span)
  },
})
