/*
	Model to store admin user filter attributes.
*/
import Backbone from 'backbone'

var AdminUserFilterModel = Backbone.Model.extend({})

var adminUserFilterModel = new AdminUserFilterModel()

// make it a singleton
export default adminUserFilterModel
