/*
	Model to store admin event filter attributes.
*/
import Backbone from 'backbone'

var AdminEventFilterModel = Backbone.Model.extend({})

var adminEventFilterModel = new AdminEventFilterModel()

// make it a singleton
export default adminEventFilterModel
