import Backbone from 'backbone'

var UserSettings = Backbone.Model.extend({
  url: '/api/protected/settings',

  initialize() {
    this.fetch({
      reset: true,
    })
  },
})

var userSettings = new UserSettings()

// make it a singleton
export default userSettings
