/*
	Class to display home blog view.
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'

export const HomeBlogCollectionView = Backbone.View.extend({
  template: require('../../templates/HomeBlogCollectionView.hbs'),

  initialize(options) {
    this.blogs = _.keyBy(options.blogs, 'id')
    this.router = options.router
  },

  events: {
    'click .blog': 'clickItem',
    'click button.mainView': 'renderMainBlog',
  },

  render(blogs) {
    const markup = this.template({
      blogCollection: _.orderBy(blogs, (o) => new Date(o.date), 'desc'),
    })
    this.$el.html(markup)

    return this
  },

  renderMainBlog() {
    if (this.el.querySelector('.blogItemOuter')) {
      this.$el.find('.blogItemOuter').remove()
    }

    $('#blog').show()
    $('#article-tab').hide()

    this.el.querySelector('.blogItems').style.display = 'block'
  },

  clickItem(e) {
    // Remove blog- prefix from element ID
    const blogId = e.currentTarget.id.replace(/^blog-/, '')
    this.renderItem(blogId, true)
  },

  /**
   * Render blog item, and update the URL.
   * @param blogId
   * @param push - If true, URL is pushed to history. Should be false for the
   * 					     initial render.
   */
  renderItem(blogId, push) {
    this.el.querySelector('.blogItems').style.display = 'none'

    const divOuter = document.createElement('div')
    divOuter.className = 'blogItemOuter'
    divOuter.style.width = this.divWidth + 'px'

    const htmlFile = this.blogs[blogId].link
    this.router.navigate(`home/blog/${blogId}`, { replace: !push })

    const divInner = document.createElement('div')
    divInner.className = 'blogItemInner'

    divOuter.appendChild(divInner)

    this.el.querySelector('.blogs').appendChild(divOuter)

    const articleTab = $('#article-tab')
    articleTab.show()
    articleTab.addClass('active')
    $('#blog').hide()

    this.loadHTMLFile(this.el.querySelector('.blogItemInner'), htmlFile)
  },

  loadHTMLFile(target, url) {
    var r = new XMLHttpRequest()
    r.open('GET', url, true)
    r.onreadystatechange = function () {
      if (r.readyState !== 4 || r.status !== 200) return
      target.innerHTML = r.responseText
    }
    r.send()
  },
})
