// Filter factory for different views.
export function applyFilter(filter, item) {
  switch (Object.keys(filter)[0]) {
    case 'text':
      if (item) {
        item = item.toString().toLowerCase()
        const find = filter.text.toLowerCase()
        return item.indexOf(find) >= 0
      }
      break
    case 'range':
      if (
        Number(item) >= Number(filter.range.split(',')[0]) &&
        Number(item) <= Number(filter.range.split(',')[1])
      ) {
        return true
      }
      break
    case 'checkbox':
      if (filter.checkbox.indexOf(item) <= -1) {
        return true
      }
      break
    case 'dateFrom':
      if (item >= filter.dateFrom) {
        return true
      }
      break
    case 'dateTo':
      if (item <= filter.dateTo) {
        return true
      }
      break
  }
}
