const H = require('hbsfy/runtime')
const HB = require('handlebars')
const sprintf = require('sprintf-js').sprintf
const _ = require('lodash')
const moment = require('moment')

if (typeof window === 'undefined') {
  // node environment
  exports.HB = HB
  exports.sprintf = sprintf
} else {
  window.HB = HB
  window.sprintf = sprintf
}

var defaultEach = HB.helpers.each
var helpers = {
  /**
   * format the input as per formatting string "format"
   *
   **/

  formatNumber(context, block) {
    if (context === undefined || context === '') {
      return '-'
    }
    var f = '%.3f'
    if (typeof block !== 'undefined') {
      f = block.hash.format || '%.3f'
    }
    return sprintf(f, +context).replace(' ', '&nbsp;')
  },

  max(context, block) {
    var property = block.hash.property

    return block.fn(_.max(context, property))
  },

  min(context, block) {
    var property = block.hash.property

    return block.fn(_.min(context, property))
  },

  last(context, block) {
    var sortBy = block.hash.sortBy || 'reentryTime'

    return block.fn(_.last(_.sortBy(context, sortBy)))
  },

  first(context, block) {
    var sortBy = block.hash.sortBy || 'reentryTime'

    return block.fn(_.head(_.sortBy(context, sortBy)))
  },

  each(context, block) {
    var cont = context
    if (block.hash.sortBy) {
      cont = _.sortBy(context, block.hash.sortBy)
    }

    return defaultEach.call(this, cont, block)
  },

  formatDate(context) {
    var f = 'DD MMM YYYY'
    var date = moment.utc(context)
    return date.format(f)
  },

  formatDateHour(context) {
    var f = 'DD MMM YYYY ~HH'
    var date = moment.utc(context)
    return date.format(f) + 'h'
  },

  truncate(context, block) {
    if (context === undefined || context === '') {
      return '-'
    }
    const truncateOptions = {}
    if (typeof block !== 'undefined') {
      if (block.hash.maxLength) {
        truncateOptions.length = block.hash.maxLength
      }
      if (block.hash.separator) {
        truncateOptions.separator = block.hash.separator
      }
      if (block.hash.omission) {
        truncateOptions.omission = block.hash.omission
      }
    }
    return _.truncate(context, truncateOptions)
  },
}

// register helper functions with handlebars
for (var helper in helpers) {
  HB.registerHelper(helper, helpers[helper])
  H.registerHelper(helper, helpers[helper])
}

H.registerHelper('chain', function () {
  var helpers = []
  var args = Array.prototype.slice.call(arguments)
  var argsLength = args.length
  var index
  var arg

  for (index = 0, arg = args[index]; index < argsLength; arg = args[++index]) {
    if (H.helpers[arg]) {
      helpers.push(H.helpers[arg])
    } else {
      args = args.slice(index)
      break
    }
  }

  while (helpers.length) {
    args = [helpers.pop().apply(H.helpers, args)]
  }

  return args.shift()
})

H.registerHelper('ifCond', function (v1, v2, options) {
  if (v1 === v2) {
    return options.fn(this)
  }
  return options.inverse(this)
})

H.registerHelper('breaklines', function (text) {
  text = H.Utils.escapeExpression(text)
  text = text.replace(/(\r\n|\n|\r)/gm, '<br>')
  return new H.SafeString(text)
})
