var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<form role=\"form\" class=\"form\">\n            <label>Email frequency for followed events</label>\n						<div class=\"form-group\">\n              <label class=\"radio-inline\">\n                <input type=\"radio\" name=\"frequencyEmail\" value=\"Daily\" id=\"settingsFrequencyEmailDaily\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),"Daily",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":105},"end":{"line":21,"column":159}}})) != null ? stack1 : "")
    + ">\n                Daily\n              </label>\n              <label class=\"radio-inline\">\n                <input type=\"radio\" name=\"frequencyEmail\" value=\"Weekly\" id=\"settingsFrequencyEmailWeekly\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),"Weekly",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":107},"end":{"line":25,"column":162}}})) != null ? stack1 : "")
    + ">\n                Weekly\n              </label>\n              <label class=\"radio-inline\">\n                <input type=\"radio\" name=\"frequencyEmail\" value=\"Never\" id=\"settingsFrequencyEmailNever\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),"Never",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":105},"end":{"line":29,"column":159}}})) != null ? stack1 : "")
    + ">\n                Never\n              </label>\n            </div>\n					</form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"settings-modal\" role=\"dialog\" aria-hidden=\"true\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n					<span aria-hidden=\"true\">&times;</span>\n					<span class=\"sr-only\">Close</span>\n				</button>\n				<h4 class=\"modal-title\" id=\"reqAddInfoLabel\">\n					Settings\n				</h4>\n			</div>\n\n			<div class=\"modal-body\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"settings") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + "			</div>\n\n			<div class=\"modal-footer\">\n        <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"accountSettingsURL") || (depth0 != null ? lookupProperty(depth0,"accountSettingsURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"accountSettingsURL","hash":{},"data":data,"loc":{"start":{"line":38,"column":17},"end":{"line":38,"column":39}}}) : helper)))
    + "\" class=\"btn btn-default\" role=\"button\" target=\"_blank\">Account settings</a>\n				<button type=\"button\" class=\"btn btn-primary saveSettings\" data-dismiss=\"modal\" id='saveSettings' ><span class=\"glyphicon glyphicon-floppy-disk\" /> Save changes</button>\n				<button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\"><span class=\"glyphicon glyphicon-remove-circle\" /> Close</button>\n			</div>\n\n		</div><!-- /.modal-content -->\n	</div><!-- /.modal-dialog -->\n</div><!-- /.modal -->\n";
},"useData":true});