var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"col-lg-2-25\" style=\"height:100%\">\n		<div class=\"detailFilterMenu\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"filterDivHeight") || (depth0 != null ? lookupProperty(depth0,"filterDivHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"filterDivHeight","hash":{},"data":data,"loc":{"start":{"line":5,"column":46},"end":{"line":5,"column":65}}}) : helper)))
    + "; overflow: hidden\">\n			<div class=\"detailFilesFilter\"></div>\n		</div>\n		<br><br>\n		<div class=\"detailAskAdminView\" />\n	</div>\n	<div class=\"col-lg-9-75\" style=\"height:100%\">\n		<div class=\"detailFilesItem\"></div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "	<center><span class=\"fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom\" style=\"margin:100px auto auto\"></span></center>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"detailUploadModal\"></div>\n<div class=\"detailReqAddInfoModal\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dataPopulated") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":16,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"detailFullScreenModal\"></div>\n";
},"useData":true});