/*
	Display messages and send it
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import messageBus from '../models/MessageBus'
import whoAmI from '../models/WhoAmI'
import AskAdminMessagesCollection from '../models/AskAdminMessagesCollection'

export const AskAdminMessagesView = Backbone.View.extend({
  template: require('../../templates/AskAdminMessages.hbs'),

  initialize(options) {
    console.log('Initialized Ask Admin Messages View')

    this.view = options.view
    this.cosparId = options.cosparId
    this.userId = options.userId
    this.isAdminUser = options.isAdminUser
  },

  events: {
    'click button.sendMessage': 'sendMessage',
    'click button.eraseMessage': 'eraseMessage',
  },

  render(collection, isBackButtonDisplay) {
    var markup
    this.collection = collection
    markup = this.template({
      collection: collection.toJSON(),
      divHeightPanelBody: isBackButtonDisplay ? '190px' : '210px',
      backButtonDisplay: isBackButtonDisplay,
      view: this.view,
      isAdminUser: this.isAdminUser,
    })
    this.$el.html(markup)

    return this
  },

  sendMessage(e) {
    e.preventDefault()
    console.log(e)
    var message = this.el.querySelector('#askAdminMessage-' + this.view).value
    if (message !== '') {
      var collection = this.collection
      this.askAdminMessagesModel = new collection.model()

      this.askAdminMessagesModel.set({
        message,
        cosparId: this.cosparId,
        messageTimeStamp: moment.utc().format('DD MMM YYYY HH:mm:ss'),
        userId: this.userId,
        isMessagePost: true,
      })

      this.askAdminMessagesModel.idAttribute = null

      this.askAdminMessagesModel
        .save()
        .success(function (model) {
          collection.add(model)
        })
        .error(function (model, error) {
          messageBus.trigger('growl', {
            type: 'danger',
            content: 'Message not sent' + error.responseText,
          })
        })
    }
  },

  eraseMessage(e) {
    this.el.querySelector('#askAdminMessage-' + this.view).value = ''
  },
})
