var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <li><a class=\"dropdown-item\" target=\"_blank\" href=\"/files/restricted/GEN-REN-MAN-00233-OPS-GR ESA Re-entry Prediction Front-end User Guide  to the Restricted Access.pdf\">Detailed predictions</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"dropdown\" id=\"helpLink\">\n	<a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n    Documentation\n    <span class=\"caret\"></span>\n  </a>\n  <ul class=\"dropdown-menu\">\n    <li><a class=\"dropdown-item\" target=\"_blank\" href=\"/files/public/GEN-REN-MAN-00232-OPS-GR ESA Re-entry Prediction Front-end User Guide.pdf\">Basic documentaion</a></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"isPrivileged") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "  </ul>\n</li>\n<li class=\"dropdown\" id=\"NavigationView\">\n	<a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n		<span class=\"glyphicon glyphicon-user\"></span>\n		"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1), depth0))
    + "\n		<span class=\"caret\"></span>\n	</a>\n	<div class=\"dropdown-menu panel panel-default nav-user\" role=\"menu\" style=\"min-width:190px\">\n			<div class=\"panel-body\">\n				<div>\n					<strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1), depth0))
    + "</strong>\n				</div>\n				<div>\n					<small>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "</small>\n				</div>\n			</div>\n			<div class=\"panel-footer\">\n				<div class=\"btn-group\" role=\"group\">\n					<a class=\"btn btn-default btn-sm\" data-toggle=\"modal\" data-backdrop=\"static\" data-target=\"#settings-modal\">\n						<span class=\"glyphicon glyphicon-cog\"></span>\n						Settings\n					</a>\n					<a id=\"logout\" class=\"btn btn-primary btn-sm pull-right\" href=\"/logout\">\n						<span class=\"glyphicon glyphicon-log-out\"></span>\n						Logout\n					</a>\n				</div>\n			</div>\n	</div>\n</li>\n";
},"useData":true});