/*
	Model to store reentry filter attributes.
*/
import Backbone from 'backbone'

var ReentryFilterModel = Backbone.Model.extend({})

var reentryFilterModel = new ReentryFilterModel()

// make it a singleton
export default reentryFilterModel
