module.exports = {
  reentryFieldNames: {
    COSPAR_ID: 'cosparId',
    SATNO: 'satno',
    NAME: 'objectName',
    REENTRY_DATE: 'reentryTime',
    UNCERTAINTY: 'uncertaintyWindow',
    MASS: 'objectMass',
    CLASS: 'objectClass',
    TOOL: 'methodBcoefFitting',
    COUNTRY: 'country',
    CREATION_DATE: 'creationDate',
    LATITUDE: 'reentryLat',
    LONGITUDE: 'reentryLon',
    CREATION_DATE_RANK: 'creationDateRank',
    ORBIT_EPOCH: 'objectOrbitEpoch',
    SEMI_MAJOR_AXIS: 'objectSemiMajorAxis',
    ECCENTRICITY: 'objectEccentricity',
    INCLINATION: 'objectInclination',
    LONGITUDE_OF_ASCENDING_NODE: 'objectLongitudeOfAscendingNode',
    ARGUMENT_OF_PERIAPSIS: 'objectArgumentOfPeriapsis',
    TRUE_ANOMALY: 'objectTrueAnomaly',
    PERIGEE: 'objectPerigeeHeight',
    APOGEE: 'objectApogeeHeight',
    IS_FOLLOWED: 'isFollowed',
    IS_RESTRICTED: 'isRestricted',
  },
  keplerParams: {
    EPOCH: 'epoch',
    SEMI_MAJOR_AXIS: 'semiMajorAxis',
    ECCENTRICITY: 'eccentricity',
    INCLINATION: 'inclination',
    LONGITUDE_OF_ASCENDING_NODE: 'longitudeOfAscendingNode',
    ARGUMENT_OF_PERIAPSIS: 'argumentOfPeriapsis',
    TRUE_ANOMALY: 'trueAnomaly',
  },
  askAdmin: {
    IS_ADMIN_MESSAGE: 'isAdminMessage',
    MESSAGE: 'message',
    TIME: 'time',
    IS_NEW_MESSAGE: 'isNewMessage',
  },
}
