/*
	Global Detail View which populates the required sub-detail views
	e.g event info, list of files for the event, ask admin.
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import { DetailFilesFilterView } from './DetailFilesFilterView'
import { DetailFilesItemView } from './DetailFilesItemView'
import { DetailFilesUploadItemView } from './DetailFilesUploadItemView'
import { DetailFilesReqAddInfoView } from './DetailFilesReqAddInfoView'
import { AskAdminView } from './AskAdminView'
import ReentryDataCollection from '../models/ReentryDataCollection'
import DetailModel from '../models/DetailModel'
import DetailFilesItemModel from '../models/DetailFilesItemModel'
import DetailFilesFilterModel from '../models/DetailFilesFilterModel'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'
import whoAmI from '../models/WhoAmI'

export const DetailView = Backbone.View.extend({
  template: require('../../templates/DetailView.hbs'),

  whoAmI,

  askAdminWindowOpened: false,

  dataPopulated: false,

  initialize(options) {
    console.log('Initialized Detail View')

    this.cosparId = options.cosparId
    this.isAdmin = options.userRights === 'admin'

    this.detailEventCollection = new ReentryDataCollection({
      query: reentryColumns.COSPAR_ID + '==' + this.cosparId,
      view: 'detail',
    })

    this.askAdminView = new AskAdminView({
      cosparId: this.cosparId,
      userId: this.whoAmI.toJSON().userId,
      isAdminUser: this.isAdmin,
      view: 'detail',
    })

    this.render()

    this.listenTo(this.detailEventCollection, 'reset', function (e) {
      var detailFilesItemModel = new DetailFilesItemModel()
      var detailFilesFilterModel = new DetailFilesFilterModel()

      var eventReentryDateHistory = _.map(
        this.detailEventCollection.toJSON()[0].content,
        reentryColumns.REENTRY_DATE,
      )

      var detailModel = new DetailModel({
        cosparId: this.cosparId,
        detailFilesFilterModel,
      })

      if (this.isAdmin) {
        this.detailFilesUploadItemView = new DetailFilesUploadItemView({
          detailModel,
          cosparId: this.cosparId,
        })
      }

      this.detailFilesReqAddInfoView = new DetailFilesReqAddInfoView({
        detailModel,
        cosparId: this.cosparId,
      })

      this.detailFilesDivHeight = this.isAdmin
        ? this.$el.parent().height() - 210
        : this.$el.parent().height() - 150
      this.detailFilesFilterView = new DetailFilesFilterView({
        detailFilesFilterModel,
        detailFilesItemModel,
        detailModel,
        cosparId: this.cosparId,
        divHeight: this.detailFilesDivHeight,
        isAdmin: this.isAdmin,
        detailEventCollection: this.detailEventCollection,
      })

      this.detailFilesItemView = new DetailFilesItemView({
        detailModel,
        detailFilesItemModel,
        divHeight: this.$el.parent().height(),
        cosparId: this.cosparId,
        eventReentryDateHistory,
        detailEventCollection: this.detailEventCollection,
      })

      this.dataPopulated = true

      this.render()
    })
  },

  events: {
    'click button.uploadFileButton': 'uploadFileButton',
    'click .askAdminPanelHeading-detail': 'detailAskAdminPanelHeading',
  },

  renderUploadFile() {
    if (this.isAdmin) {
      this.el
        .querySelector('.detailUploadModal')
        .appendChild(this.detailFilesUploadItemView.render().el)
      return this
    }
  },

  renderReqAddInfo() {
    this.el
      .querySelector('.detailReqAddInfoModal')
      .appendChild(this.detailFilesReqAddInfoView.render().el)
    return this
  },

  renderAskAdmin() {
    if (this.el.querySelector('.askAdminWindow-detail')) {
      this.$el.find('.askAdminWindow-detail').remove()
    }

    this.el
      .querySelector('.detailAskAdminView')
      .appendChild(this.askAdminView.render(this.askAdminWindowOpened).el)
  },

  uploadFileButton(e) {
    var currentDateTime = moment.utc().format('DD-MMM-YYYY HH:mm:ss')
    this.el.querySelector('input.uploadFileDate').value = currentDateTime
    return this
  },

  renderFiles() {
    this.el
      .querySelector('.detailFilesFilter')
      .appendChild(this.detailFilesFilterView.render().el)
    return this
  },

  renderDetailItem(divHeight) {
    this.el
      .querySelector('.detailFilesItem')
      .appendChild(this.detailFilesItemView.render(false).el)
    return this
  },

  render() {
    this.filterDivHeight = this.$el.parent().height() - 90
    var markup = this.template({
      filterDivHeight: this.filterDivHeight + 'px',
      dataPopulated: this.dataPopulated,
    })
    this.$el.html(markup)
    if (this.dataPopulated) {
      this.renderUploadFile()
      this.renderReqAddInfo()
      this.renderFiles()
      this.renderAskAdmin()
      this.renderDetailItem()
    }
    return this
  },

  detailAskAdminPanelHeading(e) {
    if (!this.askAdminWindowOpened) {
      // if ask admin window closed, open it.
      // change the icon to opened in header
      // reduce filter and files div height
      this.$el.find('.expandDetailAskAdmin').remove()
      if (this.el.querySelector('.newMessages-detail') && !this.isAdmin) {
        this.askAdminView.updateNewMessageStatus()
        this.el.querySelector('.newMessages-detail').remove()
      }
      this.$el.find('#panelBodyAskAdmin-detail').collapse('show')
      this.detailFilesDivHeight = this.detailFilesDivHeight - 335
      this.filterDivHeight = this.filterDivHeight - 335
      this.el.querySelector('.detailFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.el.querySelector('.detailFilesMenu').style.height =
        this.detailFilesDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-top collapseDetailAskAdmin',
        '.askAdminPanelExpand-detail',
      )
      if (this.el.querySelector('.askAdminChatBox-detail')) {
        this.el.querySelector('.askAdminChatBox-detail').scrollTop =
          this.el.querySelector('.askAdminChatBox-detail').scrollHeight
      }
      this.askAdminWindowOpened = true
    } else {
      // if ask admin window opened, close it.
      // change the icon to closed in header
      // increase filter and files div height
      this.$el.find('.collapseDetailAskAdmin').remove()
      this.$el.find('#panelBodyAskAdmin-detail').collapse('hide')
      this.detailFilesDivHeight = this.detailFilesDivHeight + 335
      this.filterDivHeight = this.filterDivHeight + 335
      this.el.querySelector('.detailFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.el.querySelector('.detailFilesMenu').style.height =
        this.detailFilesDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-right expandDetailAskAdmin',
        '.askAdminPanelExpand-detail',
      )
      this.askAdminWindowOpened = false
    }
    e.preventDefault()
    e.stopPropagation()
  },

  addPanelButton(spanClass, appendClass) {
    var span = document.createElement('span')
    span.className += spanClass

    this.el.querySelector(appendClass).appendChild(span)
  },
})
