var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"filter-input timeFilter\">\n	<label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":19}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":2,"column":21},"end":{"line":2,"column":30}}}) : helper)))
    + "</label>\n	<div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":16}}}) : helper)))
    + "\">\n		<small class=\"text-muted\">From:</small>\n		<div class=\"input-group\">\n			<div class=\"input-group-btn\">\n				<button type=\"button\" class=\"btn btn-default filter_datetime reentryDateFilter\" id=\"from-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":7,"column":93},"end":{"line":7,"column":99}}}) : helper)))
    + "\">\n					<span class=\"glyphicon glyphicon-calendar\"></span>\n				</button>\n			</div>\n			<input id=\"text-from-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":30}}}) : helper)))
    + "\" type=\"text\" class=\"form-control value field\" disabled=\"disabled\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"valueFrom") || (depth0 != null ? lookupProperty(depth0,"valueFrom") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"valueFrom","hash":{},"data":data,"loc":{"start":{"line":11,"column":104},"end":{"line":11,"column":117}}}) : helper)))
    + "\">\n			<div class=\"input-group-btn\">\n				<button tabindex=\"-1\" type=\"button\" class=\"btn btn-default remove-time-value\" id=\"removeFrom-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":97},"end":{"line":13,"column":103}}}) : helper)))
    + "\">\n					<span class=\"glyphicon glyphicon-remove\"></span>\n				</button>\n			</div>\n		</div>\n		<small class=\"text-muted\">To:</small>\n		<div class=\"input-group\">\n			<div class=\"input-group-btn\">\n				<button type=\"button\" class=\"btn btn-default filter_datetime reentryDateFilter\" id=\"to-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":21,"column":91},"end":{"line":21,"column":97}}}) : helper)))
    + "\">\n					<span class=\"glyphicon glyphicon-calendar\"></span>\n				</button>\n			</div>\n			<input id=\"text-to-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":25,"column":22},"end":{"line":25,"column":28}}}) : helper)))
    + "\" type=\"text\" class=\"form-control value field\" disabled=\"disabled\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"valueTo") || (depth0 != null ? lookupProperty(depth0,"valueTo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"valueTo","hash":{},"data":data,"loc":{"start":{"line":25,"column":102},"end":{"line":25,"column":113}}}) : helper)))
    + "\">\n			<div class=\"input-group-btn\">\n				<button tabindex=\"-1\" type=\"button\" class=\"btn btn-default remove-time-value\" id=\"removeTo-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":27,"column":95},"end":{"line":27,"column":101}}}) : helper)))
    + "\">\n					<span class=\"glyphicon glyphicon-remove\"></span>\n				</button>\n			</div>\n		</div>\n	</div>\n	<br>\n</div>\n";
},"useData":true});