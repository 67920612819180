/*
	View displays list of files for the current cosparId
	Also displays the current event information.
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'
import messageBus from '../models/MessageBus'
import reentryFollowCollection from '../models/ReentryFollowCollection'
import whoAmI from '../models/WhoAmI'

export const DetailFilesFilterView = Backbone.View.extend({
  template: require('../../templates/DetailFilesFilterView.hbs'),

  detailFilesCollection: null,

  fileNames: [],

  fileTypes: null,

  fileIndexActive: null,

  sortIndexActive: null,

  nameSearchValue: '',

  fileIds: {},

  lastIndexProperty: 1,

  sortKey: null,

  divHeight: null,

  eventDetails: {},

  eventDetailWindowOpened: false,

  autoFocusField: null,

  initialize(options) {
    console.log('Initialized Details Files Filter View')

    this.detailModel = options.detailModel

    this.detailFilesItemModel = options.detailFilesItemModel

    this.detailFilesFilterModel = options.detailFilesFilterModel

    this.detailFilesCollection = this.detailModel.getDetailFilesCollection()

    this.isAdmin = options.isAdmin

    this.divHeight = options.divHeight

    this.cosparId = options.cosparId

    this.eventDetails = options.detailEventCollection.toJSON()[0]

    this.listenTo(this.detailFilesCollection, 'reset', function () {
      this.fileTypes = this.detailModel.getFileTypes()
      this.fileNames = _.uniqBy(
        this.detailFilesCollection.toJSON(),
        function (item) {
          return item.name + item.type
        },
      )
      this.nameSearchValue = this.detailFilesFilterModel.has('name')
        ? this.detailFilesFilterModel.get('name').text
        : ''
      this.render()
    })

    this.listenTo(reentryFollowCollection, 'add remove', function () {
      this.render()
    })
  },

  render() {
    var markup

    markup = this.template({
      eventDetails: this.eventDetails,
      detailFiles: this.fileNames,
      fileTypes: this.fileTypes,
      nameSearchValue: this.nameSearchValue,
      divHeight: this.divHeight + 'px',
      isAdmin: this.isAdmin,
      reentryColumns,
      isFollowed: _.map(
        reentryFollowCollection.toJSON(),
        reentryColumns.COSPAR_ID,
      ).includes(this.cosparId),
      showRequestRestrictedButton:
        whoAmI.get('isPrivileged') && !whoAmI.get('isPrivilegedAll'),
    })
    this.$el.html(markup)

    if (this.eventDetailWindowOpened) {
      this.$el.find('#panelBodyDetailEventInfo').collapse('show')
      this.addPanelButton(
        'glyphicon glyphicon-triangle-bottom collapseDetailEventInfo',
        '.detailEventInfoPanel',
      )
    } else {
      this.addPanelButton(
        'glyphicon glyphicon-triangle-right expandDetailEventInfo',
        '.detailEventInfoPanel',
      )
    }

    // Autofocus if last filter was text search
    if (this.autoFocusField !== null) {
      this.el.querySelector('#' + this.autoFocusField).focus()
      this.el.querySelector('#' + this.autoFocusField).value =
        this.el.querySelector('#' + this.autoFocusField).value
    }

    return this
  },

  events: {
    'click .fileItem': 'eventDetailItemClicked',
    'click input.detailFilesCheck': 'applyCheckListDetailsType',
    'click .sortItem': 'sortFileList',
    'input input.text': 'applyTextFilter',
    'click #detailEventInfoPanelHeading': 'detailEventInfoPanelHeading',
    'click .followButton': 'userFollowEvent',
    // TODO : The following listeners should be part of ask admin view.
    'click .usersEmailListItem': 'usersEmailListItem',
    'click button.back': 'loadUserList',
  },

  // Populates list of files available for a particular file name and type
  populateDisplayItems(file) {
    var itemList = _.sortBy(this.detailModel.getItemListToDisplay(file), 'date')
    var indexItemDisplayed = itemList.length - 1
    var itemDisplayed = itemList[indexItemDisplayed]
    var dates = _.map(itemList, 'date')

    var includeDates = (function () {
      var datesFound = []
      for (var i = 0; i < dates.length; i++) {
        datesFound.push(
          moment.utc(dates[i], 'YYYYMMDDHHmmss').format('DD-MMM-YYYY HH:mm:ss'),
        )
      }
      return datesFound
    })()

    var displayedItemObject = {
      itemList,
      indexItemDisplayed,
      itemDisplayed,
      dates,
      includeDates,
    }

    return displayedItemObject
  },

  eventDetailItemClicked(e) {
    console.log('Fetch File', e.currentTarget.id)
    var index = e.currentTarget.id

    var fileId =
      this.fileNames[index].type +
      '.' +
      this.fileNames[index].extension +
      '.' +
      this.fileNames[index].name
    var fileIds = this.fileIds
    if (this.detailFilesItemModel.has('fileIds')) {
      fileIds = _.clone(this.detailFilesItemModel.get('fileIds'))
    }
    if (this.detailFilesItemModel.get('view') === 'single') {
      var idToChange = this.detailFilesItemModel.has('fullScreen')
        ? this.detailFilesItemModel.get('fullScreen')
        : '0'
      fileIds[idToChange] = this.populateDisplayItems(fileId)
    } else {
      fileIds[++this.lastIndexProperty] = this.populateDisplayItems(fileId)
    }
    this.detailFilesItemModel.set('fileIds', fileIds)
  },

  applyCheckListDetailsType(e) {
    console.log('CheckList clicked', e)
    this.autoFocusField = null
    e.stopPropagation()
    e.preventDefault()
    var inputGroup = $(e.target).parents('ul.detailFilesCheckList')
    var input = inputGroup.children('input.value')
    var inputValues = []
    inputGroup.find('input.detailFilesCheck').each(function (index) {
      var checked = $(this).prop('checked')
      if (!checked) {
        inputValues.push($(this).prop('value'))
      }
    })
    this.detailFilesFilterModel.set(
      $(e.target).parents('ul.detailFilesCheckList').prop('id'),
      { checkbox: inputValues },
    )
  },

  sortFileList(e) {
    console.log('Sort', e)
    this.autoFocusField = null
    this.detailFilesFilterModel.set('sort', e.currentTarget.id)
    this.$el.find('#' + e.currentTarget.id).addClass('active')
    if (this.sortIndexActive !== null) {
      this.$el.find('#' + this.sortIndexActive).removeClass('active')
    }
    this.sortIndexActive = e.currentTarget.id
  },

  applyTextFilter: _.debounce(function (e) {
    console.log('Text searched', e)
    this.autoFocusField = e.currentTarget.id
    this.detailFilesFilterModel.set(e.currentTarget.id, {
      text: e.currentTarget.value,
    })
  }, 500),

  detailEventInfoPanelHeading(e) {
    if (this.el.querySelector('.expandDetailEventInfo')) {
      this.$el.find('.expandDetailEventInfo').remove()
      this.$el.find('#panelBodyDetailEventInfo').collapse('show')
      this.divHeight = this.divHeight - 265
      this.el.querySelector('.detailFilesMenu').style.height =
        this.divHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-bottom collapseDetailEventInfo',
        '.detailEventInfoPanel',
      )
      this.eventDetailWindowOpened = true
    } else {
      this.$el.find('.collapseDetailEventInfo').remove()
      this.$el.find('#panelBodyDetailEventInfo').collapse('hide')
      this.divHeight = this.divHeight + 265
      this.el.querySelector('.detailFilesMenu').style.height =
        this.divHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-right expandDetailEventInfo',
        '.detailEventInfoPanel',
      )
      this.eventDetailWindowOpened = false
    }
  },

  sendMessage(e) {
    this.askAdminView.sendMessage(e)
  },

  eraseMessage(e) {
    this.askAdminView.eraseMessage(e)
  },

  usersEmailListItem(e) {
    this.askAdminView.usersEmailListItem(e)
  },

  loadUserList(e) {
    this.askAdminView.loadUserList(e)
  },

  userFollowEvent(e) {
    var divId = e.currentTarget.id
    var isActionFollow = divId === 'follow' ? true : false

    e.preventDefault()

    if (isActionFollow) {
      var collection = reentryFollowCollection
      this.reentryFollowModel = new collection.model()
      this.reentryFollowModel.set({
        cosparId: this.cosparId,
      })
      this.reentryFollowModel.idAttribute = null
      this.reentryFollowModel
        .save()
        .success(function (model) {
          collection.add(model)
        })
        .error(function (model, error) {
          messageBus.trigger('growl', {
            type: 'danger',
            content: 'Error -' + error.responseText,
          })
        })
    } else {
      this.reentryFollowModel = reentryFollowCollection.get(this.cosparId)
      this.reentryFollowModel.destroy({
        wait: true,
        success(model) {
          reentryFollowCollection.remove(model)
        },
        error(model, error) {
          console.log(arguments)
          messageBus.trigger('growl', {
            type: 'danger',
            content: 'Error -' + JSON.stringify(error),
          })
        },
      })
    }
  },

  addPanelButton(spanClass, appendClass) {
    var span = document.createElement('span')
    span.className += spanClass

    this.el.querySelector(appendClass).appendChild(span)
  },
})
