import Backbone from 'backbone'
import _ from 'lodash'

export default Backbone.View.extend({
  template: require('../../templates/SelectorDropdown.hbs'),

  initialize(options) {
    this.input = options.input
    if (this.input.selectors.length === 1) {
      this.input.selector = this.input.selectors[0]
    }
  },

  render() {
    this.$el.html(this.template(this.input))
    return this
  },

  loadFromFilter(inFilterSet) {
    var filter, domElement, button, selector, i

    filter = null
    domElement = this.el.querySelector('.field')
    button = domElement.parentNode.querySelector('button.selected')

    for (i = 0; i < inFilterSet.length; ++i) {
      if (inFilterSet[i].hasOwnProperty(this.input.id)) {
        filter = inFilterSet[i][this.input.id]

        if (filter.$lt) {
          button.innerHTML = '&lt;'
          domElement.value = filter.$lt
        } else if (filter.$gt) {
          button.innerHTML = '&gt;'
          domElement.value = filter.$gt
        } else {
          console.error('WTF?!?!')
        }

        return
      }
    }

    domElement.value = ''
  },

  getFilter() {
    var inp = this.el.querySelector('.field')
    var selectedButton = inp.parentNode.querySelector('button.selected')
    var selector = selectedButton.innerHTML.trim()

    var value = inp.value.trim()
    var dbCol = inp.id.split('-')[1]

    var ret = null
    if (value !== '') {
      ret = {}
      switch (selector) {
        case '&lt;':
          ret[dbCol] = { $lt: value }
          break

        case '&gt;':
          ret[dbCol] = { $gt: value }
          break

        default:
          break
      }
    }
    return ret
  },
})
