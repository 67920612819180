/*
	To display the request additional info modal
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import messageBus from '../models/MessageBus'
import reentryRestrictedCollection from '../models/ReentryRestrictedCollection'
import ReentryRequestRestrictedModel from '../models/ReentryRequestRestrictedModel'

export const DetailFilesReqAddInfoView = Backbone.View.extend({
  template: require('../../templates/DetailFilesReqAddInfo.hbs'),

  initialize(options) {
    console.log('Initialized Details Files Request Additional Information View')

    this.detailModel = options.detailModel

    this.cosparId = options.cosparId
  },

  events: {
    'click button.reqAddInfoSubmit': 'reqAddInfoSubmit',
  },

  render() {
    var markup = this.template({})
    this.$el.html(markup)
    return this
  },

  reqAddInfoSubmit(e) {
    e.preventDefault()
    var cosparId = this.cosparId

    e.preventDefault()

    var reason = this.el.querySelector('.reqAddInfoReason').value
    var tncAccepted = this.el.querySelector('#reqAddInfoTnc').checked
    if (!reason) {
      messageBus.trigger('growl', {
        type: 'danger',
        content: 'Error - no reason entered!',
      })
      return
    }

    if (!tncAccepted) {
      messageBus.trigger('growl', {
        type: 'danger',
        content: 'Error - T & C not accepted!',
      })
      return
    }

    var reentryRequestRestrictedModel = new ReentryRequestRestrictedModel()
    reentryRequestRestrictedModel.set({
      cosparId,
      requestReason: reason,
    })
    reentryRequestRestrictedModel.idAttribute = null
    reentryRequestRestrictedModel
      .save()
      .success(function (model) {
        messageBus.trigger('growl', {
          type: 'success',
          content: 'Request sent!',
        })
      })
      .error(function (model, error) {
        messageBus.trigger('growl', {
          type: 'danger',
          content: 'Error -' + error.responseText,
        })
      })
  },
})
