import three from 'three'
import _ from 'lodash'
import {
  keplerParams,
  reentryFieldNames as reentryColumns,
} from '../../../common/config/constants'

// Convert mean to true anomaly
export function mean2true(M, e) {
  // tolerance for iteration
  var tol = 1e-8

  // find eccentric anomaly first
  let En
  if ((-Math.PI < M && M < 0.0) || M > Math.PI) {
    En = M - e
  } else {
    En = M + e
  }

  var En1 = En + (M - En + e * Math.sin(En)) / (1.0 - e * Math.cos(En))

  // now iterate to find true anomaly
  while (Math.abs(En1 - En) > tol) {
    En = En1
    En1 = En + (M - En + e * Math.sin(En)) / (1.0 - e * Math.cos(En))
  }
  var E = En1

  // now compute true anomaly from eccentric anomaly and give it in [0,2pi)
  let nu
  if (e > 1e-9) {
    var sinNu = (Math.sin(E) * Math.sqrt(1.0 - e)) / (1.0 - e * Math.cos(E))
    var cosNu = (Math.cos(E) - e) / (1.0 - e * Math.cos(E))
    nu = Math.atan2(sinNu, cosNu) % (2.0 * Math.PI)
  } else {
    nu = E % (2.0 * Math.PI)
  }

  return nu
}

// Convert true to mean anomaly
export function true2mean(nu, e) {
  // compute eccentric anomaly
  var sinE = (Math.sin(nu) * Math.sqrt(1.0 - e * e)) / (1.0 + e * Math.cos(nu))
  var cosE = (Math.cos(nu) + e) / (1.0 + e * Math.cos(nu))

  var E = Math.atan2(sinE, cosE)
  var M = E - e * Math.sin(E)

  return M
}

//Get cartesian coordinates of satellite in the orbit
export function getPositionVelocityAtTimeFromOrbitalElements(
  inTime,
  inOrbitalElements,
) {
  var rotationToAscendingNode,
    rotationOfInclination,
    rotationToPeriapsis,
    finalRotation,
    deltaTime,
    currentTrueAnomaly,
    currentMeanAnomaly

  rotationToAscendingNode = new three.Quaternion().setFromAxisAngle(
    new three.Vector3(0, 0, 1),
    inOrbitalElements.longitudeOfAscendingNode,
  )

  rotationOfInclination = new three.Quaternion().setFromAxisAngle(
    new three.Vector3(1, 0, 0),
    inOrbitalElements.inclination,
  )

  rotationToPeriapsis = new three.Quaternion().setFromAxisAngle(
    new three.Vector3(0, 0, 1),
    inOrbitalElements.argumentOfPeriapsis,
  )
  //https://en.wikipedia.org/wiki/Orbital_elements#Keplerian_elements
  finalRotation = rotationToAscendingNode
    .multiply(rotationOfInclination)
    .multiply(rotationToPeriapsis)

  deltaTime = (inTime - inOrbitalElements.epoch) / 1000

  //https://en.wikipedia.org/wiki/Mean_anomaly
  //https://en.wikipedia.org/wiki/True_anomaly
  currentMeanAnomaly =
    inOrbitalElements.meanAnomaly + deltaTime * inOrbitalElements.angularSpeed
  currentMeanAnomaly = currentMeanAnomaly % (2.0 * Math.PI)
  currentTrueAnomaly = mean2true(
    currentMeanAnomaly,
    inOrbitalElements.eccentricity,
  )

  var radius =
    (inOrbitalElements.semiMajorAxis *
      (1 - inOrbitalElements.eccentricity * inOrbitalElements.eccentricity)) /
    (1 + inOrbitalElements.eccentricity * Math.cos(currentTrueAnomaly))
  var direction = new three.Vector3(
    Math.cos(currentTrueAnomaly),
    Math.sin(currentTrueAnomaly),
    0,
  )
  var position = direction.clone().multiplyScalar(radius)

  var radiusPrime =
    (-inOrbitalElements.semiMajorAxis *
      (1 - inOrbitalElements.eccentricity * inOrbitalElements.eccentricity) *
      (-inOrbitalElements.eccentricity *
        Math.sin(currentTrueAnomaly) *
        inOrbitalElements.angularSpeed)) /
    Math.pow(
      1 + inOrbitalElements.eccentricity * Math.cos(currentTrueAnomaly),
      2,
    )
  var directionPrime = new three.Vector3(
    -Math.sin(currentTrueAnomaly) * inOrbitalElements.angularSpeed,
    Math.cos(currentTrueAnomaly) * inOrbitalElements.angularSpeed,
    0,
  )
  //ie positionPrime:
  var velocity = direction
    .clone()
    .multiplyScalar(radiusPrime)
    .add(directionPrime.clone().multiplyScalar(radius))

  return {
    position: position.applyQuaternion(finalRotation),
    velocity: velocity.applyQuaternion(finalRotation),
  }
}

// Converts ECI to geo referenced data
export function eciToGeo(eciCoords, time) {
  var t =
    367.0 * time.getUTCFullYear() -
    Math.floor(
      7 *
        (time.getUTCFullYear() +
          Math.floor((time.getUTCMonth() + 1 + 9) / 12.0)) *
        0.25,
    ) +
    Math.floor((275 * (time.getUTCMonth() + 1)) / 9.0) +
    time.getUTCDate() +
    1721013.5 +
    ((time.getUTCSeconds() / 60.0 + time.getUTCMinutes()) / 60.0 +
      time.getUTCHours()) /
      24.0

  var tut1 = (t - 2451545.0) / 36525.0
  var gmst =
    -6.2e-6 * tut1 * tut1 * tut1 +
    0.093104 * tut1 * tut1 +
    (876600.0 * 3600 + 8640184.812866) * tut1 +
    67310.54841 //#  sec

  gmst = ((gmst * (Math.PI / 180.0)) / 240.0) % (Math.PI * 2) // 360/86400 = 1/240, to deg, to rad

  //  ------------------------ check quadrants ---------------------
  if (gmst < 0.0) {
    gmst += Math.PI * 2
  }

  var a = 6378.137
  var b = 6356.7523142
  var R = Math.sqrt(eciCoords.x * eciCoords.x + eciCoords.y * eciCoords.y)
  var f = (a - b) / a
  var e2 = 2 * f - f * f
  var longitude =
    ((Math.atan2(eciCoords.y, eciCoords.x) - gmst) * 180) / Math.PI

  // Bring range from -180 to 180
  // reduce the angle
  longitude = longitude % 360

  // force it to be the positive remainder, so that 0 <= angle < 360
  longitude = (longitude + 360) % 360

  // force into the minimum absolute value residue class, so that -180 < angle <= 180
  if (longitude > 180) {
    longitude -= 360
  }
  var kmax = 20
  var k = 0
  var latitude = Math.atan2(
    eciCoords.z,
    Math.sqrt(eciCoords.x * eciCoords.x + eciCoords.y * eciCoords.y),
  )

  var C
  while (k < kmax) {
    C = 1 / Math.sqrt(1 - e2 * (Math.sin(latitude) * Math.sin(latitude)))
    latitude = Math.atan2(eciCoords.z + a * C * e2 * Math.sin(latitude), R)
    k += 1
  }
  var heightOrbit = R / Math.cos(latitude) - a * C

  latitude = (latitude * 180) / Math.PI
  return { longitude, latitude, heightOrbit }
}

export function getOrbits(data, reentryOrbitCollection) {
  var allSatelliteOrbits = []
  data.forEach(function (object) {
    var orbitPoints = []
    var orbitDataFound = false
    var orbitModel = reentryOrbitCollection.get(
      object[reentryColumns.COSPAR_ID],
    )
    if (orbitModel) {
      orbitPoints = orbitModel.get('orbitData')
    } else {
      // calculate orbit
      orbitPoints = calculateOrbit(object, reentryOrbitCollection)
    }

    // Split orbit to two lines to make it come back to left side if it crosses right side.
    var orbitPointsSplit = []
    for (var index = 0; index < orbitPoints.length; index++) {
      orbitPointsSplit.push({
        longitude: orbitPoints[index].longitude,
        latitude: orbitPoints[index].latitude,
      })
      if (
        index !== orbitPoints.length - 1 &&
        Math.abs(
          orbitPoints[index].longitude - orbitPoints[index + 1].longitude,
        ) > 180
      ) {
        allSatelliteOrbits.push({
          cosparId: object[reentryColumns.COSPAR_ID],
          values: orbitPointsSplit,
        })
        orbitPointsSplit = []
      }
    }
    allSatelliteOrbits.push({
      cosparId: object[reentryColumns.COSPAR_ID],
      values: orbitPointsSplit,
    })
  })
  return allSatelliteOrbits
}

export function calculateLoc(object, time) {
  var satRadiusOfEarth = 6371
  var satGravitationalConstant = 398600441800000
  var satSemiMajorAxis = object[keplerParams.SEMI_MAJOR_AXIS] * 1000 // in meters
  var satAngularSpeed = Math.sqrt(
    satGravitationalConstant /
      (satSemiMajorAxis * satSemiMajorAxis * satSemiMajorAxis),
  )
  var satEciCoords = getPositionVelocityAtTimeFromOrbitalElements(time, {
    longitudeOfAscendingNode:
      (object[keplerParams.LONGITUDE_OF_ASCENDING_NODE] * Math.PI) / 180,
    inclination: (object[keplerParams.INCLINATION] * Math.PI) / 180,
    argumentOfPeriapsis:
      (object[keplerParams.ARGUMENT_OF_PERIAPSIS] * Math.PI) / 180,
    epoch: object[keplerParams.EPOCH],
    trueAnomaly: (object[keplerParams.TRUE_ANOMALY] * Math.PI) / 180,
    angularSpeed: satAngularSpeed,
    semiMajorAxis: satSemiMajorAxis,
    eccentricity: object[keplerParams.ECCENTRICITY],
    meanAnomaly: true2mean(
      (object[keplerParams.TRUE_ANOMALY] * Math.PI) / 180,
      object[keplerParams.ECCENTRICITY],
    ),
  })

  return eciToGeo(satEciCoords.position, time)
}

export function getSatellites(data) {
  var satOrbitPoints = []
  data.forEach(function (object) {
    var satTime = new Date()
    var satGeoData = calculateLoc(object, satTime)

    satOrbitPoints.push({
      latitude: satGeoData.latitude,
      longitude: satGeoData.longitude,
      cosparId: object[reentryColumns.COSPAR_ID],
      objectClass: object[reentryColumns.CLASS],
    })
  })
  return satOrbitPoints
}

export function calculateOrbit(object, reentryOrbitCollection) {
  var orbitPoints = []
  var radiusOfEarth = 6371
  var gravitationalConstant = 398600441800000
  var semiMajorAxis =
    ((object[reentryColumns.APOGEE] +
      radiusOfEarth +
      object[reentryColumns.PERIGEE] +
      radiusOfEarth) /
      2.0) *
    1000 // in meters
  var orbitalTimePerCycle =
    2 *
    Math.PI *
    Math.sqrt(
      (semiMajorAxis * semiMajorAxis * semiMajorAxis) / gravitationalConstant,
    )
  var nbOrbitPoints = orbitalTimePerCycle / 30 //every 30 seconds
  var angularSpeed = Math.sqrt(
    gravitationalConstant / (semiMajorAxis * semiMajorAxis * semiMajorAxis),
  )
  var now = new Date()
  for (var iter = -(nbOrbitPoints / 2); iter <= nbOrbitPoints / 2; iter++) {
    var time = new Date(now.getTime() + iter * 30000)
    var geoData = calculateLoc(object, time)
    orbitPoints.push({
      longitude: geoData.longitude,
      latitude: geoData.latitude,
    })
  }
  reentryOrbitCollection.add({
    cosparId: object[reentryColumns.COSPAR_ID],
    orbitData: orbitPoints,
    object,
    time: new Date(now.getTime() + (orbitalTimePerCycle * 1000) / 4),
  })

  return orbitPoints
}

export function updateOrbits(reentryOrbitCollection) {
  var radiusOfEarth = 6371
  var gravitationalConstant = 398600441800000
  var modelChange = false

  reentryOrbitCollection.each(function (model) {
    var orbitPoints = []
    var object = model.get('object')
    var now = new Date()
    if (now.getTime() >= model.get('time').getTime()) {
      modelChange = true
      var semiMajorAxis =
        ((object[reentryColumns.APOGEE] +
          radiusOfEarth +
          object[reentryColumns.PERIGEE] +
          radiusOfEarth) /
          2.0) *
        1000 // in meters
      var orbitalTimePerCycle =
        2 *
        Math.PI *
        Math.sqrt(
          (semiMajorAxis * semiMajorAxis * semiMajorAxis) /
            gravitationalConstant,
        )
      var nbOrbitPoints = orbitalTimePerCycle / 30 //every 30 seconds
      var angularSpeed = Math.sqrt(
        gravitationalConstant / (semiMajorAxis * semiMajorAxis * semiMajorAxis),
      )
      for (var iter = -(nbOrbitPoints / 2); iter <= nbOrbitPoints / 2; iter++) {
        var time = new Date(now.getTime() + iter * 30000)
        var geoData = calculateLoc(object, time)
        orbitPoints.push({
          longitude: geoData.longitude,
          latitude: geoData.latitude,
        })
      }
      model.unset('orbitData')
      model.unset('time')
      model.set('orbitData', orbitPoints)
      model.set(
        'time',
        new Date(now.getTime() + (orbitalTimePerCycle * 1000) / 4),
      )
      reentryOrbitCollection.set(model, { remove: false })
    }
  })

  if (modelChange) {
    reentryOrbitCollection.trigger('change:orbit')
  }
}
