/*
	Model for detail files.
	Save file feature borrowed from - Backbone.Model File Upload v1.0.0 (https://github.com/homeslicesolutions/backbone-model-file-upload)
*/
import Backbone from 'backbone'

import _ from 'lodash'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

var _superClass = _.clone(Backbone.Model.prototype)

var DetailFilesModel = Backbone.Model.extend({
  urlRoot: '/api/protected/detailFiles',
  idAttribute: reentryColumns.COSPAR_ID,

  // ! Default file attribute - can be overwritten
  fileAttribute: 'file',

  // @ Save - overwritten
  save(key, val, options) {
    // Variables
    var attrs,
      attributes = this.attributes

    // Signature parsing - taken directly from original Backbone.Model.save
    // and it states: 'Handle both "key", value and {key: value} -style arguments.'
    if (key === null || typeof key === 'object') {
      attrs = key
      options = val
    } else {
      ;(attrs = {})[key] = val
    }

    // Validate & wait options - taken directly from original Backbone.Model.save
    options = _.extend({ validate: true }, options)
    if (attrs && !options.wait) {
      if (!this.set(attrs, options)) return false
    } else {
      if (!this._validate(attrs, options)) return false
    }

    // Merge data temporarily for formdata
    var mergedAttrs = _.extend({}, attributes, attrs)

    if (attrs && options.wait) {
      this.attributes = mergedAttrs
    }

    // Check for "formData" flag and check for if file exist.
    if (
      options.formData === true ||
      (options.formData !== false &&
        mergedAttrs[this.fileAttribute] &&
        mergedAttrs[this.fileAttribute] instanceof File) ||
      mergedAttrs[this.fileAttribute] instanceof FileList ||
      mergedAttrs[this.fileAttribute] instanceof Blob
    ) {
      // Converting Attributes to Form Data
      var formData = new FormData()
      _.each(mergedAttrs, function (value, key) {
        if (value instanceof FileList) {
          _.each(value, function (file) {
            formData.append(key, file)
          })
          return
        }
        formData.append(key, value)
      })

      // Set options for AJAX call
      options.data = formData
      options.processData = false
      options.contentType = false
    }

    // Resume back to original state
    if (attrs && options.wait) this.attributes = attributes

    // Continue to call the existing "save" method
    return _superClass.save.call(this, attrs, options)
  },
})

var DetailFilesCollection = Backbone.Collection.extend({
  model: DetailFilesModel,
  url() {
    return '/api/protected/detailFiles/' + this.cosparId
  },

  initialize(cosparId) {
    this.cosparId = cosparId
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

export default DetailFilesCollection
