/*
	Model to send restricted access reason to server
*/
import Backbone from 'backbone'

import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

var ReentryRequestRestrictedModel = Backbone.Model.extend({
  urlRoot: '/api/protected/reentryRestricted/request',
  idAttribute: reentryColumns.COSPAR_ID,
})

export default ReentryRequestRestrictedModel
