/*
	Model for detail file list filter.
	Stores filters currently selected.
*/

import Backbone from 'backbone'

var DetailFilesFilterModel = Backbone.Model.extend({})

export default DetailFilesFilterModel
