/*
	View to display list of users who have sent new messages in reentry and detail page.
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import AskAdminUserCollection from '../models/AskAdminUserCollection'
import { AskAdminMessagesView } from './AskAdminMessagesView'
import AskAdminMessagesCollection from '../models/AskAdminMessagesCollection'

export const AskAdminUserListView = Backbone.View.extend({
  template: require('../../templates/AskAdminUserList.hbs'),

  initialize(options) {
    console.log('Initialized Ask Admin User List View')

    this.cosparId = options.cosparId
    this.view = options.view
  },

  render(collection) {
    var markup

    markup = this.template({
      collection: collection.toJSON(),
      collectionLength: collection.toJSON().length,
      view: this.view,
    })
    this.$el.html(markup)

    return this
  },
})
