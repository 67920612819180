/*
	Model provids the necessary functionalities for reentry objects view
	e.g applying filters, sorting
*/
import Backbone from 'backbone'

import _ from 'lodash'
import ReentryDataCollection from './ReentryDataCollection'
import reentryFilterModel from './ReentryFilterModel'
import reentryFollowCollection from './ReentryFollowCollection'
import reentryRestrictedCollection from './ReentryRestrictedCollection'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'
import * as FilterFactory from '../factory/FilterFactory.js'
import whoAmI from '../models/WhoAmI'

const ReentryModel = Backbone.Model.extend({
  reentryDataCollection: null,

  objectClasses: null,

  fullCollectionFilterAttributes: {},

  initialize() {
    this.reentryDataCollection = new ReentryDataCollection({
      query: '',
      view: 'reentry',
    })
    this.displayCollection = new (Backbone.Collection.extend({
      model: Backbone.Model.extend({ idAttribute: 'messageId' }),
    }))()

    this.listenTo(reentryFilterModel, 'change', () => {
      console.log('react to reentryFilterModel change:filters')
      this.applyFilter()
    })

    this.listenTo(this.reentryDataCollection, 'sync add remove', () => {
      console.log(
        'reentryDataCollection fetched, populating display Collection',
      )
      this.reentryDataCollectionJSON = this.reentryDataCollection.toJSON()

      if (this.reentryDataCollectionJSON.length > 0) {
        // Append Followed Attribute
        this.appendIsFollowedAttribute()

        // Append Restricted Attribute
        this.appendIsRestrictedAttribute()
      }

      this.initalizeFilterAttributes()

      // Remove fragments by default
      reentryFilterModel.set(reentryColumns.CLASS, {
        checkbox: [
          'Payload Debris',
          'Rocket Debris',
          'Payload Fragmentation Debris',
          'Rocket Fragmentation Debris',
        ],
      })
    })

    this.listenTo(reentryFollowCollection, 'add remove', () => {
      // Append Followed Attribute
      this.appendIsFollowedAttribute()
      this.applyFilter()
    })
  },

  // initialize filter attributes on the main collection to keep list of all available options after filter applied.
  initalizeFilterAttributes() {
    var checkBoxObjectClass = {
      list: _.uniq(_.map(this.reentryDataCollectionJSON, reentryColumns.CLASS)),
    }

    var rangeObjectMass = {
      max: Math.ceil(
        _.max(_.map(this.reentryDataCollectionJSON, reentryColumns.MASS)),
      ),
      min: Math.floor(
        _.min(_.map(this.reentryDataCollectionJSON, reentryColumns.MASS)),
      ),
    }

    var rangeObjectInclination = {
      max: Math.ceil(
        _.max(
          _.map(this.reentryDataCollectionJSON, reentryColumns.INCLINATION),
        ),
      ),
      min: Math.floor(
        _.min(
          _.map(this.reentryDataCollectionJSON, reentryColumns.INCLINATION),
        ),
      ),
    }

    var rangeObjectPerigee = {
      max: Math.ceil(
        _.max(_.map(this.reentryDataCollectionJSON, reentryColumns.PERIGEE)),
      ),
      min: Math.floor(
        _.min(_.map(this.reentryDataCollectionJSON, reentryColumns.PERIGEE)),
      ),
    }

    var rangeObjectApogee = {
      max: Math.ceil(
        _.max(_.map(this.reentryDataCollectionJSON, reentryColumns.APOGEE)),
      ),
      min: Math.floor(
        _.min(_.map(this.reentryDataCollectionJSON, reentryColumns.APOGEE)),
      ),
    }

    var checkBoxAttributes = {}
    checkBoxAttributes[reentryColumns.CLASS] = checkBoxObjectClass

    var rangeAttributes = {}
    rangeAttributes[reentryColumns.MASS] = rangeObjectMass
    rangeAttributes[reentryColumns.INCLINATION] = rangeObjectInclination
    rangeAttributes[reentryColumns.PERIGEE] = rangeObjectPerigee
    rangeAttributes[reentryColumns.APOGEE] = rangeObjectApogee

    this.fullCollectionFilterAttributes = {
      checkBox: checkBoxAttributes,
      range: rangeAttributes,
    }
  },

  // add what events are followed
  appendIsFollowedAttribute() {
    var followedEvents = _.map(
      reentryFollowCollection.toJSON(),
      reentryColumns.COSPAR_ID,
    )
    for (var i = 0; i < this.reentryDataCollectionJSON.length; i++) {
      if (
        followedEvents.length > 0 &&
        followedEvents.includes(
          this.reentryDataCollectionJSON[i][reentryColumns.COSPAR_ID],
        )
      ) {
        this.reentryDataCollectionJSON[i][reentryColumns.IS_FOLLOWED] = true
      } else {
        this.reentryDataCollectionJSON[i][reentryColumns.IS_FOLLOWED] = false
      }
    }
  },

  // add what events have restricted access
  appendIsRestrictedAttribute() {
    var restrictedEvents = _.map(
      reentryRestrictedCollection.toJSON(),
      reentryColumns.COSPAR_ID,
    )
    for (var i = 0; i < this.reentryDataCollectionJSON.length; i++) {
      if (
        whoAmI.get('isPrivilegedAll') ||
        restrictedEvents.includes(
          this.reentryDataCollectionJSON[i][reentryColumns.COSPAR_ID],
        )
      ) {
        this.reentryDataCollectionJSON[i][reentryColumns.IS_RESTRICTED] = true
      } else {
        this.reentryDataCollectionJSON[i][reentryColumns.IS_RESTRICTED] = false
      }
    }
  },

  applyFilter() {
    let filteredItems = this.reentryDataCollectionJSON

    _.forEach(reentryFilterModel.toJSON(), (value, key) => {
      if (
        key !== 'sort' &&
        key !== 'followedFilter' &&
        key !== 'restrictedFilter' &&
        ((value.hasOwnProperty('text') && value.text !== '') ||
          !value.hasOwnProperty('text'))
      ) {
        filteredItems = _.filter(filteredItems, (item) => {
          let field = key
          if (key.split('-')[0] === reentryColumns.REENTRY_DATE) {
            field = reentryColumns.REENTRY_DATE
          }
          if (FilterFactory.applyFilter(value, item[field])) {
            return item
          }
        })
      }
    })

    if (reentryFilterModel.has('sort')) {
      const itemClicked = reentryFilterModel.get('sort').split('|')
      if (itemClicked[0] === 'asc') {
        filteredItems = this.sortAscending(itemClicked[1], filteredItems)
      } else {
        filteredItems = this.sortDescending(itemClicked[1], filteredItems)
      }
    }

    if (reentryFilterModel.has('followedFilter')) {
      filteredItems = _.filter(filteredItems, reentryColumns.IS_FOLLOWED)
    }

    if (reentryFilterModel.has('restrictedFilter')) {
      filteredItems = _.filter(filteredItems, reentryColumns.IS_RESTRICTED)
    }
    this.displayCollection.reset(filteredItems)
  },

  validateItemWithFilter(item, filterKey) {
    var filterValue = reentryFilterModel.attributes[filterKey]
    if (filterValue.hasOwnProperty('text')) {
      if (item[filterKey].toString().startsWith(filterValue.text)) {
        return true
      } else {
        return false
      }
    }
  },

  /**
   * Return a map of category names to icons. The names are only known at
   * runtime, but there are 10 different categories in DISCOS at time of
   * writing.
   * @param categories
   */
  getIconMap(categories) {
    const remaining = new Set(categories)

    // start with predetermined icon(s) for specific cases
    const categoryIcons = {
      Unknown: { name: 'question-circle', char: '\uf059' },
      'Rocket Body': { name: 'rocket', char: '\uf135' },
    }
    Object.keys(categoryIcons).forEach((category) => remaining.delete(category))

    // assign icons to categories, wrapping if we run out
    const usableIcons = [
      { name: 'circle', char: '\uf111' },
      { name: 'plus', char: '\uf067' },
      { name: 'star', char: '\uf005' },
      { name: 'square', char: '\uf0c8' },
      { name: 'asterisk', char: '\uf069' },
      { name: 'map-marker', char: '\uf041' },
      { name: 'play', char: '\uf04b' },
      { name: 'certificate', char: '\uf0a3' },
    ]

    categories
      .filter((c) => remaining.has(c))
      .forEach((category, idx) => {
        remaining.delete(category)
        categoryIcons[category] = usableIcons[idx % usableIcons.length]
      })

    return categoryIcons
  },

  getCheckList(checkListField) {
    const displayResult = _.uniq(
      _.map(this.displayCollection.toJSON(), checkListField),
    )
    let returnResult = []

    if (this.fullCollectionFilterAttributes.hasOwnProperty('checkBox')) {
      const categories =
        this.fullCollectionFilterAttributes.checkBox[checkListField].list

      const iconMap = this.getIconMap(categories)

      returnResult = categories.map((category) => ({
        value: category,
        isChecked: _.includes(displayResult, category),
        icon: iconMap[category].name,
      }))
      returnResult = _.sortBy(returnResult, 'value')
    }
    return returnResult
  },

  getMaxValue(rangeField) {
    var result =
      Math.ceil(
        _.max(_.map(this.displayCollection.toJSON(), rangeField)) * 100,
      ) / 100
    return result
  },

  getMinValue(rangeField) {
    var result =
      Math.floor(
        _.min(_.map(this.displayCollection.toJSON(), rangeField)) * 100,
      ) / 100
    return result
  },

  getMaxRange(rangeField) {
    return this.fullCollectionFilterAttributes.hasOwnProperty('range')
      ? this.fullCollectionFilterAttributes.range[rangeField].max
      : ''
  },

  getMinRange(rangeField) {
    return this.fullCollectionFilterAttributes.hasOwnProperty('range')
      ? this.fullCollectionFilterAttributes.range[rangeField].min
      : ''
  },

  sortAscending(field, filteredItems) {
    return _.chain(filteredItems).sortBy(field).value()
  },

  sortDescending(field, filteredItems) {
    return _.chain(filteredItems).sortBy(field).reverse().value()
  },

  getDisplayCollection() {
    return this.displayCollection
  },

  getReentryDataCollection() {
    return this.reentryDataCollection
  },

  getReentryDataByEvent(cosparId) {
    var filterObject = {}
    filterObject[reentryColumns.COSPAR_ID] = Number(cosparId)
    return _.head(_.filter(this.displayCollection.toJSON(), filterObject))
  },

  getAllClasses() {
    return this.fullCollectionFilterAttributes.checkBox[reentryColumns.CLASS]
      .list
  },
})

var reentryModel = new ReentryModel()

// make it a singleton
export default reentryModel
