var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<th class=\"col-size-events-flag\"></th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"COSPAR_ID") || (depth0 != null ? lookupProperty(depth0,"COSPAR_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"COSPAR_ID","hash":{},"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":59}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"COSPAR_ID") || (depth0 != null ? lookupProperty(depth0,"COSPAR_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"COSPAR_ID","hash":{},"data":data,"loc":{"start":{"line":7,"column":65},"end":{"line":7,"column":78}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"International designator\">COSPAR ID</span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"SATNO") || (depth0 != null ? lookupProperty(depth0,"SATNO") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SATNO","hash":{},"data":data,"loc":{"start":{"line":12,"column":46},"end":{"line":12,"column":55}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SATNO") || (depth0 != null ? lookupProperty(depth0,"SATNO") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SATNO","hash":{},"data":data,"loc":{"start":{"line":12,"column":61},"end":{"line":12,"column":70}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Satellite catalogue number\">SATNO</span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":54}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":17,"column":60},"end":{"line":17,"column":68}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Re-entry object name\">Name</span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"REENTRY_DATE") || (depth0 != null ? lookupProperty(depth0,"REENTRY_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"REENTRY_DATE","hash":{},"data":data,"loc":{"start":{"line":22,"column":46},"end":{"line":22,"column":62}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"REENTRY_DATE") || (depth0 != null ? lookupProperty(depth0,"REENTRY_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"REENTRY_DATE","hash":{},"data":data,"loc":{"start":{"line":22,"column":68},"end":{"line":22,"column":84}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"(Predicted) Reentry date\">Reentry Date</span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"UNCERTAINTY") || (depth0 != null ? lookupProperty(depth0,"UNCERTAINTY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UNCERTAINTY","hash":{},"data":data,"loc":{"start":{"line":27,"column":46},"end":{"line":27,"column":61}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UNCERTAINTY") || (depth0 != null ? lookupProperty(depth0,"UNCERTAINTY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UNCERTAINTY","hash":{},"data":data,"loc":{"start":{"line":27,"column":67},"end":{"line":27,"column":82}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Uncertainty\">Uncertainty<sub>day</sub></span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"MASS") || (depth0 != null ? lookupProperty(depth0,"MASS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MASS","hash":{},"data":data,"loc":{"start":{"line":32,"column":46},"end":{"line":32,"column":54}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"MASS") || (depth0 != null ? lookupProperty(depth0,"MASS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MASS","hash":{},"data":data,"loc":{"start":{"line":32,"column":60},"end":{"line":32,"column":68}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Object's mass\">Mass<sub>kg</sub></span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"CLASS") || (depth0 != null ? lookupProperty(depth0,"CLASS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CLASS","hash":{},"data":data,"loc":{"start":{"line":37,"column":46},"end":{"line":37,"column":55}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CLASS") || (depth0 != null ? lookupProperty(depth0,"CLASS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CLASS","hash":{},"data":data,"loc":{"start":{"line":37,"column":61},"end":{"line":37,"column":70}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Object's class\">Class</span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"INCLINATION") || (depth0 != null ? lookupProperty(depth0,"INCLINATION") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INCLINATION","hash":{},"data":data,"loc":{"start":{"line":42,"column":46},"end":{"line":42,"column":61}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"INCLINATION") || (depth0 != null ? lookupProperty(depth0,"INCLINATION") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INCLINATION","hash":{},"data":data,"loc":{"start":{"line":42,"column":67},"end":{"line":42,"column":82}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Object's inclination\">Inclination<sub>deg</sub></span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"PERIGEE") || (depth0 != null ? lookupProperty(depth0,"PERIGEE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PERIGEE","hash":{},"data":data,"loc":{"start":{"line":47,"column":46},"end":{"line":47,"column":57}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"PERIGEE") || (depth0 != null ? lookupProperty(depth0,"PERIGEE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PERIGEE","hash":{},"data":data,"loc":{"start":{"line":47,"column":63},"end":{"line":47,"column":74}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Object's perigee height\">Perigee<sub>km</sub></span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"APOGEE") || (depth0 != null ? lookupProperty(depth0,"APOGEE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"APOGEE","hash":{},"data":data,"loc":{"start":{"line":52,"column":46},"end":{"line":52,"column":56}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"APOGEE") || (depth0 != null ? lookupProperty(depth0,"APOGEE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"APOGEE","hash":{},"data":data,"loc":{"start":{"line":52,"column":62},"end":{"line":52,"column":72}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Object's apogee height\">Apogee<sub>km</sub></span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOOL") || (depth0 != null ? lookupProperty(depth0,"TOOL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOOL","hash":{},"data":data,"loc":{"start":{"line":57,"column":46},"end":{"line":57,"column":54}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"TOOL") || (depth0 != null ? lookupProperty(depth0,"TOOL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOOL","hash":{},"data":data,"loc":{"start":{"line":57,"column":60},"end":{"line":57,"column":68}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Tool used to generate automated prediction\">Tool</span>\n					</div>\n				</th>\n				<th class=\"col-size-events\">\n					<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"COUNTRY") || (depth0 != null ? lookupProperty(depth0,"COUNTRY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"COUNTRY","hash":{},"data":data,"loc":{"start":{"line":62,"column":46},"end":{"line":62,"column":57}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"COUNTRY") || (depth0 != null ? lookupProperty(depth0,"COUNTRY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"COUNTRY","hash":{},"data":data,"loc":{"start":{"line":62,"column":63},"end":{"line":62,"column":74}}}) : helper)))
    + "\">\n						<span class=\"word-hyphenate\" title=\"Launching state of object\">Country</span>\n					</div>\n				</th>\n				<th class=\"col-size-events-icon\"><span title=\"\"></span></th>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"collection") : depths[1]),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":3},"end":{"line":100,"column":12}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<tr class=\"col-size-row\">\n					<td class=\"col-size-events-flag\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFollowed") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":6},"end":{"line":77,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRestricted") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":6},"end":{"line":80,"column":13}}})) != null ? stack1 : "")
    + "					</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":82,"column":48},"end":{"line":82,"column":76}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"SATNO") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":83,"column":48},"end":{"line":83,"column":72}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":84,"column":48},"end":{"line":84,"column":71}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"REENTRY_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":85,"column":48},"end":{"line":85,"column":100}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"UNCERTAINTY") : depths[1]),{"name":"chain","hash":{"format":"%.1f"},"data":data,"loc":{"start":{"line":86,"column":48},"end":{"line":86,"column":115}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"MASS") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":87,"column":48},"end":{"line":87,"column":108}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"CLASS") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":88,"column":48},"end":{"line":88,"column":72}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"INCLINATION") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":89,"column":48},"end":{"line":89,"column":115}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"PERIGEE") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":90,"column":48},"end":{"line":90,"column":111}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"APOGEE") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":91,"column":48},"end":{"line":91,"column":110}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"TOOL") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":92,"column":48},"end":{"line":92,"column":71}}}))
    + "</td>\n					<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"COUNTRY") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":93,"column":48},"end":{"line":93,"column":74}}}))
    + "</td>\n					<td class=\"col-size-events-icon word-hyphenate\">\n						<button class=\"btn btn-default btn-xs viewDetails\" id=\""
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":95,"column":61},"end":{"line":95,"column":89}}}))
    + "\" type=\"button\" title=\"Click to view details of this object\">\n							<span class=\"glyphicon glyphicon-new-window\"></span>\n						</button>\n					</td>\n				</tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "								<span class=\"fa fa-star\" title=\"Event Followed\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "								<span class=\"fa fa-unlock-alt\" title=\"Restricted Access\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-striped table-hover head-fixed\">\n	<thead>\n		<tr class=\"col-size-row\" style=\"border-bottom-style: solid\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"reentryColumns") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":67,"column":12}}})) != null ? stack1 : "")
    + "		</tr>\n	</thead>\n	<tbody style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":70,"column":22},"end":{"line":70,"column":35}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"reentryColumns") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":2},"end":{"line":101,"column":11}}})) != null ? stack1 : "")
    + "	</tbody>\n</table>\n";
},"useData":true,"useDepths":true});