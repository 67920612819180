/*
	Model/collection to fetch/update user settings from admin page
*/
import Backbone from 'backbone'

const AdminSettingsModel = Backbone.Model.extend({
  urlRoot: '/api/protected/settings/adminSettings',
  idAttribute: 'userId',
})

const AdminSettingsCollection = Backbone.Collection.extend({
  model: AdminSettingsModel,
  url() {
    return '/api/protected/settings/adminSettings' + this.userId + this.cosparId
  },

  initialize(options) {
    this.userId = '?userId=' + options.userId
    this.cosparId = '&cosparId=' + options.cosparId
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

export default AdminSettingsCollection
