/*
	Model/collection to get list of users who sent new messages
*/
import Backbone from 'backbone'

var AskAdminUserModel = Backbone.Model.extend({
  urlRoot: '/api/protected/askAdmin/messageThreads',
  idAttribute: 'userId',
})

var AskAdminUserCollection = Backbone.Collection.extend({
  model: AskAdminUserModel,
  url() {
    let url = '/api/protected/askAdmin/messageThreads'
    if (this.cosparId !== null) {
      url += `?cosparId=${this.cosparId}`
    }
    return url
  },

  initialize(options) {
    this.cosparId = options.cosparId
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

export default AskAdminUserCollection
