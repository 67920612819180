var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<button type=\"button\" class=\"btn btn-default dropdown-toggle selected\" disabled=\"disabled\" data-toggle=\"dropdown\">\n				"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"selector") || (depth0 != null ? lookupProperty(depth0,"selector") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"selector","hash":{},"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":16}}}) : helper)))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn btn-default dropdown-toggle selected\" data-toggle=\"dropdown\">>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "					<li><a class=\"selection clickable\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"filter-input selector-dropdown\">\n	<label for=\"field-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":19},"end":{"line":2,"column":25}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":38}}}) : helper))) != null ? stack1 : "")
    + "</label>\n	<div class=\"input-group\">\n		<div class=\"input-group-btn\">\n			<div class=\"btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selector") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":3},"end":{"line":11,"column":10}}})) != null ? stack1 : "")
    + "				</button>\n				<ul class=\"dropdown-menu\" role=\"menu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectors") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "				</ul>\n			</div>\n		</div>\n		<input id=\"field-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":19},"end":{"line":20,"column":25}}}) : helper)))
    + "\" type=\"text\" class=\"form-control value field\">\n		<div class=\"input-group-btn\">\n			<button type=\"button\" class=\"btn btn-default remove-value\">\n				<span class=\"glyphicon glyphicon-remove\"></span>\n			</button>\n		</div>\n	</div>\n	<br>\n</div>\n";
},"useData":true});