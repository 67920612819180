/*
	Model provids the necessary functionalities for reentry objects view
	e.g applying filters, sorting
*/
import Backbone from 'backbone'

import _ from 'lodash'
import UserCollection from './UserCollection'
import adminUserFilterModel from './AdminUserFilterModel'
import adminEventFilterModel from './AdminEventFilterModel'
import reentryModel from './ReentryModel'
import * as FilterFactory from '../factory/FilterFactory.js'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

var AdminModel = Backbone.Model.extend({
  userCollection: null,

  eventCollection: null,

  initialize() {
    this.userCollection = new UserCollection()
    this.displayUserCollection = new (Backbone.Collection.extend({
      model: Backbone.Model.extend({ idAttribute: 'email' }),
    }))()

    this.eventCollection = reentryModel.getReentryDataCollection()
    this.displayEventCollection = new (Backbone.Collection.extend({
      model: Backbone.Model.extend({ idAttribute: reentryColumns.COSPAR_ID }),
    }))()

    this.listenTo(
      this.userCollection,
      'sync add',
      function (e) {
        this.displayUserCollection.reset(this.userCollection.toJSON())
      },
      this,
    )

    this.listenTo(this.eventCollection, 'reset', function (e) {
      this.displayEventCollection.reset(this.eventCollection.toJSON())
    })

    this.listenTo(
      adminUserFilterModel,
      'change',
      function () {
        this.applyFilter('user')
      },
      this,
    )

    this.listenTo(
      adminEventFilterModel,
      'change',
      function () {
        this.applyFilter('event')
      },
      this,
    )
  },

  applyFilter(type) {
    let filteredItems = this.userCollection.toJSON()
    var model = adminUserFilterModel
    if (type === 'event') {
      filteredItems = this.eventCollection.toJSON()
      model = adminEventFilterModel
    }

    _.keys(model.toJSON()).forEach(function (key) {
      if (
        (model.toJSON()[key].hasOwnProperty('text') &&
          model.toJSON()[key].text !== '') ||
        !model.toJSON()[key].hasOwnProperty('text')
      ) {
        filteredItems = _.filter(filteredItems, (item) => {
          var colKey = key === 'domain' ? 'email' : key
          var colValue =
            key === 'domain' ? item[colKey].split('@')[1] : item[colKey]
          if (FilterFactory.applyFilter(model.toJSON()[key], colValue)) {
            return item
          }
        })
      }
    })

    if (type === 'event') {
      this.displayEventCollection.reset(filteredItems)
    } else {
      this.displayUserCollection.reset(filteredItems)
    }
  },

  getUserDomainCheckBoxList() {
    var userDomainList = Object.keys(
      _.groupBy(this.userCollection.toJSON(), function (item) {
        return item.email.split('@')[1]
      }),
    )

    var displayUserDomainList = Object.keys(
      _.groupBy(this.displayUserCollection.toJSON(), function (item) {
        return item.email.split('@')[1]
      }),
    )

    var returnResult = []
    for (var i = 0; i < userDomainList.length; i++) {
      if (displayUserDomainList.indexOf(userDomainList[i]) > -1) {
        returnResult.push({ value: userDomainList[i], isChecked: true })
      } else {
        returnResult.push({ value: userDomainList[i], isChecked: false })
      }
    }
    return returnResult
  },

  getFileTypes() {
    var fileTypes = _.uniq(_.map(this.detailFilesCollection.toJSON(), 'type'))
    var displayFileTypes = _.uniq(
      _.map(this.displayDetailFilesCollection.toJSON(), 'type'),
    )
  },

  getUserCollection() {
    return this.userCollection
  },

  getDisplayUserCollection() {
    return this.displayUserCollection
  },

  getDisplayEventCollection() {
    return this.displayEventCollection
  },
})

var adminModel = new AdminModel()

// make it a singleton
export default adminModel
