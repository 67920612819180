/*
	Model/collection to fetch reentry objects from server.
*/
import Backbone from 'backbone'

const ReentryDataModel = Backbone.Model.extend({
  urlRoot: '/api/protected/reentries',
  idAttribute: 'messageId',
})

const ReentryDataCollection = Backbone.Collection.extend({
  model: ReentryDataModel,
  url() {
    const baseUrl =
      this.view === 'detail'
        ? '/api/protected/reentries/detail'
        : '/api/protected/reentries'
    return baseUrl + '?size=' + this.size + '&query=' + this.query
  },

  initialize(options) {
    this.size = 1000000
    this.query = options.query
    this.view = options.view
    this.fetch({ reset: true })
  },

  parse(response) {
    return response.content
  },
})

export default ReentryDataCollection
