var doctype =
  '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">'

var canvas = document.createElement('canvas')

var styles = function styles(dom) {
  var used = ''
  var sheets = document.styleSheets
  for (var i = 0; i < sheets.length; i++) {
    var rules = sheets[i].cssRules
    if (rules !== null) {
      for (var j = 0; j < rules.length; j++) {
        var rule = rules[j]
        if (typeof rule.style !== 'undefined') {
          var elems = dom.querySelectorAll(rule.selectorText)
          if (elems.length > 0) {
            used += rule.selectorText + ' { ' + rule.style.cssText + ' }\n'
          }
        }
      }
    }
  }

  var s = document.createElement('style')
  s.setAttribute('type', 'text/css')
  s.innerHTML = '<![CDATA[\n' + used + '\n]]>'

  var defs = document.createElement('defs')
  defs.appendChild(s)
  return defs
}

var svgAsDataUri = (module.exports.svgAsDataUri = function (el, scaleFactor) {
  scaleFactor = scaleFactor || 1

  var outer = document.createElement('div')
  var clone = el.cloneNode(true)

  var width = parseInt(
    clone.getAttribute('width') ||
      clone.style.width ||
      this.getComputedStyle(el).getPropertyValue('width'),
  )

  var height = parseInt(
    clone.getAttribute('height') ||
      clone.style.height ||
      this.getComputedStyle(el).getPropertyValue('height'),
  )

  var xmlns = 'http://www.w3.org/2000/xmlns/'

  clone.setAttribute('version', '1.1')
  clone.setAttributeNS(xmlns, 'xmlns', 'http://www.w3.org/2000/svg')
  clone.setAttributeNS(xmlns, 'xmlns:xlink', 'http://www.w3.org/1999/xlink')
  clone.setAttribute('width', width * scaleFactor)
  clone.setAttribute('height', height * scaleFactor)
  clone.setAttribute('viewBox', '0 0 ' + width + ' ' + height)

  outer.appendChild(clone)

  clone.insertBefore(styles(clone), clone.firstChild)

  var svg = doctype + outer.innerHTML
  var uri =
    'data:image/svg+xml;base64,' +
    window.btoa(unescape(encodeURIComponent(svg)))

  return uri
})

module.exports.svgAsPng = function svgAsPng(el, scaleFactor) {
  var dataUri = svgAsDataUri(el, scaleFactor)
  var image = document.createElement('img')
  image.src = dataUri

  canvas.width = image.width
  canvas.height = image.height

  var context = canvas.getContext('2d')
  context.drawImage(image, 0, 0)

  var pngUri = canvas.toDataURL('image/png')

  return pngUri
}
