/*
	Displays the reentry objects in a table.
*/
import Backbone from 'backbone'

import $ from 'jquery'
import d3 from 'd3'
import moment from 'moment'
import * as topojson from 'topojson'

import 'd3-geo-projection/d3.geo.projection'

import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'
import { keplerParams } from '../../../common/config/constants'
import reentryModel from '../models/ReentryModel'
import reentryFilterModel from '../models/ReentryFilterModel'
import reentryOrbitCollection from '../models/ReentryOrbitCollection'
import * as Orbit from '../helpers/Orbit.js'

function getViewFromHash() {
  let view = window.location.hash.split('_')[1] || 'table'
  if (!['table', 'gantt', 'live'].includes(view)) {
    view = 'table'
  }
  return view
}

export const ReentryCollectionView = Backbone.View.extend({
  template: require('../../templates/ReentryCollectionView.hbs'),
  tableTemplate: require('../../templates/ReentryTableView.hbs'),
  ganttTemplate: require('../../templates/ReentryGanttView.hbs'),
  liveTemplate: require('../../templates/ReentryLiveView.hbs'),

  isProtected: false,

  mapLoaded: false,

  renderedOnceDataPopulated: false,

  // Collection containing reentry objects to be displayed
  reentryDataCollection: null,

  // The current sub-view. Tabular/Gantt/Live
  currentView: getViewFromHash(),

  // Sort Id which was clicked
  sortIdClicked: null,

  dataPopulated: false,

  // Previous sort id which was clicked.
  // If the current sort id clicked matches previous one,
  // List needs to be sorted in descending order based on sort id
  previousSortIdClicked: null,

  orbits: [],

  initialize(options) {
    console.log('Initialized Reentry Collection View')

    this.router = options.router

    this.reentryDataCollection = reentryModel.getDisplayCollection()

    this.divHeight = options.divHeight - 50

    this.listenTo(this.reentryDataCollection, 'reset', () => {
      this.reentryDataCollectionJSON = this.reentryDataCollection.toJSON()
      this.dataPopulated = true
      this.render()
    })

    this.listenTo(reentryOrbitCollection, 'change:orbit', () => {
      if (this.currentView === 'live' && this.dataPopulated) {
        this.renderLiveView(this.reentryDataCollection.toJSON())
      }
    })
  },

  events: {
    'click .reentryViewType': 'switchView',
    'click button.viewDetails': 'viewDetails',
    'click .sort': 'sort',
    'click .focusDot': 'viewDetails',
    'click .satOrbitPin': 'viewDetails',
    'click .reentry_tab': 'openTab',
  },

  render() {
    if (!this.dataPopulated || !this.renderedOnceDataPopulated) {
      const markup = this.template({
        dataPopulated: this.dataPopulated,
      })
      this.$el.html(markup)
      if (this.dataPopulated) {
        this.renderedOnceDataPopulated = true
      }
    }

    if (this.currentView === 'table' && this.dataPopulated) {
      this.renderTable(this.reentryDataCollection.toJSON())
    } else if (this.currentView === 'gantt' && this.dataPopulated) {
      this.renderGanttChart(
        this.reentryDataCollection.toJSON(),
        this.$el.width(),
      )
    } else if (this.currentView === 'live' && this.dataPopulated) {
      if (this.mapLoaded) {
        this.renderLiveView(this.reentryDataCollection.toJSON())
      } else {
        this.loadMap(this.reentryDataCollection.toJSON(), this.$el.width())
      }
    }
    // Go to correct sub view
    const view = getViewFromHash()
    $('ul.nav a[href="reentry_' + view + '"]').tab('show')
    return this
  },

  // render table tab
  renderTable(collection) {
    var markup = this.tableTemplate({
      collection,
      divHeight: this.divHeight - 55 + 'px',
      reentryColumns,
    })

    if (this.el.querySelector('.reentryTable')) {
      this.$el.find('.reentryTable').remove()
    }

    var divReentryTable = document.createElement('div')
    divReentryTable.className = 'reentryTable'
    divReentryTable.innerHTML = markup

    this.el
      .querySelector('.reentryCollectionTable')
      .appendChild(divReentryTable)

    // If a column sorted, add drop up, drop down icon
    if (this.sortIdClicked !== null) {
      var span = document.createElement('span')
      if (this.sortIdClicked === this.previousSortIdClicked) {
        span.className = 'dropup'
      } else {
        span.className = 'dropDown'
      }
      console.log('Span', span.className)
      var spanChild = document.createElement('span')
      spanChild.className = 'caret'
      span.appendChild(spanChild)
      this.el
        .querySelector('.addSortButton-' + this.sortIdClicked)
        .appendChild(span)
    }
  },

  //render gantt chart tab
  renderGanttChart(collection, divWidth) {
    var margin = { top: 10, right: 10, bottom: 140, left: 120 },
      marginContextX = {
        top: this.divHeight - margin.bottom + 40,
        right: margin.right,
        bottom: 30,
        left: margin.left,
      },
      width = divWidth - margin.left - margin.right,
      height = this.divHeight - margin.top - margin.bottom,
      heightContextX = 100

    var markup = this.ganttTemplate({
      height: height + 5 + 'px',
    })

    if (this.el.querySelector('.reentryGanttChartArea')) {
      this.$el.find('.reentryGanttChartArea').remove()
    }

    var divReentryGantt = document.createElement('div')
    divReentryGantt.className = 'reentryGanttChartArea'
    divReentryGantt.innerHTML = markup

    this.el
      .querySelector('.reentryCollectionGantt')
      .appendChild(divReentryGantt)

    this.data = collection

    var formatDate = d3.time.format('%x')

    // make data
    for (var i = 0; i < this.data.length; ++i) {
      var reentryDate = Number(this.data[i][reentryColumns.REENTRY_DATE])
      var creationDate = Number(this.data[i][reentryColumns.CREATION_DATE])
      var uncertaintyWindow = Number(this.data[i][reentryColumns.UNCERTAINTY])
      var oneWeekInMs = 604800000
      var oneDayInMs = 86400000

      this.data[i].x = moment.utc(reentryDate).toDate()
      this.data[i].y = this.data[i][reentryColumns.COSPAR_ID]
      this.data[i].uncertainty_low = moment
        .utc(reentryDate - uncertaintyWindow * oneDayInMs)
        .toDate()
      this.data[i].uncertainty_high = moment
        .utc(reentryDate + uncertaintyWindow * oneDayInMs)
        .toDate()
    }

    var focusHeight =
      height > (this.data.length + 1) * 18
        ? height
        : (this.data.length + 1) * 18

    var x = d3.time.scale.utc().range([0, width]),
      xContextX = d3.time.scale.utc().range([0, width]),
      y = d3.scale.ordinal().rangePoints([focusHeight, 0]),
      yContextX = d3.scale.linear().range([heightContextX, 0])

    var xAxis = d3.svg.axis().scale(x).orient('bottom'),
      xAxisContextX = d3.svg.axis().scale(xContextX).orient('bottom'),
      yAxis = d3.svg.axis().scale(y).orient('left'),
      yAxisContextX = d3.svg.axis().scale(yContextX).orient('left')

    var brushContextX = d3.svg.brush().x(xContextX).on('brush', brushedContextX)

    var xAxisSvg = d3
      .select('.xAxisFocus')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', 20)

    var focusSvg = d3
      .select(this.el.querySelector('.focusArea'))
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', focusHeight)
      .append('g')
      .attr('transform', 'translate(0,0)')

    var contextSvg = d3
      .select('.context')
      .append('svg')
      .attr('width', width + marginContextX.left + marginContextX.right)
      .attr('height', heightContextX)

    focusSvg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', width)
      .attr('height', height + 10)
      .attr('y', -10)

    // main view of gantt chart
    var focus = focusSvg
      .append('g')
      .attr('class', 'focus')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    // brush view of gantt chart
    var contextX = contextSvg
      .append('g')
      .attr('class', 'contextX')
      .attr('transform', 'translate(' + marginContextX.left + ',10)')

    var currentDate = moment.utc().toDate()
    var xMin = d3.min(this.data, function (d) {
      return d.uncertainty_low
    })
    var xMax = d3.max(this.data, function (d) {
      return d.uncertainty_high
    })
    x.domain([xMin, xMax])
    xContextX.domain(x.domain())

    var yDomain = this.data.map(function (d) {
      return d.y
    })
    yDomain.splice(0, 0, '')
    y.domain(yDomain)
    yContextX.domain([0, 1])

    focus.append('path').datum(this.data).attr('clip-path', 'url(#clip)')

    var drawAreafocus = focus.append('g').attr('clip-path', 'url(#clip)')

    var focusTodayLine = makeTodayLine(
      drawAreafocus,
      this.data,
      currentDate,
      y.domain(),
      'focusTodayLine',
      true,
    )
    var focusUncertaintyGroup = makeUncertaintyGroup(
      drawAreafocus,
      this.data,
      'focusUncertaintyLine',
      true,
    )
    var focusCircleGroup = makeCircleGroup(
      drawAreafocus,
      this.data,
      7,
      null,
      null,
      'focusDot',
      true,
    )

    xAxisSvg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(' + margin.left + ',0)')
      .call(xAxis)

    focus.append('g').attr('class', 'y axis').call(yAxis)

    contextX.append('path').datum(this.data).attr('class', 'symbol')

    contextX
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,50)')
      .call(xAxisContextX)

    contextX
      .append('g')
      .attr('class', 'x brush')
      .call(brushContextX)
      .selectAll('rect')
      .attr('y', -6)
      .attr('height', 57)

    var contextXCirclegroup = makeCircleGroup(
      contextX,
      this.data,
      3,
      0.5,
      null,
      'contextDotX',
      false,
    )
    var contextXTodayLine = makeTodayLine(
      contextX,
      this.data,
      currentDate,
      yContextX.domain(),
      'contextTodayLine',
      false,
    )

    function brushedContextX() {
      x.domain(
        brushContextX.empty() ? xContextX.domain() : brushContextX.extent(),
      )
      focusCircleGroup
        .selectAll('.focusDot')
        .attr('cx', function (d) {
          return x(d.x)
        })
        .attr('cy', function (d) {
          return y(d.y)
        })
      focusUncertaintyGroup
        .selectAll('.focusUncertaintyLine')
        .attr('x1', function (d) {
          return x(d.uncertainty_low)
        })
        .attr('y1', function (d) {
          return y(d.y)
        })
        .attr('x2', function (d) {
          return x(d.uncertainty_high)
        })
        .attr('y2', function (d) {
          return y(d.y)
        })
      focusTodayLine
        .selectAll('.focusTodayLine')
        .attr('x1', function () {
          return x(currentDate)
        })
        .attr('y1', function () {
          return y('')
        })
        .attr('x2', function () {
          return x(currentDate)
        })
        .attr('y2', function () {
          return y(y.domain()[y.domain().length - 1])
        })
      xAxisSvg.select('.x.axis').call(xAxis)
    }

    function makeCircleGroup(
      view,
      data,
      radius,
      yVal,
      xVal,
      classAttr,
      isFocus,
    ) {
      var circleGroup = view.append('g')
      circleGroup
        .selectAll('.' + classAttr)
        .data(data)
        .enter()
        .append('circle')
        .attr('class', classAttr)
        .attr('id', function (d) {
          return d.y
        })
        .attr(
          'cx',
          xVal === null
            ? function (d) {
                return x(d.x)
              }
            : xVal,
        )
        .attr(
          'cy',
          yVal === null
            ? function (d) {
                return y(d.y)
              }
            : yVal,
        )
        .attr('r', radius)
        .style('fill', 'steelblue')
        .append('svg:title')
        .text(function (d) {
          return (
            'COSPAR ID: ' +
            d[reentryColumns.COSPAR_ID] +
            '\nSATNO: ' +
            d[reentryColumns.SATNO] +
            '\nName: ' +
            d[reentryColumns.NAME] +
            '\nReentry Date: ' +
            moment
              .unix(Number(d[reentryColumns.REENTRY_DATE]) / 1000)
              .utc()
              .format('DD-MMM-YYYY') +
            '\nUncertainty: ' +
            d[reentryColumns.UNCERTAINTY] +
            ' day(s)' +
            '\nMass: ' +
            Math.round(d[reentryColumns.MASS] * 1000) / 1000 +
            ' kg' +
            '\nClass: ' +
            d[reentryColumns.CLASS] +
            '\nInclination: ' +
            Math.round(d[reentryColumns.INCLINATION] * 1000) / 1000 +
            ' deg' +
            '\nPerigee: ' +
            Math.round(d[reentryColumns.PERIGEE] * 1000) / 1000 +
            ' km' +
            '\nApogee: ' +
            Math.round(d[reentryColumns.APOGEE] * 1000) / 1000 +
            ' km' +
            '\nTool: ' +
            d[reentryColumns.TOOL] +
            '\nCountry: ' +
            d[reentryColumns.COUNTRY]
          )
        })
      return circleGroup
    }

    function makeTodayLine(
      view,
      data,
      currentDate,
      yDomain,
      classAttr,
      isFocus,
    ) {
      var todayLine = view.append('g')
      todayLine
        .selectAll('.' + classAttr)
        .data(data)
        .enter()
        .append('svg:line')
        .attr('class', classAttr)
        .attr('x1', function () {
          return x(currentDate)
        })
        .attr('x2', function () {
          return x(currentDate)
        })
        .attr('y1', function () {
          if (isFocus) {
            return y(yDomain[0])
          } else {
            return yContextX(yDomain[0])
          }
        })
        .attr('y2', function () {
          if (isFocus) {
            return y(yDomain[yDomain.length - 1])
          } else {
            return yContextX(yDomain[yDomain.length - 1])
          }
        })
        .style('stroke-dasharray', '3, 3')
        .style('stroke-opacity', 0.9)
        .style('stroke', 'steelblue')
        .append('svg:title')
        .text('Today: ' + currentDate)
      return todayLine
    }

    function makeUncertaintyGroup(view, data, classAttr, isFocus) {
      var uncertaintyGroup = view.append('g')
      uncertaintyGroup
        .selectAll('.' + classAttr)
        .data(data)
        .enter()
        .append('line')
        .attr('class', classAttr)
        .attr('x1', (d) => x(d.uncertainty_low))
        .attr('y1', (d) => y(d.y))
        .attr('x2', (d) => x(d.uncertainty_high))
        .attr('y2', (d) => y(d.y))
        .style('fill', '#EC916A')
        .style('stroke', '#EC916A')
        .style('stroke-width', 5)
      return uncertaintyGroup
    }
  },

  //load map
  loadMap(collection, divWidth) {
    var markup = this.liveTemplate({})

    if (this.el.querySelector('.reentryLiveViewArea')) {
      this.$el.find('.reentryLiveViewArea').remove()
    }

    var divReentryLive = document.createElement('div')
    divReentryLive.className = 'reentryLiveViewArea'
    divReentryLive.innerHTML = markup

    this.el.querySelector('.reentryCollectionLive').appendChild(divReentryLive)

    var width = divWidth
    var height = this.divHeight - 30

    var scale =
      (width * 100) / 628 < (height * 100) / 302
        ? (width * 100) / 628
        : (height * 100) / 302

    var heightOffset = (height - scale * 3.02) / 2 + 20

    this.projection = d3.geo
      .equirectangular()
      .scale(scale)
      .translate([width / 2, height / 2])
      .precision(0.1)

    var path = d3.geo.path().projection(this.projection)

    var graticule = d3.geo.graticule()

    this.svg = d3
      .select(this.el.querySelector('.reentryLiveViewArea'))
      .append('svg')
      .attr('width', width)
      .attr('height', height)

    var self = this

    d3.json('../../../files/maps/world.json', function (error, world) {
      if (error) throw error

      d3.csv('../../../files/maps/popDensity.xyz', function (error, buckets) {
        if (error) throw error
        // Coerce the CSV data to the appropriate types.
        buckets.forEach(function (d) {
          d.longitude = +d.longitude
          d.latitude = +d.latitude
          d.density = +d.density
        })

        self.map = self.svg.append('g').attr('class', 'map')

        var landArea = self.map.append('g').attr('class', 'landArea')

        landArea
          .insert('path', '.graticule')
          .datum(topojson.feature(world, world.objects.land))
          .attr('class', 'land')
          .attr('d', path)

        var color = d3.scale
          .linear()
          .range([
            '#D3E5FF',
            '#CBE0FF',
            '#AECFFF',
            '#9BC3FF',
            '#8AB9FF',
            '#7CB1FF',
            '#66A4FF',
            '#5096FF',
            '#3888FF',
            '#1F79FF',
            '#1171FF',
            '#0066FF',
            '#0050C8',
            '#0043A6',
            '#003482',
          ])
        color.domain([
          0, 1, 2, 5, 10, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000,
          50000, 80000,
        ])

        // Alternate Color for Population density
        //color.domain([0, 1, 2, 5, 10, 20, 50, 100, 150, 300, 600, 1200, 2500, 5000, 10000, 20000, 40000, 80000]);
        //var color = d3.scale.linear().range(["#FFDA7A", "#FFB700", "#FF9E00", "#FF8900", "#FF7300", "#FF5A00", "#FF4900", "#FF2F00", "#FF0400", "#FF0089", "#FF00BC", "#7C00FF", "#5500FF", "#2B00FF", "#2400DA", "#1E00B2", "#17008B", "#10005F"]);

        var popDensity = self.map.append('g').attr('class', 'popDensity')

        var legendArea = popDensity.append('g').attr('class', 'legendArea')

        // Add a legend for the color values.
        var legend = legendArea
          .selectAll('.legend')
          .data(color.domain())
          .enter()
          .append('g')
          .attr('class', 'legend')

        legend
          .append('rect')
          .attr('x', 20)
          .attr('y', function (d, i) {
            return height - heightOffset - i * 10 - 2 * 20
          })
          .attr('width', 10)
          .attr('height', 10)
          .style('fill', function (d, i) {
            return color(d)
          })

        legend
          .append('text')
          .attr('x', 35)
          .attr('y', function (d, i) {
            return height - heightOffset - i * 10 - 32
          })
          .style('font-size', '10px')
          .text(String)

        legendArea
          .append('text')
          .attr('class', 'label')
          .attr('x', -(height - (heightOffset + 2 * 20)))
          .attr('y', 5)
          .attr('dy', '.35em')
          .text('Population density per sq. km')
          .attr('transform', 'rotate(-90)')

        popDensity
          .selectAll('.tile')
          .data(buckets)
          .enter()
          .append('circle', '.tile')
          .attr('r', '2px')
          .attr('fill', function (d) {
            return color(d.density)
          })
          .attr('transform', function (d) {
            return (
              'translate(' + self.projection([d.latitude, d.longitude]) + ')'
            )
          })

        var countryArea = self.map.append('g').attr('class', 'countryArea')

        countryArea
          .insert('path', '.country')
          .datum(
            topojson.mesh(world, world.objects.countries, function (a, b) {
              return a !== b
            }),
          )
          .attr('class', 'country')
          .attr('d', path)

        var latLongGridArea = self.map
          .append('g')
          .attr('class', 'latLongGridArea')

        latLongGridArea
          .append('path', '.latLongGrid')
          .datum(graticule)
          .attr('class', 'latLongGrid')
          .attr('d', path)

        self.mapLoaded = true

        self.renderLiveView(collection)

        setInterval(() => {
          Orbit.updateOrbits(reentryOrbitCollection)
        }, 2 * 60 * 1000)
      })
    })

    d3.select(self.frameElement).style('height', height + 'px')
  },

  // render live view tab
  renderLiveView(collection) {
    var data = []
    var self = this
    var currentTime = new Date().getTime()

    // make data
    for (var i = 0; i < collection.length; ++i) {
      if (currentTime < collection[i][reentryColumns.REENTRY_DATE]) {
        data[i] = {}
        data[i][keplerParams.ARGUMENT_OF_PERIAPSIS] =
          collection[i][reentryColumns.ARGUMENT_OF_PERIAPSIS]
        data[i][keplerParams.LONGITUDE_OF_ASCENDING_NODE] =
          collection[i][reentryColumns.LONGITUDE_OF_ASCENDING_NODE]
        data[i][keplerParams.INCLINATION] =
          collection[i][reentryColumns.INCLINATION]
        data[i][keplerParams.EPOCH] = collection[i][reentryColumns.ORBIT_EPOCH]
        data[i][keplerParams.ECCENTRICITY] =
          collection[i][reentryColumns.ECCENTRICITY]
        data[i][keplerParams.TRUE_ANOMALY] =
          collection[i][reentryColumns.TRUE_ANOMALY]
        data[i][keplerParams.SEMI_MAJOR_AXIS] =
          collection[i][reentryColumns.SEMI_MAJOR_AXIS]
        data[i][reentryColumns.APOGEE] = collection[i][reentryColumns.APOGEE]
        data[i][reentryColumns.PERIGEE] = collection[i][reentryColumns.PERIGEE]
        data[i][reentryColumns.COSPAR_ID] =
          collection[i][reentryColumns.COSPAR_ID]
        data[i][reentryColumns.CLASS] = collection[i][reentryColumns.CLASS]
      }
    }

    d3.selectAll('.satelliteOrbitOverlay').remove()

    var satelliteOrbitOverlay = this.map
      .append('g')
      .attr('class', 'satelliteOrbitOverlay')

    // Draw orbits
    d3.selectAll('.orbits').remove()

    var orbitsOverlay = satelliteOrbitOverlay
      .append('g')
      .attr('class', 'orbits')

    var allSatelliteOrbits = Orbit.getOrbits(data, reentryOrbitCollection)

    var orbitLine = d3.svg
      .line()
      .interpolate('monotone')
      .x(function (d) {
        return self.projection([d.longitude, d.latitude])[0]
      })
      .y(function (d) {
        return self.projection([d.longitude, d.latitude])[1]
      })

    var orbitLines = orbitsOverlay
      .selectAll('.orbitLines')
      .data(allSatelliteOrbits)
      .enter()
      .append('g')
      .attr('class', 'orbitLines')

    orbitLines
      .append('path')
      .attr('class', function (d) {
        return 'orbitLine orbit-' + d.cosparId
      })
      .attr('d', function (d) {
        return orbitLine(d.values)
      })

    // Draw satellites
    var satellitesOverlay = satelliteOrbitOverlay
      .append('g')
      .attr('class', 'satellites')

    const classes = reentryModel.getAllClasses()
    const iconMap = reentryModel.getIconMap(classes)

    d3.timer((elapsed) => {
      var satOrbitPoints = Orbit.getSatellites(data)
      d3.selectAll('.satOrbitPin').remove()
      d3.selectAll('.satOrbitPinText').remove()
      d3.selectAll('.satOrbitPinTextShadow').remove()

      satellitesOverlay
        .selectAll('.satOrbitPin')
        .data(satOrbitPoints)
        .enter()
        .append('text', '.satOrbitPin')
        .attr('font-family', 'FontAwesome')
        .text((d) => iconMap[d.objectClass].char)
        .attr('class', 'satOrbitPin')
        .attr('id', (d) => d.cosparId)
        .attr(
          'transform',
          (d) =>
            'translate(' + self.projection([d.longitude, d.latitude]) + ')',
        )
        .attr('y', 5)
        .attr('x', -5)
        .on('mouseover', (d) => {
          orbitsOverlay
            .selectAll('.orbit-' + d.cosparId)
            .style('stroke-width', '5px')
            .transition()
            .duration(5000)
            .style('stroke-width', '.5px')
        })

      satellitesOverlay
        .selectAll('.satOrbitPinTextShadow')
        .data(satOrbitPoints)
        .enter()
        .append('text')
        .attr('class', 'satOrbitPinTextShadow')
        .attr('x', (d) => self.projection([d.longitude, d.latitude])[0] + 7)
        .attr('y', (d) => self.projection([d.longitude, d.latitude])[1] - 7)
        .text((d) => d.cosparId)

      satellitesOverlay
        .selectAll('.satOrbitPinText')
        .data(satOrbitPoints)
        .enter()
        .append('text')
        .attr('class', 'satOrbitPinText')
        .attr('x', (d) => self.projection([d.longitude, d.latitude])[0] + 7)
        .attr('y', (d) => self.projection([d.longitude, d.latitude])[1] - 7)
        .text((d) => d.cosparId)
    })
  },

  sort(e) {
    console.log('Sort ', e)
    this.sortIdClicked = e.currentTarget.id

    // sort in descending if same field clicked twice
    if (this.sortIdClicked === this.previousSortIdClicked) {
      this.previousSortIdClicked = null
      reentryFilterModel.set('sort', 'desc|' + e.currentTarget.id)
    } else {
      this.previousSortIdClicked = this.sortIdClicked
      reentryFilterModel.set('sort', 'asc|' + e.currentTarget.id)
    }
  },

  viewDetails(e) {
    var cosparId = e.currentTarget.id
    this.router.navigate('events/' + cosparId, { trigger: true })
  },

  openTab(e) {
    console.log('View Id', e)
    var newView = e.currentTarget.id
    if (newView !== this.currentView) {
      this.currentView = newView
      var idDisplay = '#reentry_' + newView
      window.location.hash = idDisplay
      var tabs = this.el.querySelectorAll('.reentry_tab_display')
      for (var i = 0; i < tabs.length; i++) {
        tabs[i].style.display = 'none'
      }
      this.el.querySelector(idDisplay).style.display = 'block'
      this.render()
    }
  },
})
