var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "clickable";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"panel-collapse collapse\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"askAdminWindow-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":1,"column":27},"end":{"line":1,"column":35}}}) : helper)))
    + "\">\n	<div class=\"panel panel-info\" style=\"overflow-x:hidden\">\n		<div class=\"panel-heading "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminPage") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":71}}})) != null ? stack1 : "")
    + " askAdminPanelHeading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":3,"column":93},"end":{"line":3,"column":101}}}) : helper)))
    + "\">\n			<div class=\"panel-title askAdminPanelTitle-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":4,"column":46},"end":{"line":4,"column":54}}}) : helper)))
    + "\">\n				<span class=\"glyphicon glyphicon-comment\"/>\n				Ask Admin\n				<div class=\"btn-group pull-right\" role=\"group\">\n					<div class=\"askAdminPanelExpand-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":45}}}) : helper)))
    + "\"/>\n				</div>\n			</div>\n		</div>\n		<div id=\"panelBodyAskAdmin-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":12,"column":29},"end":{"line":12,"column":37}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminPage") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":104}}})) != null ? stack1 : "")
    + ">\n			<div class=\"panel-body\" style=\"padding-left:5px; padding-right:5x\">\n				<div>\n					<div class=\"askAdminDisplay-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":15,"column":33},"end":{"line":15,"column":41}}}) : helper)))
    + "\" />\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});