/*
	Model provids the necessary functionalities for event view
	e.g applying filters, sorting
*/
import Backbone from 'backbone'

import _ from 'lodash'
import DetailFilesCollection from './DetailFilesCollection'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'
import * as FilterFactory from '../factory/FilterFactory.js'

var DetailModel = Backbone.Model.extend({
  initialize(options) {
    this.cosparId = options.cosparId
    this.detailFilesCollection = new DetailFilesCollection(this.cosparId)
    this.displayDetailFilesCollection = new (Backbone.Collection.extend({
      model: Backbone.Model.extend({ idAttribute: reentryColumns.COSPAR_ID }),
    }))()
    this.detailFilesFilterModel = options.detailFilesFilterModel

    this.listenTo(
      this.detailFilesFilterModel,
      'change',
      function () {
        this.applyFilter()
      },
      this,
    )

    this.listenTo(
      this.detailFilesCollection,
      'sync add',
      function (e) {
        this.displayDetailFilesCollection.reset(
          this.detailFilesCollection.toJSON(),
        )
      },
      this,
    )
  },

  applyFilter() {
    let filteredItems = this.detailFilesCollection.toJSON()

    var self = this

    _.keys(this.detailFilesFilterModel.toJSON()).forEach(function (key) {
      if (key !== 'sort') {
        if (
          (self.detailFilesFilterModel.toJSON()[key].hasOwnProperty('text') &&
            self.detailFilesFilterModel.toJSON()[key].text !== '') ||
          !self.detailFilesFilterModel.toJSON()[key].hasOwnProperty('text')
        ) {
          filteredItems = _.filter(filteredItems, (item) => {
            if (
              FilterFactory.applyFilter(
                self.detailFilesFilterModel.toJSON()[key],
                item[key],
              )
            ) {
              return item
            }
          })
        }
      }
    })

    if (this.detailFilesFilterModel.has('sort')) {
      var itemClicked = this.detailFilesFilterModel.get('sort').split('-')
      if (itemClicked[1] === 'asc') {
        filteredItems = this.sortAscending(itemClicked[0], filteredItems)
      } else {
        filteredItems = this.sortDescending(itemClicked[0], filteredItems)
      }
    }

    this.displayDetailFilesCollection.reset(filteredItems)
  },

  getFileTypes() {
    var fileTypes = _.uniq(_.map(this.detailFilesCollection.toJSON(), 'type'))
    var displayFileTypes = _.uniq(
      _.map(this.displayDetailFilesCollection.toJSON(), 'type'),
    )
    var returnResult = []
    for (var i = 0; i < fileTypes.length; i++) {
      if (displayFileTypes.indexOf(fileTypes[i]) > -1) {
        returnResult.push({ value: fileTypes[i], isChecked: true })
      } else {
        returnResult.push({ value: fileTypes[i], isChecked: false })
      }
    }
    return returnResult
  },

  sortAscending(field, filteredItems) {
    return _.chain(filteredItems).sortBy(field).value()
  },

  sortDescending(field, filteredItems) {
    return _.chain(filteredItems).sortBy(field).reverse().value()
  },

  getDetailFilesCollection() {
    return this.displayDetailFilesCollection
  },

  getItemListToDisplay(fileId) {
    var fileProperties = fileId.split('.')
    var fileType = fileProperties[0]
    var fileExtension = fileProperties[1]
    var fileName = (function () {
      var name = ''
      for (var i = 2; i < fileProperties.length; i++) {
        name = name + '.'
        name = name + fileProperties[i]
      }
      return name.substring(1)
    })()

    var filesList = _.filter(this.detailFilesCollection.toJSON(), {
      name: fileName,
      type: fileType,
      extension: fileExtension,
    })
    return filesList
  },

  getFilesCollection() {
    return this.detailFilesCollection
  },
})

export default DetailModel
