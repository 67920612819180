export const containers = {
  home: '#container-home',
  detail: '#container-detail',
  reentry: '#container-reentry',
  campaigns: '#container-campaigns',
  admin: '#container-admin',
}

export const routes = {
  'events/:id': 'detail',
  'home(/:page)(/:id)': 'home',
  reentry: 'reentry',
  admin: 'admin',
  '*actions': 'defaultRoute',
}
