var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-lg-2-25\" style=\"height:100%\">\n	<div class=\"scroll-y-auto callout reentryFilterMenu\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"filterDivHeight") || (depth0 != null ? lookupProperty(depth0,"filterDivHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"filterDivHeight","hash":{},"data":data,"loc":{"start":{"line":2,"column":68},"end":{"line":2,"column":87}}}) : helper)))
    + "; overflow-x: hidden\">\n		<div class=\"reentryCollectionFilter\"></div>\n	</div>\n	<div class=\"reentryAskAdminView\" />\n</div>\n\n<div class=\"col-lg-9-75\"  style=\"height:100%\">\n	<div class=\"reentryCollectionView\"></div>\n</div>\n";
},"useData":true});