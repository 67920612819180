/*
	Displays the history of an object in a table.
*/
import Backbone from 'backbone'

import _ from 'lodash'
import $ from 'jquery'
import { reentryFieldNames as reentryColumns } from '../../../common/config/constants'

export const DetailHistoryView = Backbone.View.extend({
  template: require('../../templates/DetailHistoryView.hbs'),

  // Collection containing history to be displayed
  historyCollection: null,

  initialize(options) {
    console.log('Initialized Detail History View')

    this.historyCollection = options.collection

    this.divHeight = options.height
  },

  render() {
    var markup = this.template({
      collection: this.historyCollection,
      divHeight: this.divHeight - 50 + 'px',
      reentryColumns,
    })
    this.$el.html(markup)
    return this
  },
})
