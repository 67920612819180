var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "						<th class=\"col-size-history-detail\">\n							<span title=\"Creation Date\">Creation Date (UTC)</span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span title=\"(Predicted) Reentry date\">Reentry Date (UTC)</span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span title=\"Uncertainty\">Uncertainty<sub>day</sub></span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span title=\"Object's inclination\">Inclination<sub>deg</sub></span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span title=\"Object's perigee height\">Perigee<sub>km</sub></span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span title=\"Object's apogee height\">Apogee<sub>km</sub></span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span title=\"Tool used to generate automated prediction\">Tool</span>\n						</th>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"collection") : depths[1]),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":5},"end":{"line":43,"column":14}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<tr class=\"col-size-row\">\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatDateHour","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CREATION_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":35,"column":58},"end":{"line":35,"column":115}}}))
    + "</td>\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatDateHour","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"REENTRY_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":36,"column":58},"end":{"line":36,"column":114}}}))
    + "</td>\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"UNCERTAINTY") : depths[1]),{"name":"chain","hash":{"format":"%.1f"},"data":data,"loc":{"start":{"line":37,"column":58},"end":{"line":37,"column":125}}}))
    + "</td>\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"INCLINATION") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":38,"column":58},"end":{"line":38,"column":125}}}))
    + "</td>\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"PERIGEE") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":39,"column":58},"end":{"line":39,"column":121}}}))
    + "</td>\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatNumber","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"APOGEE") : depths[1]),{"name":"chain","hash":{"format":"%.3f"},"data":data,"loc":{"start":{"line":40,"column":58},"end":{"line":40,"column":120}}}))
    + "</td>\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"TOOL") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":41,"column":58},"end":{"line":41,"column":81}}}))
    + "</td>\n						</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"reentryHistoryCollection\">\n	<div class=\"reentryHistoryTable\">\n		<table class=\"table table-striped table-hover head-fixed\">\n			<thead>\n				<tr class=\"col-size-row\" style=\"border-bottom-style: solid\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"reentryColumns") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":5},"end":{"line":28,"column":14}}})) != null ? stack1 : "")
    + "				</tr>\n			</thead>\n			<tbody style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":37}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"reentryColumns") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":44,"column":13}}})) != null ? stack1 : "")
    + "			</tbody>\n		</table>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});